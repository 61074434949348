import React from 'react';
import { Translation } from 'react-i18next';
import { BLACK, WHITE } from '../../utils/color-palatte';
import logo from "../../assets/logos/white-logo.svg";
import { companyEmail, companyTwitter, companyFacebook, contactNumber, wsMe, companyEmailOutside, contactNumberOutside, contactNumber2, contactNumberOutside2 } from "../../config";
import { Link, Redirect } from 'react-router-dom'
import facebookIcon from "../../assets/icons/fb-icon.svg";
import logOutIcon from "../../assets/icons/logout.svg";
import twitterIcon from "../../assets/icons/twitter-icon.svg";
import { css } from 'emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import cartIcon from "../../assets/icons/yellow-cart.svg";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import thousandSeparator from '../../utils/thousandSeparator';
import { validateUser } from '../../utils/validateUserType';
import { getData } from '../../fetch/services';
import wsYellowIcon from "../../assets/icons/yellow-ws-icon.png";

const cartButton = css`
position: fixed;
bottom: 10px;
right: 0;
background-color: ${BLACK};
padding-right: 10px;
padding-left: 10px;
cursor: pointer;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
z-index: 300;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  `

const cartIconStyles = css`
width: 50px;   
height: 50px;
cursor: pointer;
@media(max-width: 650px) {
  width: 40px;   
  height: 40px;
}
  `

const cartCounterText = css`
position: absolute;
top: 2px;
right: 2Px;
font-size: 11px;
font-weight: bold;
background: ${WHITE};
padding: 4px;
margin: 0;
border-radius: 20px;
@media(max-width: 650px) {
  top: -5px;
  right: 5px;
  font-size: 10px;
}
  `

const homeFooterContainer = css`
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 30px;
padding-bottom: 30px;
`

const footerLastInfoContainer = css`
display: flex;
justify-content: center;
align-items: center;
margin-top: 30px
`

const socialMediaButtonContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
margin-top: 10px;
`

const itemsContainer = css`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
align-items: center;
margin-left: 60px;
@media(max-width: 910px) {
  margin-left: 40px;
  justify-content: flex-start;
}
@media(max-width: 340px) {
  margin-left: 20px;
}
`

const footerItemContainer = css`
text-align: left;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: center;
align-items: flex-start;
width: 30%;
min-width: 300px;
min-height: 160px;
justify-content: flex-start;
@media(min-width: 650px) {
  margin: 20px;
}
`

const footerItemContainer2 = css`
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
width: 30%;
min-width: 300px;
min-height: 160px;
justify-content: flex-start;
@media(max-width: 910px) {
align-items: flex-start;
}
`

const logoStyles = css`
width: 120px;   
height: 50px;
`

const logOutIconStyles = css`
width: 25px;   
height: 25px;
cursor: pointer;
margin-top: 20px;
`

const textStyle = css`
margin: 0;
margin-top: 10px;
`

const footerSocialMediIcon = css`
width: 20px;
height: 20px;
margin-right: 5px;
`

const flags = css`
button {
  padding: 0;
}
`

class HomeFooter extends React.Component {

  state = {
    redirect: false,
    totalAmount: "",
    bsValue: "",
    currency: "$",
  }

  componentDidMount = async () => {
    await this.fetchConfig()
  }

  logout = () => {
    const { signOut } = this.props;
    signOut();
    window.scrollTo(0, 0);
  };

  updateAmount = () => {
    let counter = 0
    if (this.props.cart && this.props.cart.length > 0) {
      this.props.cart.forEach(product => {
        counter = counter + ((product.quantitySelected || 1) * product.price)
      })
    }
    return counter
  };

  fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getData(`/config/get-config`);
        // console.log("fetchConfig / response: ", response);
        const config = response.body

        this.setState({
          bsValue: config.bsValue,
          currency: config.currency,
        });
        resolve();
      } catch (e) {
        resolve(e);
      }
    })
  }

  render() {
    const { user, cart } = this.props;
    const { currency, bsValue } = this.state;
    const totalAmount = this.updateAmount()
    // console.log("prueba: ", user && user.email && (user.userType ? (user.userType !== "products-admin" && user.userType !== "seller") : true))
    return (
      <Translation>
        {t =>
          <div style={{ backgroundColor: BLACK }} className={`${homeFooterContainer}`}>
            <div className={`${itemsContainer}`}>
              <div className={`${footerItemContainer}`}>
                <img className={`${logoStyles}`} src={logo} alt="mayor diesel logo" />
                <p className={`${textStyle}`} style={{ color: WHITE, textAlign: 'left' }}>{`${t('footerExtraInfo')}`}</p>
                {companyEmail && <p className={`${textStyle}`} style={{ color: WHITE }}>{`${t('email')}: ${companyEmail} ${companyEmailOutside}`}</p>}
                {contactNumber && <p className={`${textStyle}`} style={{ color: WHITE }}>{`${t('phone')}:`}</p>}
                {contactNumber && <p className={`${textStyle}`} style={{ color: WHITE }}>{`${contactNumber}`}</p>}
                {contactNumber2 && <p className={`${textStyle}`} style={{ color: WHITE }}>{`${contactNumber2}`}</p>}
                {contactNumberOutside && <p className={`${textStyle}`} style={{ color: WHITE }}>{`${contactNumberOutside}`}</p>}
                {contactNumberOutside2 && <p className={`${textStyle}`} style={{ color: WHITE }}>{`${contactNumberOutside2}`}</p>}
                {wsMe && <a target="_blank" rel="noopener noreferrer" href={wsMe}>
                  <img style={{ marginLeft: 5, width: 40, height: 40, cursor: 'pointer', marginTop: 10 }} src={wsYellowIcon} alt='ws number' />
                </a>}
              </div>
              <div className={`${footerItemContainer}`}>
                <h2 className={`${textStyle}`} style={{ color: WHITE }}>{t('footerMenuTitle')}</h2>
                <Link to="/">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>{t('footerHome')}</p>
                </Link>
                <Link to="/products">
                  <p className={`${textStyle}`} style={{ color: WHITE }}>{t('footerProducts')}</p>
                </Link>
                <Link to="/we">
                  <p className={`${textStyle}`} style={{ color: WHITE }}>{t('footerWe')}</p>
                </Link>
                <Link to="/">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('contactUs')}
                  </p>
                </Link>
                {(user && user.email && (user.userType ? (user.userType !== "products-admin" && user.userType !== "seller") : true)) && <Link to="/orders">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('footerOrdersButton')}
                  </p>
                </Link>}
                {(user && user.email) && <Link to="/favourites">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('footerOFavouritesButton')}
                  </p>
                </Link>}
                {(user && validateUser(["super-admin", "admin"], user.userType)) && <Link to="/contacts">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('footerContactsButton')}
                  </p>
                </Link>}
                {(user && validateUser(["super-admin", "admin", "seller", "employer"], user.userType)) && <Link to="/users">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('footerUsersButton')}
                  </p>
                </Link>}
                {(user && validateUser(["super-admin"], user.userType)) && <Link to="/configuration">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('footerConfigurationButton')}
                  </p>
                </Link>}
                <Link to="/help">
                  <p onClick={() => { window.scrollTo(0, 0); }} className={`${textStyle}`} style={{ color: WHITE }}>
                    {t('help2')}
                  </p>
                </Link>
                <ReactFlagsSelect
                  // ref={this.userFlag}
                  className={`${flags}`}
                  defaultCountry={String(localStorage.getItem("language") || "ES").toUpperCase()}
                  countries={["US", "ES"]}
                  showSelectedLabel={false}
                  showOptionLabel={false}
                  onSelect={(selected) => {
                    localStorage.setItem("language", String(selected).toLowerCase())
                    window.location.reload(false);
                  }}
                />
              </div>
              <div className={`${footerItemContainer2}`} style={user && user.email ? {display: 'flex'} : {display: 'none'}}>
                {(companyFacebook || companyTwitter) && <h2 className={`${textStyle}`} style={{ color: WHITE }}>{t('footerSocialMediaTitle')}</h2>}
                {companyFacebook && <a target="_blank" rel="noopener noreferrer" href={companyFacebook}>
                  <div className={`${socialMediaButtonContainer}`}>
                    <img className={`${footerSocialMediIcon}`} src={facebookIcon} alt="facebook button" />
                    <p style={{ color: WHITE, margin: 0 }}>{t('footerFacebook')}</p>
                  </div>
                </a>}
                {companyTwitter && <a target="_blank" rel="noopener noreferrer" href={companyTwitter}>
                  <div className={`${socialMediaButtonContainer}`}>
                    <img className={`${footerSocialMediIcon}`} src={twitterIcon} alt="twitter button" />
                    <p style={{ color: WHITE, margin: 0 }}>{t('footerTwitter')}</p>
                  </div>
                </a>}
                {user && user.email
                  ? <img alt="log out button" onClick={() => this.logout()} src={logOutIcon} className={`${logOutIconStyles}`} />
                  : null}
              </div>
            </div>
            <div className={`${footerLastInfoContainer}`}>
              <p style={{ color: WHITE, margin: 0 }}>{t('footerLastInfo')}</p>
            </div>
            {(cart && cart.length > 0 && window.location.pathname !== '/cart')
              && <div className={`${cartButton}`} onClick={() => this.setState({ redirect: true })}>
                <div style={{ position: 'relative', width: 50, marginTop: 10 }}>
                  <img src={cartIcon} className={`${cartIconStyles}`} alt='shopping cart button' />
                  <p style={{ color: BLACK }} className={`${cartCounterText}`}>{cart && cart.length > 0 ? cart.length : 0}</p>
                </div>
                {(totalAmount && currency) && <p style={{ color: WHITE, marginTop: 0 }}>{`TOTAL: ${thousandSeparator(currency === "bs" ? (Number(totalAmount) * Number(bsValue)).toFixed(2) : totalAmount.toFixed(2)) || 0}${currency === "bs" ? "Bs" : "$"}`}</p>}
              </div>}
            {this.state.redirect
              ? <React.Fragment>
                <Redirect
                  to={`/cart`}
                />
                {this.setState({ redirect: false })}
              </React.Fragment>
              : null}
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
    cart
  } = state;
  return {
    user,
    cart
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeFooter);
