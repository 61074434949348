import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./components/home/Home";
import SigninSignUp from "./components/signin-signup/SigninSignUp";
import Cart from "./components/cart/Cart";
import We from "./components/we/We";
import Orders from "./components/orders/orders";
import Users from "./components/users/Users";
import Contacts from "./components/contacts/Contacts";
import Favourites from "./components/favourites/Favourites";
import Products from "./components/products/Products";
import CreateProduct from "./components/create-product/CreateProduct";
// import Header from "./components/header/Header";
import { BLACK } from "./utils/color-palatte";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from './redux/actions';
import UserStats from "./components/user-stats/UserStats";
import EditProduct from "./components/edit-product/EditProduct";
import Config from "./components/config/Config";
import { validateUser } from "./utils/validateUserType";
import Help from "./components/help/Help";

import PartnerBanner from "./components/partner/partnerBanner";

const Navigator = ({ user }) => {
  // console.log("user: ", user)
  return (
    <Router>
      <div style={{ background: BLACK }}>
        {/* <Header /> */}
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/login">
            <SigninSignUp />
          </Route>
          {(user && (validateUser(["super-admin", "admin", "products-admin"], user.userType))) && <Route exact path="/create-product">
            <CreateProduct />
          </Route>}
          {user && <Route exact path="/cart">
            <Cart />
          </Route>}
          <Route exact path="/we">
            <We />
          </Route>
          <Route exact path="/products">
            <Products />
          </Route>
          {user && <Route exact path="/orders">
            <Orders />
          </Route>}
          {(user && validateUser(["super-admin", "admin"], user.userType)) && <Route path="/contacts">
            <Contacts />
          </Route>}
          {(user && validateUser(["super-admin", "admin", "seller", "employer"], user.userType)) && <Route path="/users">
            <Users />
          </Route>}
          {(user && validateUser(["super-admin", "admin", "seller", "employer"], user.userType)) && <Route path="/user-stats">
            <UserStats />
          </Route>}
          {(user && validateUser(["super-admin", "admin", "products-admin"], user.userType)) && <Route path="/edit-product">
            <EditProduct />
          </Route>}
          {(user && validateUser(["super-admin"], user.userType)) && <Route path="/configuration">
            <Config />
          </Route>}
          {user && <Route path="/favourites">
            <Favourites />
          </Route>}
          <Route path="/help">
            <Help />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <PartnerBanner />
      </div>
    </Router>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigator);