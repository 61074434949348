// export const API_URL = 'http://192.168.1.100:4000'
export const API_URL = 'https://neurons-shopping-cart.herokuapp.com'
export const timeToResponse = 20000 //time to response from server before show timeout error
export const consoleLogs = true
export const cryptoJsSecretKey = 'HLhzmF6D8tfcMNwJ'
export const contactNumber = '+58(414) 165-2657'
export const contactNumber2 = '+58(414) 165-2659'
export const contactNumberOutside = '+1(786) 325-7626'
export const contactNumberOutside2 = '+1(786) 450-1831'
export const companyEmail = 'ventas@mayordiesel.com'
export const companyEmailOutside = 'sales@mayordiesel.com'
export const companyDirection = 'Maracaibo, Estado Zulia, VE.'
export const companyDirectionOutside = '8351 NW 68st Miami FL 33166, SUPPLY PARTS, LLC.'
export const companyFacebook = ''
export const companyTwitter = ''
export const tgMe = ''
export const wsMe = 'https://linktr.ee/Mayordiesel.com'
export const videoId = 'https://www.youtube.com/watch?v=hc-dzJxg1n8'
export const googleTranslationApiKey = 'AIzaSyA39k-d4-eJKp-_kKX_-p_ksOdhFdegBJI'
export const quantitiesMax = 24