import React from 'react';
// import { googleTranslationApiKey } from '../config';
// const { Translate } = require('@google-cloud/translate').v2;

const ProductDescription = ({ description, onClick, style }) => {
  // const [translatedDescription, setTranslatedDescription] = useState('');

  // useEffect(() => {
  //   // Function to translate the description
  //   const translateDescription = async () => {
  //     // Initialize Google Translate client with your API key
  //     // Instantiates a client
  //     const translate = new Translate({ key: googleTranslationApiKey });

  //     try {
  //       const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"
  //       // console.log("language: ", language)
  //       // Translate the description to Spanish ('es')
  //       const [translation] = await translate.translate(description, language);
  //       setTranslatedDescription(translation);
  //     } catch (error) {
  //       console.error('Error translating description:', error);
  //     }
  //   };

  //   // Call the translation function
  //   translateDescription();
  // }, [description]); // Re-run translation when the description changes

  return (
    <p onClick={onClick} style={style}>{`${description}`}</p>
    );
};

export default ProductDescription;
