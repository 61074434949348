import React from 'react'
import { css } from 'emotion'
const Loader = ({ color, className }) => {
  const generalLoader = css`
  :empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :empty::after {
    content: "";
    width: 1.5em;
    height: 1.5em;
    border: transparent;
    border-left: 0.1em solid ${color || "#F6F7FA"};
    border-radius: 50%;
    // -webkit-animation: load8 1.1s infinite linear;
    // animation: load8 1.1s infinite linear;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
  `
  return (
    <div className={`${generalLoader} ${className}`} />
  )
}
export default Loader