import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import HomeFooter from '../home-footer/HomeFooter';
import { css } from 'emotion'
import { BLACK, FULL_WHITE, WHITE } from '../../utils/color-palatte';
import Input from "../../utils/Input";
import Button from "../../utils/Button";
import { Redirect } from "react-router-dom";
import { emailsRegex } from '../../utils/regex';
import { postData } from '../../fetch/services';
import ModalComponent from '../../utils/Modal';
import Alert from '../../utils/Alert';
import saveStat from '../../utils/saveStat';
import { validateUser } from '../../utils/validateUserType';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const signinSignupContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const itemsContainer = css`
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
`

const forgetPasswordContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row
`

const item = css`
min-width: 280px;
display: flex;
justify-content: flex-start;
max-width: 400px;
display: flex;
align-items: flex-start;
flex-direction: column;
padding-right: 20px;
padding-left: 20px;
@media(max-width: 390px) {
  width: 100%
}
`

const inputStyles = css`
width: 100%;
`

const resetPasswordContainer = css`

`

class SignInSignUp extends React.Component {

  state = {
    signinEmail: "",
    signinPassword: "",
    signinLoading: false,
    signupLoading: false,
    showErrorsSignIn: false,
    showErrorsSignUp: false,
    signupEmail: "",
    signupPassword: "",
    signupName: "",
    signupPhone: "",
    isModalOpen: false,
    resetPassEmail: "",
    resetPasswordLoading: false,
    showModalInputsErrors: false,
    redirect: false,
    showPassword: false
  };

  viewPassword = () => {
    if(this.state.showPassword) {
      this.setState({showPassword: false})
    } else {
      this.setState({ showPassword: true });
    }
  }

  componentDidMount = () => {

  };

  signUp = (t) => {
    if (this.state.signupEmail &&
      this.state.signupPassword &&
      this.state.signupEmail.match(emailsRegex)) {
      this.setState({ signupLoading: true }, async () => {
        try {
          await this.verifySession()
          const data = {
            name: this.state.signupName,
            email: this.state.signupEmail,
            phone: this.state.signupPhone,
            password: this.state.signupPassword
          }
          await postData('/user/signup', data);
          // console.log("signUp / response: ", response);
          this.showAlert(true, t('signup-complete'), true)
          this.setState({
            signupLoading: false,
            signupEmail: "",
            signupPassword: "",
            signupName: "",
            signupPhone: "",
          })
        } catch (e) {
          // console.log("signUp / error: ", e);
          this.setState({ signupLoading: false })
          if (e !== "session-expired") {
            const error = e && e.data && e.data.error && e.data.error.message ? e.data.error.message : t('internalError')
            this.showAlert(true, error, false)
          }
        }
      })
    } else {
      this.setState({ showErrorsSignUp: true })
      if (this.state.signupEmail && !this.state.signupEmail.match(emailsRegex)) {
        alert(t('emailIsInvalid'));
      }
    }
  };

  // resendConfirmEmail = (t) => {
  //   const { signinEmail } = this.state;
  //   this.setState({ signinLoading: true }, async () => {
  //     try {
  //       const data = { email: signinEmail };
  //       await postData('/user/resend-token', data, false);
  //       // console.log("resendConfirmEmail / response: ", response)
  //       alert(`${t('email-verification-sended')} ${signinEmail}`);
  //       this.setState({ signinLoading: false });
  //     } catch (e) {
  //       // console.log("resendConfirmEmail / error: ", e)
  //       this.setState({ signinLoading: false });
  //       const error = e && e.data && e.data.error ? e.data.error : null;
  //       const errorMessage = error && error.message && error.message[0] && error.message[0].msg
  //         ? error.message[0].msg
  //         : error && error.message
  //           ? error.message
  //           : t('internalError')
  //       alert(errorMessage);
  //     }
  //   })
  // };

  signIn = (t) => {
    const { saveUser } = this.props;
    if (this.state.signinEmail &&
      this.state.signinPassword) {
      this.setState({ signinLoading: true }, async () => {
        try {
          const data = {
            email: this.state.signinEmail,
            password: this.state.signinPassword,
          }
          const response = await postData('/user/login', data, false);
          // console.log("signIn / response: ", response);
          localStorage.setItem('token', response.body.token)
          localStorage.setItem('userId', response.body.user._id)
          saveUser(response.body.user)
          this.setState({
            signinLoading: false,
          }, async () => {
            const userId = localStorage.getItem('userId')
            if (userId) {
              await saveStat('login');
              this.setState({ redirect: true })
            }
          })
        } catch (e) {
          // // console.log("signIn / error: ", e);
          // const isNotVerified = e && e.data && e.data.error && e.data.error.code === "user-not-verified" ? true : false
          // if (isNotVerified) {
          //   await this.resendConfirmEmail(t)
          // } else {
          const error = e && e.data && e.data.error && e.data.error.message ? e.data.error.message : t('internalError')
          this.showAlert(true, error, false)
          this.setState({ signinLoading: false })
          // }
        }
      })
    } else {
      this.setState({ showErrorsSignIn: true })
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  sendChangePassEmail = (t) => {
    const { resetPassEmail } = this.state;
    if (
      resetPassEmail &&
      resetPassEmail.match(emailsRegex)
    ) {
      this.setState({ resetPasswordLoading: true }, async () => {
        try {
          await postData(`/user/send-change-password-email/${resetPassEmail}`);
          this.showAlert(true, t('pleaseCheckEmail'), true)
          this.setState({
            resetPasswordLoading: false,
            showModalInputsErrors: false,
            isModalOpen: false,
            resetPassEmail: ""
          });
        } catch (e) {
          // console.log("sendEmail / e: ", e);
          this.setState({ resetPasswordLoading: false });
          const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
            ? e.response.data.error.message
            : null;
          const error = e && e.data && e.data.error ? e.data.error : null;
          const errorMessage = error && error.message && error.message[0] && error.message[0].msg
            ? error.message[0].msg
            : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
          this.showAlert(true, networkError || errorMessage, false)
        }
      })
    } else {
      this.setState({ showModalInputsErrors: true })
      if (resetPassEmail && !resetPassEmail.match(emailsRegex)) {
        this.showAlert(true, t('invalidEmail'), false)
      }
    }
  };

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  handleKeyPress = (e, t) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.signIn(t)
    }
  }

  handleKeyPress2 = (e, t) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.sendChangePassEmail(t)
    }
  }

  redirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/home" />
    }
  };

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { user } = this.props;
    const { resetPasswordLoading, resetPassEmail } = this.state;
    // console.log("this.state.signupEmail: ", this.state.signupEmail);
    return (
      <Translation>
        {(t) => (
          <div className={`${signinSignupContainer}`}>
            <Header />
            <div
              style={{ backgroundColor: FULL_WHITE }}
              className={`${itemsContainer}`}
            >
              {!user && (
                <div className={`${item}`}>
                  <h2 style={{ color: BLACK }}>{t('loginTitle')}</h2>
                  <Input
                    type='text'
                    label={t('signinEmail')}
                    value={this.state.signinEmail}
                    onChange={this.handleInputChange}
                    name='signinEmail'
                    className={`${inputStyles}`}
                    showErrors={this.state.showErrorsSignIn}
                    isRequired
                  />
                  <Input
                    type={this.state.showPassword ? 'text' : 'password'}
                    label={t('signinPassword')}
                    value={this.state.signinPassword}
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => this.handleKeyPress(e, t)}
                    showErrors={this.state.showErrorsSignIn}
                    name='signinPassword'
                    // rightPicture={true}
                    imgClick={() => this.viewPassword()}
                    isRequired
                    auth
                  />
                  <Button
                    loading={this.state.signinLoading}
                    disabled={this.state.signinLoading}
                    onClick={() => this.signIn(t)}
                    content={
                      <p
                        style={{
                          color: WHITE,
                          margin: 0,
                          padding: 8,
                          fontSize: 17,
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}
                      >
                        {t('loginTitle')}
                      </p>
                    }
                    style={{
                      backgroundColor: BLACK,
                      marginTop: 10,
                      minWidth: 100,
                      minHeight: 38,
                    }}
                    loaderColor={FULL_WHITE}
                    height='38.8px'
                  />
                  <div className={`${forgetPasswordContainer}`}>
                    <p style={{ color: BLACK, marginRight: 10 }}>
                      {t('forgetPassword')}
                    </p>
                    <p
                      onClick={() => this.setState({ isModalOpen: true })}
                      style={{ cursor: 'pointer' }}
                    >
                      {t('forgetPassClickHere')}
                    </p>
                  </div>
                </div>
              )}
              {user && validateUser(['super-admin', 'admin'], user.userType) && (
                <div className={`${item}`} style={{ paddingBottom: 20 }}>
                  <h2 style={{ color: BLACK }}>{t('signupTitle')}</h2>
                  <Input
                    type='text'
                    label={t('signinEmail')}
                    value={this.state.signupEmail}
                    onChange={this.handleInputChange}
                    onFocus={() => this.verifySession(t)}
                    name='signupEmail'
                    className={`${inputStyles}`}
                    showErrors={this.state.showErrorsSignUp}
                    isRequired
                  />
                  <Input
                    type={this.state.showPassword ? 'text' : 'password'}
                    label={t('signinPassword')}
                    value={this.state.signupPassword}
                    onFocus={() => this.verifySession(t)}
                    onChange={this.handleInputChange}
                    showErrors={this.state.showErrorsSignUp}
                    name='signupPassword'
                    // rightPicture={true}
                    imgClick={() => this.viewPassword()}
                    isRequired
                    auth
                  />
                  <Input
                    type='text'
                    label={t('signupName')}
                    value={this.state.signupName}
                    onFocus={() => this.verifySession(t)}
                    onChange={this.handleInputChange}
                    name='signupName'
                    optional
                  />
                  <Input
                    type='text'
                    label={t('signupPhone')}
                    value={this.state.signupPhone}
                    onFocus={() => this.verifySession(t)}
                    onChange={this.handleInputChange}
                    name='signupPhone'
                    optional
                  />
                  <Button
                    loading={this.state.signupLoading}
                    onClick={() => this.signUp(t)}
                    disabled={this.state.signupLoading}
                    content={
                      <p
                        style={{
                          color: WHITE,
                          margin: 0,
                          padding: 8,
                          fontSize: 17,
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}
                      >
                        {t('signupTitle')}
                      </p>
                    }
                    style={{
                      backgroundColor: BLACK,
                      marginTop: 10,
                      minWidth: 100,
                      minHeight: 38,
                    }}
                    loaderColor={FULL_WHITE}
                  />
                </div>
              )}
            </div>
            <HomeFooter />
            <ModalComponent
              isOpen={this.state.isModalOpen}
              onRequestClose={() => this.setState({ isModalOpen: false })}
            >
              <div className={`${resetPasswordContainer}`}>
                <h2
                  style={{
                    color: BLACK,
                    fontWeight: 'normal',
                    marginBottom: 10,
                    marginTop: 30,
                  }}
                >
                  {t('resetPasswordTitle')}
                </h2>
                <Input
                  type='text'
                  label={t('resetEmail')}
                  value={resetPassEmail}
                  showErrors={this.state.showModalInputsErrors}
                  onChange={this.handleInputChange}
                  onKeyPress={(e) => this.handleKeyPress2(e, t)}
                  disabled={resetPasswordLoading}
                  name='resetPassEmail'
                  isRequired
                />
                <Button
                  loading={resetPasswordLoading}
                  onClick={() => this.sendChangePassEmail(t)}
                  disabled={resetPasswordLoading}
                  content={
                    <p
                      style={{
                        color: WHITE,
                        margin: 0,
                        padding: 8,
                        fontSize: 17,
                        paddingLeft: 30,
                        paddingRight: 30,
                      }}
                    >
                      {t('resetPasswordButton')}
                    </p>
                  }
                  style={{
                    backgroundColor: BLACK,
                    marginTop: 20,
                    minWidth: 100,
                    minHeight: 38,
                    marginBottom: 30,
                  }}
                  loaderColor={FULL_WHITE}
                />
              </div>
            </ModalComponent>
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
            {this.redirect()}
          </div>
        )}
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInSignUp);
