import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import { css } from 'emotion';
import bgImage from "../../assets/dashboard/background-image.png";
import { BLACK, BLACK_OPACITY, BLACK_OPACITY2, FULL_WHITE, WHITE } from '../../utils/color-palatte';
import HomeFooter from '../home-footer/HomeFooter';
import Button from '../../utils/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getData, postData } from '../../fetch/services';
import moment from 'moment';
import Loader from '../../utils/Loader';
import Alert from '../../utils/Alert';
import searchIcon from "../../assets/icons/search-icon.svg";
import Input from '../../utils/Input';
import { Link } from 'react-router-dom';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
display: flex;
`

const title = css`
margin: 0;
font-size: 50px
`

const cartContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}
`

const shoppingCartContentContainer = css`
min-height: 300px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
flex-wrap: wrap;
`

const tableContainer = css`
margin: 10px;
@media (max-width: 40em){
  width: 300px;
}
`

const productName = css`
@media (max-width: 40em){
margin-left: -40% !important;
}
`

const thText = css`
color: ${WHITE};
font-weight: bold;
margin: 10px;
@media (max-width: 40em){
  color: ${BLACK};
}
`

const loader = css`
margin-top: 50px;
margin-bottom: 50px;
`

const seeMoreButton = css`
color: ${WHITE};
margin: 0px;
padding: 8px;
font-size: 20px;
padding-left: 20px;
padding-right: 20px;
font-weight: bold;
`

const productButton = css`
color: ${WHITE};
margin: 0px;
padding: 8px;
font-size: 12px;
padding-left: 10px;
padding-right: 10px;
font-weight: bold;
`

const filtersContainer = css`
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: row;
border: ${BLACK_OPACITY} solid 1px;
border-radius: 3px;
margin: 10px;
width: 95%;
flex-wrap: wrap;
max-width: 1000px;
  `

const inputContainer = css`
display: flex;
flex-direction: row;
@media(max-width: 650px) {
  flex-wrap: wrap;
}
`

const searchInput = css`
margin: 10px;
  `

const searchInputContainer = css`
max-width: 380px;
min-width: 300px
`

const inputPictureStyle = css`
margin-right: 10px;
  `

const trStyles = css`
@media(max-width: 40em) {
  border: 1px solid #000 !important;
}
`

class Users extends React.Component {

  state = {
    loading: true,
    users: [],
    skipItems: 0,
    limitItems: 5,
    search: "",
    plusProductsNumber: 5,
    keepIncreasingCounter: false,
  };

  componentDidMount = () => {
    this.getUsers();
    window.scrollTo(0, 0)
  };

  onEndReached = () => {
    // console.log("onEndReached");
    const {
      skipItems,
      limitItems,
      plusProductsNumber,
      keepIncreasingCounter
    } = this.state;
    if (keepIncreasingCounter) {
      this.setState({
        skipItems: skipItems + plusProductsNumber,
        limitItems: limitItems + plusProductsNumber
      }, () => {
        this.getUsers();
      });
    } else {
      this.getUsers();
    }
  };

  getUsers = () => {
    return new Promise(async (resolve, reject) => {
      const { skipItems, limitItems, search, users } = this.state;
      const { user } = this.props;
      this.setState({ loading: true }, async () => {
        try {
          await this.verifySession()
          const response = await getData(`/user/get-users/?userId=${user ? user._id : ""}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}`);
          // console.log("getUsers / response: ", response);
          const newOrders = response.body;
          // console.log("getUsers / newOrders: ", newOrders);
          const finalOrders = users
            .concat(newOrders)
            .filter((item, index, self) =>
              index === self.findIndex((t) => (
                t._id === item._id
              ))
            )
          // .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
          // console.log("getUsers / finalOrders: ", finalOrders);
          this.setState({
            loading: false,
            users: finalOrders,
            keepIncreasingCounter: newOrders.length > 0
          });
          resolve();
        } catch (e) {
          this.setState({ loading: false })
          resolve(e);
          // console.log("getUsers / error: ", e)
          if (e !== "session-expired") {
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        }
      })
    })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  onRefreshProducts = () => {
    // const { saveUserPosts } = this.props;
    const { plusProductsNumber } = this.state;
    // saveUserPosts([]);
    this.setState({ skipItems: 0, limitItems: plusProductsNumber, users: [], search: "" }, () => {
      this.getUsers();
    })
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.setState({
        users: [],
        skipItems: 0,
        limitItems: 5,
      }, () => {
        this.getUsers();
      })
    }
  }

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { users } = this.state;
    // console.log("users: ", users);
    return (
      <Translation>
        {t =>
          <div className={`${cartContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("usersTitle")}</h1>
                </span>
              </span>
            </div>
            <div className={`${shoppingCartContentContainer}`} style={{ backgroundColor: FULL_WHITE }}>
              <div className={`${filtersContainer}`}>
                <div className={`${inputContainer}`}>
                  <Input
                    type="text"
                    className={`${searchInput}`}
                    containerStyle={`${searchInputContainer}`}
                    pictureStyle={`${inputPictureStyle}`}
                    placeholder={t('searchUsersInputPlaceholder')}
                    value={this.state.search}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleInputChange}
                    name='search'
                    rightPicture={<img
                      onClick={() => {
                        this.setState({
                          users: [],
                          skipItems: 0,
                          limitItems: 5,
                        }, () => {
                          this.getUsers();
                        })
                      }}
                      src={searchIcon}
                      style={{ width: 37, height: 20, cursor: 'pointer', backgroundColor: BLACK, padding: 6, marginRight: 10 }}
                      alt="search-button"
                    />}
                  />
                  <Button
                    onClick={() => this.onRefreshProducts()}
                    content={<p style={{ color: WHITE, margin: 0, fontSize: 15, fontWeight: 'bold' }}>{t("seeAllProductsProducts")}</p>}
                    style={{ backgroundColor: BLACK, height: 30, minWidth: 90, margin: 10, minHeight: 38 }}
                  />
                </div>
              </div>
              <div className={`${tableContainer}`}>
                {users && users.length > 0
                  ? <Table style={{ borderSpacing: 0 }}>
                    <Thead style={{ backgroundColor: BLACK, pading: 10 }}>
                      <Tr>
                        <Th><p className={`${thText}`}>{t('userEmail')}</p></Th>
                        <Th><p className={`${thText}`}>{t('userName')}</p></Th>
                        <Th><p className={`${thText}`}>{t('userTypeTable')}</p></Th>
                        <Th><p className={`${thText}`}>{t('userPhone')}</p></Th>
                        <Th><p className={`${thText}`}>{t('userCreated')}</p></Th>
                        <Th><p className={`${thText}`}>{t('userActivities')}</p></Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {users.map(user =>
                        <Tr className={`${trStyles}`} key={user._id}>
                          <Td>
                            {<p style={{ margin: 10, color: BLACK }} className={`${productName}`}>
                              {user.email}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ margin: 10, color: BLACK }} className={`${productName}`}>
                              {user.name || t('notDefined')}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ margin: 10, color: BLACK }} className={`${productName}`}>
                              {t(user.userType) || t('notDefined')}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ margin: 10, color: BLACK }} className={`${productName}`}>
                              {user.phone || t('notDefined')}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ color: BLACK_OPACITY2, margin: 10 }} className={`${productName}`}>
                              {user.creationDate ? moment(user.creationDate).format('YYYY-MM-DD hh:mm A') : t('notDefined')}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ margin: 10, color: BLACK }} className={`${productName}`}>
                              {user.activitiesCount || 0}
                            </p>}
                          </Td>
                          <Td>
                            <Link to={`/user-stats/?user=${user._id}`}>
                              <div style={{ backgroundColor: BLACK, margin: 10 }}>
                                <p className={`${productButton}`}>
                                  {t("userStatsButton")}
                                </p>
                              </div>
                            </Link>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  : !this.state.loading
                    ? this.state.search
                      ? <h2 style={{ color: BLACK, margin: 50 }}>{t('noResultsUsers')}</h2>
                      : <h2 style={{ color: BLACK, margin: 50 }}>{t('usersEmpty')}</h2>
                    : <Loader color={BLACK} className={`${loader}`} />}
              </div>
              {this.state.keepIncreasingCounter && !this.state.loading
                ? <Button
                  onClick={() => this.onEndReached()}
                  content={<p className={`${seeMoreButton}`}>
                    {t("seeMoreProducts")}
                  </p>}
                  style={{ backgroundColor: BLACK, marginTop: 20, minHeight: 38, marginBottom: 20 }}
                />
                : !this.state.loading
                  ? users && users.length > 0 ? <h5 style={{ color: BLACK, margin: 20, marginTop: 50 }}>{t('noMoreUsers')}</h5> : null
                  : <Loader color={BLACK} className={`${loader}`} />}
            </div>
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
  } = state;
  return {
    user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
