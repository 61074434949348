import React from 'react'
import './product.css';
import offerImage from "../../assets/icons/offer-icon.svg"
import { BLACK, WHITE } from '../color-palatte';
import { Translation } from 'react-i18next';
import cartIcon from "../../assets/icons/yellow-cart.svg";
import editIcon from "../../assets/icons/edit.svg";
import removeRedIcon from "../../assets/icons/remove-red-icon.svg";
import { css } from 'emotion'
import ProductDetailModal from '../ProductDetailsModal';
import saveStat from '../saveStat';
import { Link } from 'react-router-dom';
import thousandSeparator from '../thousandSeparator';
import defaultProductImage from "../../assets/icons/timing-belt.svg";
import { validateUser } from '../validateUserType';
import ProductNameTitle from '../ProductNameTitle';

const buttonBackground = css`
background-color: ${BLACK};
padding: 5px;
`

const buttonText = css`
color: ${WHITE};
font-size: 12px !important;
`

const removeRedIconStyles = css`
width: 30px;
height: 30px;
margin: 5px;
cursor: pointer;
`

const buttonContainer = css`
display: flex;
flex-direction: row;
position: absolute;
right: 20px;
top: 20px
`

const editIconStyles = css`
width: 30px;
height: 30px;
margin: 5px;
cursor: pointer;
border-radius: 5px;
background-color: ${WHITE}
`

const cursorStyle = css`
cursor: pointer
`

class Product extends React.Component {

  state = {
    isModalOpen: false
  }

  openModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen }, () => {
      const { product } = this.props;
      const userId = localStorage.getItem('userId')
      if (product && userId) {
        saveStat('view', product)
      }
    })
  }

  render() {
    const {
      product,
      addProductToCart,
      removeProductFromCart,
      isAdded,
      removeProduct,
      isLogged,
      showPrice,
      addToFavourites,
      removeFromFavourites,
      bsValue,
      currency
    } = this.props;
    return (
      <Translation>
        {t =>
          <>
            <div className="product-container" style={!isLogged ? { height: 340 } : {}}>
              {(isLogged && validateUser(["super-admin", "admin", "products-admin"], isLogged.userType)) && <div className={`${buttonContainer}`}>
                {removeProduct && <img onClick={removeProduct} src={removeRedIcon} className={`${removeRedIconStyles}`} alt="" />}
                <Link to={`/edit-product/?product=${product._id}`}>
                  <img src={editIcon} className={`${editIconStyles}`} alt="" />
                </Link>
              </div>}
              {product.isOffered && <img onClick={this.openModal} className="offer-icon" src={offerImage} alt="offer icon" />}
              {(product.files && product.files.length > 0) ? <img
                onClick={this.openModal}
                className="product-image"
                src={product.files[0]}
                alt="product icon"
                style={{ cursor: "pointer" }}
              />
                : <div
                  className="product-image"
                  style={{ cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  onClick={this.openModal}
                >
                  <img src={defaultProductImage} style={{ width: 100, height: 100 }} alt="product icon" />
                </div>}
              <div onClick={this.openModal} className="product-title-container">
                <ProductNameTitle
                  type="h3"
                  description={product.name}
                  className={`product-title ${cursorStyle}`}
                />
                {/* <h3 className={`product-title ${cursorStyle}`} style={{ color: BLACK }}>{product.name}</h3> */}
              </div>
              {product.description && <div onClick={this.openModal} className={`product-description-container ${cursorStyle}`}>
                <p className="product-description" style={{ color: BLACK }}>{product.description}</p>
              </div>}
              {showPrice && <div className={`${cursorStyle}`}>
                <h5 style={{ color: BLACK, fontWeight: 'bold' }}>{`${thousandSeparator(product.price)}$`}</h5>
              </div>}
              {isLogged
                ? <div className="product-buttons-container">
                  <div
                    onClick={isAdded ? removeProductFromCart : addProductToCart}
                    style={{ border: `${WHITE} 1px solid` }}
                    className={`product-button-container ${isAdded ? buttonBackground : ""}`}
                  >
                    {isAdded && <img src={cartIcon} className="cart-icon-styles" alt="" />}
                    <p className={`product-button-text ${isAdded ? buttonText : ""}`}>{!isAdded ? t("addToCart") : t("removeFromCart")}</p>
                  </div>
                  <div onClick={this.openModal} style={{ border: `${WHITE} 1px solid` }} className="product-button-container">
                    <p className="product-button-text">{t("moreDetails")}</p>
                  </div>
                </div>
                : null}
            </div>
            {product
              ? <ProductDetailModal
                bsValue={bsValue}
                currency={currency}
                isOpen={this.state.isModalOpen}
                addToFavourites={addToFavourites}
                removeFromFavourites={removeFromFavourites}
                removeProduct={removeProduct}
                user={isLogged}
                onRequestClose={() => this.setState({ isModalOpen: false })}
                selectedProduct={product}
              />
              : null}
          </>
        }
      </Translation>
    )
  }
}

export default Product;