import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import HomeFooter from '../home-footer/HomeFooter';
import { css } from 'emotion'
import { BLACK, FULL_WHITE, RED, WHITE } from '../../utils/color-palatte';
import Input from "../../utils/Input";
import Select from "../../utils/Select";
import Button from "../../utils/Button";
import { getData, postData, putData } from '../../fetch/services';
import motorsBrands from "../../motor-brands.json";
import MultiImageInput from 'react-multiple-image-input';
import saveFilesToCloud from '../../utils/saveFilesToCloud';
import Alert from '../../utils/Alert';
import Loader from '../../utils/Loader';
import deleteFiles from '../../utils/deleteFiles';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const signinSignupContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const itemsContainer = css`
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
`

const item = css`
min-width: 280px;
display: flex;
justify-content: flex-start;
max-width: 400px;
display: flex;
align-items: flex-start;
flex-direction: column;
padding-right: 20px;
padding-left: 20px;
@media(max-width: 390px) {
  width: 100%
}
`

const inputStyles = css`
width: 100%;
`

const selectStyles = css`
margin: 20px;
margin-left: 0px;
  `

class EditProduct extends React.Component {

  state = {
    files: {},
    currentFiles: [],
    name: "",
    description: "",
    partNumber: "",
    brand: "",
    motorBrand: "",
    price: "",
    serie: "",
    loading: false,
    showInputsErrors: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.fetchProduct()
  };

  fetchProduct = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const productId = url.searchParams.get("product");
    if (productId) {
      return new Promise(async (resolve, reject) => {
        this.setState({ loading: true }, async () => {
          try {
            const response = await getData(`/product/get-product/?productId=${productId}`);
            // console.log("fetchProduct / response: ", response);
            const product = response.body

            this.setState({
              currentFiles: product.files || [],
              loading: false,
              name: product.name,
              description: product.description,
              partNumber: product.partNumber,
              brand: product.brand,
              price: product.price,
              serie: product.serie,
              motorBrand: product.motorBrand,
              product: product,
            });
            resolve();
          } catch (e) {
            resolve(e);
            // console.log("fetchProduct / error: ", e)
            this.setState({ loading: false })
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        })
      })
    }
  }

  saveProduct = (t) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const productId = url.searchParams.get("product");

    if (productId) {
      return new Promise(async (resolve, reject) => {
        this.setState({ loading: true }, async () => {
          try {
            const { user } = this.props;
            const { brand, serie, name, description, price, partNumber, files, currentFiles, motorBrand } = this.state;
            let productCopy = {
              brand,
              serie,
              name,
              description,
              price,
              partNumber,
              motorBrand,
              nameFilter: name.split(' ').join('-'),
              descriptionFilter: description.split(' ').join('-'),
            }

            if (files && files.length > 0) {
              await deleteFiles(currentFiles)
              const savedFiles = await saveFilesToCloud(files);
              productCopy.files = savedFiles
            }

            const data = {
              productId,
              userId: user._id,
              product: productCopy
            }
            await putData(`/product/edit-product`, data);
            // console.log("saveProduct / response: ", response);
            this.fetchProduct()
            this.showAlert(true, t('productEdited'), true)
            this.setState({
              loading: false,
              files: {},
            });
            resolve();
          } catch (e) {
            resolve(e);
            // console.log("saveProduct / error: ", e)
            this.setState({ loading: false })
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        })
      })
    }
  }

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  onChange = async ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const crop = {
      unit: '%',
      aspect: 4 / 4,
      width: '100'
    };
    // const { user } = this.props;
    // console.log("files: ", this.state.files);
    // console.log("currentFiles: ", this.state.currentFiles);
    return (
      <Translation>
        {t =>
          <div className={`${signinSignupContainer}`}>
            <Header />
            <div style={{ backgroundColor: FULL_WHITE }} className={`${itemsContainer}`}>
              <div className={`${item}`} style={{ paddingBottom: 20 }}>
                <h2 style={{ color: BLACK }}>{t("editProductTitle")}</h2>
                <h5 style={{ color: BLACK }}>{t("productImages")}</h5>
                {this.state.loading
                  ? <Loader color={BLACK} />
                  : <MultiImageInput
                    max={5}
                    images={this.state.files}
                    setImages={images => this.setState({ files: Object.values(images) })}
                    cropConfig={{ crop, ruleOfThirds: true }}
                    theme={{
                      background: BLACK,
                      outlineColor: this.state.showInputsErrors ? RED : WHITE,
                      textColor: WHITE,
                      buttonColor: '#ff0e1f',
                      modalColor: BLACK
                    }}
                    disabled={this.state.loading}
                    style={this.state.showInputsErrors && !this.state.images ? { border: `1px solid ${RED}` } : {}}
                  />}
                <Input
                  type="text"
                  label={t('createProductName')}
                  value={this.state.name}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='name'
                  className={`${inputStyles}`}
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                  isRequired
                />
                <Input
                  type="text"
                  label={t('createProductDescription')}
                  value={this.state.description}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='description'
                  className={`${inputStyles}`}
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                />
                <Input
                  type="text"
                  label={t('partNumber')}
                  value={this.state.partNumber}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='partNumber'
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                  isRequired
                />
                <Input
                  type="number"
                  label={t('price')}
                  value={this.state.price}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='price'
                  disabled={this.state.loading}
                  showErrors={this.state.showInputsErrors}
                  isRequired
                />
                <Input
                  type="text"
                  label={t('createProductMotorBrand')}
                  value={this.state.motorBrand}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='motorBrand'
                  className={`${inputStyles}`}
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                  isRequired
                />
                <div>
                  <Select
                    showErrors={this.state.showInputsErrors}
                    className={`${selectStyles}`}
                    value={this.state.brand}
                    onFocus={() => this.verifySession(t)}
                    name="brand"
                    disabled={this.state.loading}
                    onChange={this.onChange}
                  >
                    <option value="">{t('createProductBrand')}</option>
                    {motorsBrands.BRANDS.map(brand =>
                      <option value={brand}>{brand}</option>
                    )}
                  </Select>
                  {this.state.brand
                    ? <Select
                      value={this.state.serie}
                      onFocus={() => this.verifySession(t)}
                      showErrors={this.state.showInputsErrors}
                      className={`${selectStyles}`}
                      disabled={this.state.loading}
                      name="serie"
                      onChange={this.onChange}
                    >
                      <option value="">{t('createProductSerie')}</option>
                      {motorsBrands[this.state.brand].map(serie =>
                        <option value={serie}>{serie}</option>
                      )}
                    </Select>
                    : null}
                </div>
                <Button
                  loading={this.state.loading}
                  onClick={() => this.saveProduct(t)}
                  disabled={this.state.loading}
                  content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30 }}>{t("saveProductButton")}</p>}
                  style={{ backgroundColor: BLACK, marginTop: 10, minWidth: 100, minHeight: 38 }}
                  loaderColor={FULL_WHITE}
                />
              </div>
            </div>
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProduct);
