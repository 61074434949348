import React from 'react'
import { css } from 'emotion'
import { BLACK_OPACITY } from './color-palatte'
import Modal from 'react-modal';
import closeIcon from "../assets/icons/remove-icon.svg";

const ModalComponent = (props) => {
  const closeiconStyles = css`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 500;
  @media(max-width: 650px) {
    top: 20px;
    right: 20px;
  }
`
  const customStyles = {
    overlay: {
      backgroundColor: BLACK_OPACITY,
      zIndex: 200
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };
  return (
    <Modal
      {...props}
      style={customStyles}
    >
      <div>
        <img className={`${closeiconStyles}`} src={closeIcon} onClick={() => props.onRequestClose()} alt="" />
        {props.children}
      </div>
    </Modal>
  )
}

export default ModalComponent;