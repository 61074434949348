import {
  SAVE_USER,
  SIGNOUT,
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  EDIT_PRODUCT_FROM_CART,
  CLEAR_CART
} from './types';

function saveUser(user) {
  return {
    type: SAVE_USER,
    data: user,
  };
};

function addProductToCart(product) {
  return {
    type: ADD_PRODUCT_TO_CART,
    data: product,
  };
};

function removeProductFromCart(product) {
  return {
    type: REMOVE_PRODUCT_FROM_CART,
    data: product,
  };
};

function editProduct(productId, newProduct) {
  return {
    type: EDIT_PRODUCT_FROM_CART,
    productId: productId,
    newProduct: newProduct,
  };
};

function signOut() {
  return {
    type: SIGNOUT,
  };
};

function clearCart() {
  return {
    type: CLEAR_CART,
  };
};

const actionCreators = {
  saveUser,
  signOut,
  addProductToCart,
  removeProductFromCart,
  editProduct,
  clearCart
};

export { actionCreators };
