import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import whiteLogo from "../assets/logos/white-bg.png";
import thousandSeparator from "./thousandSeparator";
// Date Fns is used to format the dates we receive
// from our API call

// define a generatePDF function that accepts a tickets argument
const generatePDF = (products, totalAmount, t, currency, bsValue, order, thirdPerson) => {
  // console.log("products: ", products);
  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles
  const tableColumn = [t('cartProductTitle'), t("cartPriceTitle"), t("cartQuantityTitle"), "Total"];
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  products.forEach((product, i) => {
    const productTotalAmount = product.quantitySelected ? product.quantitySelected * product.price : product.price
    const productData = [
      product.name,
      `${currency === "bs" ? thousandSeparator(productTotalAmount * Number(bsValue)) : thousandSeparator(productTotalAmount)}${currency === "bs" ? "Bs" : "$"}`,
      product.quantitySelected || 1,
      `${(product.quantitySelected || 1) * product.price}$`,
    ];
    // push each tickcet's info into a row
    tableRows.push(productData);
    if (i + 1 === products.length) {
      tableRows.push([
        "",
        "",
        `${t('cartTotal')}:`,
        totalAmount,
      ])
    }
  });

  // startY is basically margin-top
  doc.autoTable(
    tableColumn,
    tableRows,
    { startY: thirdPerson ? 65 : 60 }
  );
  // console.log("order: ", order);
  // console.log("thirdPerson: ", thirdPerson);
  const textsArray = [`Mayor Diesel - ${t('order')}: ${order.order.orderNumber} - ${moment().format('YYYY-MM-DD hh:mm A')}`, `${t('orderUser')}: ${order.user.name ? `${order.user.name}, ` : ""}${order.user.email}`]
  if (thirdPerson) {
    textsArray.push(`${t('orderThirdPerson')}: ${thirdPerson.name ? `${thirdPerson.name}, ` : ""}${thirdPerson.email}`)
  }
  // ticket title. and margin-top + margin-left
  var img = new Image()
  img.src = whiteLogo
  img.width = "150";
  img.height = "100";
  doc.addImage(
    img,
    "PNG",
    5,
    0,
    80,
    30
  );

  // doc.setTextColor(255, 255, 255);
  doc.setFontSize(22);
  doc.setFont("times");
  doc.text(textsArray, 14, 40);

  doc.setFillColor(0, 0, 0);
  // we define the name of our PDF file.
  doc.save(`mayordiesel-${t('order')}-${order.order.orderNumber}-${moment().format('YYYY-MM-DD hh:mm A')}.pdf`);
};

export default generatePDF;