import React from 'react'
import { css } from 'emotion'
import Loader from './Loader'
import { BLACK, WHITE } from './color-palatte'
const Button =  ({
  loading,
  content,
  width,
  height,
  className,
  onClick,
  type,
  style,
  disabled,
  loaderColor
}) => {
  const ButtonStyle = css`
    height: 100%;
    width: ${width};
    height: ${height};
    border-radius: 2px;
    background-color: ${WHITE};
    border: none;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${BLACK};
    cursor: pointer;
    position: relative;
`
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      style={style}
      className={`${ButtonStyle} ${className}`}
    >
      {loading
        ? <Loader color={loaderColor || BLACK} />
        : content}
    </button>
  )
}

export default Button;