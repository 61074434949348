import React from 'react';
import { Translation } from 'react-i18next';
import { BLACK, WHITE, YELLOW } from '../../utils/color-palatte';
import { css } from 'emotion';
import contactUsBgImage from "../../assets/dashboard/contact-us-bg.png"
import { Link } from 'react-router-dom'

const contactUsContainer = css`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-height: 200px;
  background-image: url("${contactUsBgImage}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media(max-width: 1070px) {
    flex-direction: column;
  }
`

const firstItemsContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 70%;
@media(max-width: 1070px) {
  width: 100%
}
`

const secondItemsContainer = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 30%;
@media(max-width: 1070px) {
  width: 100%
}
`

const contactButton = css`
padding: 10px;
border-radius: 2px;
cursor: pointer;
@media(max-width: 1070px) {
  margin-top: 10px;
}
`

class ContactUs extends React.Component {

  render() {
    const { title, content } = this.props;
    return (
      <Translation>
        {t =>
          <div style={{ backgroundColor: WHITE }} className={`${contactUsContainer}`}>
            <div className={`${firstItemsContainer}`}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <h1 style={{ color: WHITE, margin: 0 }}>{title || t('contactUsTitle')}</h1>
                <h2 style={{ color: WHITE, margin: 0, marginTop: 10 }}>{content || t('contactUsContent')}</h2>
              </div>
            </div>
            <div className={`${secondItemsContainer}`}>
              {this.props.to
                ? <Link to={this.props.to}>
                  <div className={`${contactButton}`} style={{ backgroundColor: YELLOW }}>
                    <h3 style={{ color: BLACK, margin: 0 }}>{t('contactUsClickHere')}</h3>
                  </div>
                </Link>
                : <Link to='/'>
                  <div onClick={() => window.scrollTo(0, 0)} className={`${contactButton}`} style={{ backgroundColor: YELLOW }}>
                    <h3 style={{ color: BLACK, margin: 0 }}>{t('contactUsClickHere')}</h3>
                  </div>
                </Link>}
            </div>
          </div>
        }
      </Translation>
    );
  }
}

export default ContactUs;
