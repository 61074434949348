import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import ContactUsComplete from '../contact-us-complete/ContactUsComplete';
import HomeFooter from '../home-footer/HomeFooter';
import { css } from 'emotion';
import firstImage from "../../assets/we/piece2.png";
import firstImage2 from "../../assets/we/piece3.png";
import { BLACK, FULL_WHITE, WHITE, YELLOW, YELLOW_DARK } from '../../utils/color-palatte';
import bgImage from "../../assets/dashboard/background-image.png";
import headerImage from "../../assets/we/piece.png";
import phoneIcon from "../../assets/we/phone-icon.svg";
import attachIcon from "../../assets/we/attach-icon.svg";
import directionIcon from "../../assets/we/position-icon.svg";
import emailIcon from "../../assets/we/email-icon.svg";
import { companyEmail, contactNumber, companyDirection, contactNumberOutside, companyEmailOutside, companyDirectionOutside, contactNumber2, contactNumberOutside2 } from '../../config';
import caterpillarIcon from "../../assets/brands/caterpillar-logo.png";
import cumminsIcon from "../../assets/brands/cummins-logo.png";
import detroitDieselIcon from "../../assets/brands/detroit-diesel-logo.png";
import navistarIcon from "../../assets/brands/navistar-logo.png";
import waukeshaIcon from "../../assets/brands/waukesha-logo.png";
import mackIcon from "../../assets/brands/mack-logo.png";
import jhonDeereIcon from "../../assets/brands/john-deere-logo.png";
import komatsuIcon from "../../assets/brands/komatsu-logo.png";
import perkinsIcon from "../../assets/brands/perkins-logo.png";
import yanmarIcon from "../../assets/brands/yanmar-logo.png";
import kubotaIcon from "../../assets/brands/kubota-logo.png";
import guaranteeIcon from "../../assets/we/guarantee.png";
import deliveryIcon from "../../assets/we/delivery.png";
import termsConditionsIcon from "../../assets/we/file.png";
import trucksSegmentImage from "../../assets/we-segment/trucks.png";
import seaAplicationImage from "../../assets/we-segment/sea-aplication.png";
import miningLogo from "../../assets/we-segment/mining-logo.png";
import farmLogo from "../../assets/we-segment/farm-logo.png";
import powerImage from "../../assets/we-segment/power-image.png";
import factoryImage from "../../assets/we-segment/factory-image.png";
import caseLogo from "../../assets/brands/case-logo.png";

const weContainer = css`
text-align: center;
min-height: 400px;
width: 100%;
position: relative;
padding-bottom: 30px;
`

const contentContainer = css`
display: flex;
flex-direction: row;
justify-content: center;
align-item: center;
flex-wrap: wrap;
padding-top: 50px;
`

const textContainer = css`
flex-direction: column;
align-item: center;
padding: 20px;
padding-top: 0;
max-width: 400px;
min-height: 400px;
display: flex;
justify-content: flex-start;
`

const firstImageStyle = css`
width: 250px;
height: 250px;
margin-bottom: 10px;
`

const secondImageStyle = css`
width: 250px;
height: 250px;
@media(max-width: 700px) {
  display: none
}
`

const headerImageStyle = css`
width: 200px;
height: 200px;
position: absolute;
right: 20%;
@media(max-width: 1050px) {
  width: 150px;
  height: 150px;
  right: 20px;
}
@media(max-width: 600px) {
  display: none
}
`

const imageContainer = css`
max-width: 300px;
// height: 400px;
position: relative;
@media(max-width: 810px) {
  margin-bottom: 20px;
}
`

const title = css`
margin: 0;
font-size: 50px
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}
`

const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
position: relative;
display: flex;
`

const box = css`
width: 100%;
height: 200px;
display: flex;
justify-content: center;
align-items: center;
`

const boxContainer = css`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
width: 200px;
height: 420px;
margin-top: 20px;
// min-width: 100%;
`

const boxTitleContainer = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-width: 100%;
`

const boxTitleSecondContainer = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-top: 2px solid gray;
margin-top: 10px;
padding-top: 10px;
min-width: 100%;
`
const boxTitleFirstContainer = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-height: 70px;
`

const boxesContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
padding-top: 50px;
padding-bottom: 50px;
`

const brandsContainer = css`
background-color: ${FULL_WHITE};
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
`

const brandsContainerDots = css`
background-color: ${FULL_WHITE};
display: flex;
justify-content: center;
align-items: center;
`

const brandsContainerDots2 = css`
display: flex;
justify-content: end;
align-items: center;
flex-wrap: wrap;
max-width: 900px;

@media(max-width: 650px) {
  justify-content: center;
  }
`

const brandsContainer2 = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
padding-top: 50px;
padding-bottom: 50px;
max-width: 900px;
width: 100%;
`

const brandsContainer3 = css`
display: flex;
justify-content: end;
align-items: end;
flex-direction: column;
flex-wrap: wrap;
padding-top: 20px;
padding-bottom: 20px;
max-width: 900px;
min-width: 350px;
@media(max-width: 350px) {
  min-width: 300px;
}
`

const brandContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
height: 150px;
justify-content: space-between;
`

const boxImage = css`
width: 60px;
height: 60px;
`

const yellowTitleContainer = css`
background-color: ${YELLOW};
width: 100%;
display: flex;
align-items: center;
padding: 10px;
justify-content: center;
`

const dotTextContainer = css`
display: flex;
align-items: end;
flex-direction: column;
margin-right: 20px;
@media(max-width: 650px) {
  margin-right: 0;
  align-items: center;
}
`

const dotTextContainer2 = css`
display: flex;
align-items: center;
flex-direction: row;
margin-right: 20px;
align-self: baseline;
max-width: 300px;
`

const dotContent = css`
font-size: 20px;
text-align: left;
`

const dotStyle = css`
font-size: 30px;
margin-right: 10px;
margin-left: 10px;
`

const segmentsContainer = css`
@media(max-width: 650px) {
  justify-content: center;
}
`

const segmentsFirstContainer = css`
@media(max-width: 650px) {
  justify-content: center;
  align-items: center;
}
`

class We extends React.Component {

  state = {
  };

  componentDidMount = () => {
    window.scrollTo(0, 0)
  };

  render() {
    // const { user } = this.props;
    // console.log("user: ", user);
    return (
      <Translation>
        {t =>
          <div className={`${weContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("weTitle1")}</h1>
                </span>
                <span style={{ marginLeft: 15 }}>
                  <h1 className={`${title}`} style={{ color: YELLOW }}>{t("weTitle2")}</h1>
                </span>
                <img className={`${headerImageStyle}`} src={headerImage} alt="" />
              </span>
            </div>
            <div className={`${contentContainer}`} style={{ backgroundColor: FULL_WHITE }}>
              <div className={`${imageContainer}`}>
                <img className={`${firstImageStyle}`} src={firstImage} alt="" />
                <img className={`${secondImageStyle}`} src={firstImage2} alt="" />
              </div>
              <div className={`${textContainer}`}>
                <div style={{ color: BLACK, borderBottom: `4px solid ${YELLOW}`, width: 115, textAlign: 'left', margin: 0 }}></div>
                <h1 style={{ color: BLACK, textAlign: 'left' }}>{t('weTitle')}</h1>
                <p style={{ color: BLACK, margin: 0, fontSize: 18, textAlign: 'left' }}>{t('weContent')}</p>
                <h1 style={{ color: BLACK, textAlign: 'left' }}>{t('weClientServiceTitle')}</h1>
                <p style={{ color: BLACK, margin: 0, fontSize: 18, textAlign: 'left' }}>{t('weClientServiceContent')}</p>
              </div>
            </div>
            <div style={{ flexDirection: 'column', backgroundColor: FULL_WHITE, paddingTop: 50, display: 'flex', alignItems: 'center' }}>
              <h1 style={{ color: BLACK, textAlign: 'center', margin: 0 }}>{t('weProductsTitle')}</h1>
              <div style={{ color: BLACK, borderBottom: `4px solid ${YELLOW}`, width: 115, textAlign: 'center', margin: 20 }}></div>
              <p style={{ color: BLACK, margin: 0, fontSize: 18, textAlign: 'center' }}>{t('weProductsContent')}</p>
            </div>
            <div className={`${brandsContainer}`}>
              <div className={`${brandsContainer2}`}>
                <div className={`${yellowTitleContainer}`} style={{ marginBottom: 20 }}>
                  <h1 style={{ color: BLACK, fontWeight: 'bold', margin: 0 }}>{t('weFoEnginesTitle')}</h1>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 100 }} src={caterpillarIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>CATERPILLAR®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 120, height: 120 }} src={cumminsIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>CUMMINS®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 120 }} src={detroitDieselIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>DETROIT DIESEL®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 160, height: 33, marginTop: 30 }} src={navistarIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>NAVISTAR®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 185, height: 80 }} src={waukeshaIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>WAUKESHA®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 150, height: 80 }} src={mackIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>MACK®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 170, height: 120 }} src={jhonDeereIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>JHON DEERE®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 180, height: 33, marginTop: 30 }} src={komatsuIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>KOMATSU®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 180, height: 50, marginTop: 30 }} src={caseLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold', marginTop: 60 }}>CASE®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 120, height: 120 }} src={perkinsIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>PERKINS®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 120 }} src={yanmarIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>YANMAR®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 40, marginTop: 30 }} src={kubotaIcon} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>KUBOTA®</p>
                </div>
              </div>
              <p style={{ maxWidth: 900, textAlign: 'center', marginBottom: 20, marginTop: -20 }}>{t('logosWarnings')}</p>
            </div>
            <div className={`${brandsContainer}`}>
              <div className={`${brandsContainer2}`} style={{ margin: 0, padding: 0 }}>
                <div className={`${yellowTitleContainer}`}>
                  <h1 style={{ color: BLACK, fontWeight: 'bold', margin: 0 }}>{t('weMarketSegmentTitle')}</h1>
                </div>
              </div>
            </div>
            <div className={`${brandsContainerDots}`}>
              <div className={`${brandsContainerDots2} ${segmentsContainer}`}>
                <div className={`${brandsContainer3} ${segmentsFirstContainer}`}>
                  <div className={`${dotTextContainer}`}>
                    <img style={{ width: 300, height: 200 }} src={trucksSegmentImage} alt="" />
                    <div className={`${dotTextContainer2}`}>
                      <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weDotText1')}</p>
                    </div>
                  </div>
                  <div className={`${dotTextContainer}`}>
                    <img style={{ width: 300, height: 200 }} src={miningLogo} alt="" />
                    <div className={`${dotTextContainer2}`}>
                      <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weDotText2')}</p>
                    </div>
                  </div>
                  <div className={`${dotTextContainer}`}>
                    <img style={{ width: 300, height: 200 }} src={farmLogo} alt="" />
                    <div className={`${dotTextContainer2}`}>
                      <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weDotText3')}</p>
                    </div>
                  </div>
                </div>
                <div className={`${brandsContainer3}`}>
                  <div className={`${dotTextContainer}`}>
                    <img style={{ width: 300, height: 200 }} src={seaAplicationImage} alt="" />
                    <div className={`${dotTextContainer2}`}>
                      <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weDotText4')}</p>
                    </div>
                  </div>
                  <div className={`${dotTextContainer}`}>
                    <img style={{ width: 300, height: 200 }} src={powerImage} alt="" />
                    <div className={`${dotTextContainer2}`}>
                      <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weDotText5')}</p>
                    </div>
                  </div>
                  <div className={`${dotTextContainer}`}>
                    <img style={{ width: 300, height: 200 }} src={factoryImage} alt="" />
                    <div className={`${dotTextContainer2}`}>
                      <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weDotText6')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${brandsContainer}`}>
              <div className={`${brandsContainer2}`} style={{ margin: 0, padding: 0 }}>
                <div className={`${yellowTitleContainer}`}>
                  <h1 style={{ color: BLACK, fontWeight: 'bold', margin: 0 }}>{t('weSecondTitle')}</h1>
                </div>
              </div>
            </div>
            <div className={`${brandsContainerDots}`}>
              <div className={`${brandsContainerDots2}`}>
                <div className={`${brandsContainer3}`}>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText1')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText2')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText3')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText4')}</p>
                  </div>
                </div>
                <div className={`${brandsContainer3}`}>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText5')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText6')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText7')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weSecondDotText8')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${brandsContainer}`}>
              <div className={`${brandsContainer2}`} style={{ margin: 0, padding: 0 }}>
                <div className={`${yellowTitleContainer}`}>
                  <h1 style={{ color: BLACK, fontWeight: 'bold', margin: 0 }}>{t('weThirdTitle')}</h1>
                </div>
              </div>
            </div>
            <div className={`${brandsContainerDots}`}>
              <div className={`${brandsContainerDots2}`}>
                <div className={`${brandsContainer3}`}>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weThirdDotText1')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weThirdDotText2')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weThirdDotText3')}</p>
                  </div>
                </div>
                <div className={`${brandsContainer3}`}>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weThirdDotText4')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weThirdDotText5')}</p>
                  </div>
                  <div className={`${dotTextContainer2}`}>
                    <p className={`${dotStyle}`}>•</p><p className={`${dotContent}`}>{t('weThirdDotText6')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${brandsContainer}`} style={{ flexDirection: 'column' }}>
              <div style={{ color: BLACK, borderBottom: `4px solid ${YELLOW}`, width: 115, textAlign: 'center', margin: 0 }}></div>
              <img src={guaranteeIcon} style={{ width: 150, height: 150, marginTop: 30 }} alt="" />
              <h1 style={{ fontWeight: 'bold' }}>{t('weGauaranteeTitle')}</h1>
              <p style={{ maxWidth: 900, fontSize: 20 }}>{t('weGauaranteeContent')}</p>
              <img src={deliveryIcon} style={{ width: 150, height: 150, marginTop: 30 }} alt="" />
              <h1 style={{ fontWeight: 'bold' }}>{t('weDeliveryTitle')}</h1>
              <p style={{ maxWidth: 900, fontSize: 20 }}>{t('weDeliveryContent')}</p>
              <img src={termsConditionsIcon} style={{ width: 150, height: 150, marginTop: 30 }} alt="" />
              <h1 style={{ fontWeight: 'bold' }}>{t('weTermsConditionsTitle')}</h1>
              <p style={{ maxWidth: 900, fontSize: 20 }}>{t('weTermsConditionsContent')}</p>
            </div>
            <div className={`${boxesContainer}`} style={{ backgroundColor: FULL_WHITE }}>
              {contactNumber && <div className={`${boxContainer}`}>
                <div className={`${box}`} style={{ backgroundColor: YELLOW }}>
                  <img className={`${boxImage}`} src={phoneIcon} alt="" />
                </div>
                <div className={`${boxTitleContainer}`}>
                  <h3>{t('wePhoneTitle')}</h3>
                  <div className={`${boxTitleFirstContainer}`}>
                    <p style={{ margin: 5 }}>{`${contactNumber}`}</p>
                    <p style={{ margin: 5 }}>{`${contactNumber2}`}</p>
                  </div>
                  <div className={`${boxTitleSecondContainer}`}>
                    <p style={{ margin: 5 }}>{`${contactNumberOutside}`}</p>
                    <p style={{ margin: 5 }}>{`${contactNumberOutside2}`}</p>
                  </div>
                </div>
              </div>}
              {companyEmail && <div className={`${boxContainer}`}>
                <div className={`${box}`} style={{ backgroundColor: YELLOW_DARK }}>
                  <img className={`${boxImage}`} src={emailIcon} alt="" />
                </div>
                <div className={`${boxTitleContainer}`}>
                  <h3>{t('weEmailTitle')}</h3>
                  <div className={`${boxTitleFirstContainer}`}>
                    <p style={{ margin: 5 }}>{`${companyEmail}`}</p>
                  </div>
                  <div className={`${boxTitleSecondContainer}`}>
                    <p style={{ margin: 5 }}>{`${companyEmailOutside}`}</p>
                  </div>
                </div>
              </div>}
              {companyDirection && <div className={`${boxContainer}`}>
                <div className={`${box}`} style={{ backgroundColor: YELLOW }}>
                  <img className={`${boxImage}`} src={directionIcon} alt="" />
                </div>
                <div className={`${boxTitleContainer}`}>
                  <h3>{t('weDirectionTitle')}</h3>
                  <div className={`${boxTitleFirstContainer}`}>
                    <p style={{ margin: 5, fontSize: 14 }}>{`${companyDirection}`}</p>
                  </div>
                  <div className={`${boxTitleSecondContainer}`}>
                    <p style={{ margin: 5, fontSize: 14 }}>{`${companyDirectionOutside}`}</p>
                  </div>
                </div>
              </div>}
              <div className={`${boxContainer}`}>
                <div className={`${box}`} style={{ backgroundColor: YELLOW_DARK }}>
                  <img className={`${boxImage}`} src={attachIcon} alt="" />
                </div>
                <div className={`${boxTitleContainer}`}>
                  <h3>{t('weAnotherTitle')}</h3>
                  <div className={`${boxTitleFirstContainer}`}>
                  <p style={{ margin: 0 }}>{t('atentionTime')}</p>
                  </div>
                  <div className={`${boxTitleSecondContainer}`}>
                  <p style={{ margin: 0 }}>{t('atentionTimeEn')}</p>
                  </div>
                </div>
              </div>
            </div>
            <ContactUsComplete />
            <HomeFooter />
          </div>}
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(We);
