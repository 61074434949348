import axios from 'axios';
import { API_URL, timeToResponse } from '../config'
import { decrypt } from "../utils/encrypt";
// import { actionCreators as actions } from '../redux/actions';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

// const validateSession = (e) => {
//   return new Promise(async (resolve, reject) => {
//     // console.log("validateSession / e: ", e);
//     const error = e && e.data && e.data.error && e.data.error.code ? e.data.error.code : '';
//     if (error === 'token-expired' || error === 'internal-error') {
//       actions.signOut();
//       resolve();
//     } else {
//       resolve();
//     }
//   })
// }

/**
 * POST fetch method
 * @param  {string}  url    Endpoint URL
 * @param  {any}  data    Endpoint data
 * @param  {Boolean} isAuth if endpoint needs token, true by default
 * @return {Promise}         the data from the endpoint
 */
const postData = (url, data, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token")
      const fullUrl = `${API_URL}${url}`;
      timeout(timeToResponse, axios.post(fullUrl, data, {
        headers: {
          'content-type': 'application/json',
          'Accept': '*/*',
          'accept-language': language,
          'access-token': isAuth ? token : ''
        }
      }))
        .then(async (response) => {
          // console.log("postData / response: ", response);
          const decryptedData = await decrypt(response.data);
          resolve(decryptedData);
        })
        .catch(error => {
          // console.log("decrypt / error: ", error.response);
          // console.log("decrypt / error: ", error);
          reject(error.response)
        })
    } catch (error) {
      // console.log("postData / error: ", error);
      reject(error);
    }
  })
}

/**
 * POST fetch method
 * @param  {string}  url    Endpoint URL
 * @param  {any}  data    Endpoint data
 * @param  {Boolean} isAuth if endpoint needs token, true by default
 * @return {Promise}         the data from the endpoint
 */
const putData = (url, data, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token")
      const fullUrl = `${API_URL}${url}`;
      timeout(timeToResponse, axios.put(fullUrl, data, {
        headers: {
          'content-type': 'application/json',
          'Accept': '*/*',
          'accept-language': language,
          'access-token': isAuth ? token : ''
        }
      }))
        .then(async (response) => {
          // console.log("postData / response: ", response);
          const decryptedData = await decrypt(response.data);
          resolve(decryptedData);
        })
        .catch(error => {
          // console.log("decrypt / error: ", error.response);
          // console.log("decrypt / error: ", error);
          reject(error.response)
        })
    } catch (error) {
      // console.log("postData / error: ", error);
      reject(error);
    }
  })
}

/**
 * GET fetch method
 * @param  {string}  url    Endpoint URL
 * @param  {any}  data    Endpoint data
 * @param  {Boolean} isAuth if endpoint needs token, true by default
 * @return {Promise}         the data from the endpoint
 */
const getData = (url, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token")
      const fullUrl = `${API_URL}${url}`;
      timeout(timeToResponse, axios.get(fullUrl, {
        headers: {
          'content-type': 'application/json',
          'Accept': '*/*',
          'accept-language': language,
          'access-token': isAuth ? token : ''
        }
      }))
        .then(async (response) => {
          const decryptedData = await decrypt(response.data);
          resolve(decryptedData);
        })
        .catch(error => {
          reject(error)
        })
    } catch (error) {
      reject(error);
    }
  })
}

const deleteData = (url, isAuth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token")
      const fullUrl = `${API_URL}${url}`;
      // console.log('services / deleteData / fullUrl:', fullUrl);
      // console.log('token:', token);
      const response = await timeout(Number(timeToResponse), axios.delete(fullUrl, {
        headers: {
          'content-type': 'application/json',
          'Accept': '*/*',
          'accept-language': language,
          'access-token': isAuth ? token : ''
        }
      }))
      const decryptData = await decrypt(response.data);
      // console.log('services / deleteData / decryptData:', decryptData);
      resolve(decryptData);
    } catch (e) {
      // await validateSession(e.response || e);
      reject(e);
    }
  })
}

/**
 * Timeout for fetch request
 * @param  {number} ms milliseconds
 * @param  {promise} promise the fetch promise
 */
const timeout = (ms, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject({
        response: {
          body: '',
          data: {
            error: {
              message: language === 'es' ? 'Comprueba tu conexión a internet y prueba otra vez.' : 'Check your internet connection and try again.'
            },
          }
        }
      });
    }, ms);
    promise.then(resolve, reject);
  });
}

export {
  postData,
  getData,
  deleteData,
  putData
}