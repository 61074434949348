import React from 'react';
import { WHITE, BLACK, YELLOW } from "../../utils/color-palatte";
import whiteLogo from "../../assets/logos/white-logo.svg";
import cartIcon from "../../assets/icons/yellow-cart.svg";
import phoneIcon from "../../assets/icons/phone-icon.svg";
import twitterIcon from "../../assets/icons/twitter-icon.svg";
import facebookIcon from "../../assets/icons/fb-icon.svg";
import menuIcon from "../../assets/icons/menu.svg";
import userIcon from "../../assets/icons/user-icon.svg";
import blackUserIcon from "../../assets/icons/profile-user-black.svg";
import favouritesIcon from "../../assets/icons/heart-fill.svg";
import "./header.css";
import { Translation } from 'react-i18next';
import { companyFacebook, companyTwitter, contactNumber, wsMe } from "../../config";
import { Link } from 'react-router-dom'
import { css } from 'emotion'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import ModalComponent from '../../utils/Modal';
import logOutIcon from "../../assets/icons/logout-black.svg";
import wsYellowIcon from "../../assets/icons/yellow-ws-icon.png";
import { validateUser } from '../../utils/validateUserType';
import ReactFlagsSelect from 'react-flags-select';

const menuIconContainer = css`
display: none;
@media(max-width: 1200px) {
  display: flex;
}
`

const titleStyles = css`
margin-left: 20px;
@media(max-width: 1200px) {
  display: none;
}
  `

const contactNumberContainer = css`
display: flex;
flex-direction: column;
margin-right: 10px;
margin-left: 10px;
@media(max-width: 1200px) {
  display: none;
}
  `

const headerFirstContainer = css`
display: flex;
justify-content: left;
align-items: center;
width: 60%;
position: absolute;
left: 0;
@media(max-width: 1200px) {
  width: 20%;
}
@media(max-width: 870px) {
  width: 30%;
}
  `

const headerSecondContainer = css`
display: flex;
justify-content: flex-end;
align-items: center;
width: 40%;
position: absolute;
right: 0;
  `

const loginRegisterButtonContainer = css`

  `

const contactIconsContainer = css`
margin-right: 10px;
display: flex;
align-items: center;
@media(max-width: 870px) {
  display: none
}
  `

const userEmailStyles = css`
margin: 0;
cursor: pointer;
@media(max-width: 870px) {
  display: none
}
  `

const cartButtonContainer = css`
position: relative;
margin-right: 10px;
margin-left: 10px;
@media(max-width: 400px) {
  margin-right: 5px;
  margin-left: 5px;
}
  `

const logoStyles = css`
width: 120px;
height: 70px;
margin-left: 20px;
margin-right: 20px;
@media(max-width: 870px) {
  width: 80px;   
  height: 50px;
}
  `

const heartIconStyles = css`
width: 40px;   
height: 40px;
cursor: pointer;
@media(max-width: 870px) {
  width: 35px;   
  height: 35px;
}
  `

const heartCounterText = css`
position: absolute;
top: -1px;
right: -2px;
font-size: 11px;
font-weight: bold;
background: ${WHITE};
padding: 4px;
margin: 0;
border-radius: 20px;
@media(max-width: 870px) {
  top: -4px;
  right: -2px;
  font-size: 10px;
}
  `

const contactTextStyles = css`
font-size: 15px;
margin: 0
`

const userIconStyles = css`
width: 20px;
height: 20px;
cursor: pointer;
@media(max-width: 870px) {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}
`

const userInfoContainer = css`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
position: relative;
@media(max-width: 870px) {
  margin-left: 20px;
}
@media(max-width: 400px) {
  margin-left: 10px;
}
`

const mobileMenuContainer = css`
display: flex;
flex-direction: column;
align-items: center;
@media(min-width: 1200px) {
  display: none;
}
  `

const mobileMenuItemContainer = css`
display: flex;
flex-direction: column;
align-items: center;
min-height: 50px;
width: 100%;
border-top: 0.5px solid ${WHITE};
  `

const userModalContainer = css`
display: flex;
flex-direction: column;
align-items: center;
min-height: 50px;
  `
const userModalIcon = css`
width: 100px;
heiht: 100px;
  `

const logOutIconStyles = css`
width: 25px;   
height: 25px;
cursor: pointer;
margin-top: 20px;
`

const userModalText = css`
margin-bottom: 0
`

const flags = css`
margin-right: 10px;
button {
  padding: 0;
}
`

class Header extends React.Component {

  state = {
    isMenuOpen: false
  }
  // constructor(props) {
  //   super(props);
  //   this.userFlag = React.createRef();
  // }

  logout = () => {
    const { signOut } = this.props;
    signOut();
    window.scrollTo(0, 0);
  };

  render() {
    const { user, cart } = this.props;
    const { isMenuOpen } = this.state;
    // console.log("user: ", user);
    // console.log("isMenuOpen: ", isMenuOpen);
    // console.log("this.userFlag: ", this.userFlag);
    // console.log("localStorage.getItem('language'): ", localStorage.getItem("language"));
    return (
      <Translation>
        {t =>
          <>
            <div className="header-container" style={{ backgroundColor: BLACK }}>
              <div className={`${headerFirstContainer}`}>
                <Link to="/home">
                  <img src={whiteLogo} className={`${logoStyles}`} alt='logo' />
                </Link>
                <Link to="/home">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("home")}</h5>
                </Link>
                <Link to="/products">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("products")}</h5>
                </Link>
                <Link to="/we">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("we")}</h5>
                </Link>
                {(user && user.email && (user.userType ? (user.userType !== "products-admin" && user.userType !== "seller") : true)) && <Link to="/orders">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("ordersButton")}</h5>
                </Link>}
                {(user && validateUser(["super-admin", "admin"], user.userType)) && <Link to="/contacts">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("contactsButton")}</h5>
                </Link>}
                {(user && validateUser(["super-admin", "admin", "seller", "employer"], user.userType)) && <Link to="/users">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("usersHeaderButton")}</h5>
                </Link>}
                {(user && validateUser(["super-admin"], user.userType)) && <Link to="/configuration">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("configurationHeaderButton")}</h5>
                </Link>}
                <Link to="/help">
                  <h5 className={`${titleStyles}`} style={{ color: WHITE }}>{t("help")}</h5>
                </Link>
                <div className={`${menuIconContainer}`}>
                  <img onClick={() => this.setState({ isMenuOpen: !isMenuOpen })} src={menuIcon} className="menu-icon" alt='logo' />
                </div>
              </div>
              <div className={`${headerSecondContainer}`}>
                {user && user.userType !== 'client' ? false : true
                  ? <>
                    <div className={`${contactIconsContainer}`}>
                      {companyFacebook && <a target="_blank" rel="noopener noreferrer" href={companyFacebook}>
                        <img src={facebookIcon} className="contact-icons" alt='facebook' />
                      </a>}
                      {companyTwitter && <a target="_blank" rel="noopener noreferrer" href={companyTwitter}>
                        <img src={twitterIcon} className="contact-icons" alt='twitter' />
                      </a>}
                      {wsMe && <a target="_blank" rel="noopener noreferrer" href={wsMe}>
                        <img style={{ marginLeft: 5, width: 50, height: 50 }} src={wsYellowIcon} className="contact-icons" alt='ws number' />
                      </a>}
                      {contactNumber && <a href={`tel:${contactNumber}`}>
                        <img style={{ marginLeft: 5 }} src={phoneIcon} className="contact-icons" alt='phone number' />
                      </a>}
                    </div>
                    {contactNumber && <div className={`${contactNumberContainer}`}>
                      <p className={`${contactTextStyles}`} style={{ color: WHITE, margin: 0 }}>{t("contactUs")}</p>
                      <p className={`${contactTextStyles}`} style={{ color: WHITE, margin: 0, marginTop: 5 }}>{contactNumber}</p>
                    </div>}
                  </>
                  : null}
                {user && <div className={`${cartButtonContainer}`}>
                  <Link to="/favourites" style={{ display: "flex" }}>
                    <img src={favouritesIcon} className={`${heartIconStyles}`} alt='favourites button' />
                    <p style={{ color: BLACK }} className={`${heartCounterText}`}>{user && user.favourites ? user.favourites.length : 0}</p>
                  </Link>
                </div>}
                {(user && user.userType === "client") && <div className={`${cartButtonContainer}`}>
                  <Link to="/cart" style={{ display: "flex" }}>
                    <img src={cartIcon} className={`${heartIconStyles}`} alt='shopping cart button' />
                    <p style={{ color: BLACK }} className={`${heartCounterText}`}>{cart && cart.length > 0 ? cart.length : 0}</p>
                  </Link>
                </div>}
                {user && user.email
                  ? <>
                    <div className={`${userInfoContainer}`}>
                      <img onClick={() => this.setState({ userInfoModal: true })} src={userIcon} alt="user icon" className={`${userIconStyles}`} />
                      <p onClick={() => this.setState({ userInfoModal: true })} className={`${userEmailStyles}`} style={{ color: WHITE, marginLeft: 5, marginRight: 10, fontSize: 10 }}>{user.email}</p>
                    </div>
                    {(user.userType && validateUser(["super-admin", "admin"], user.userType)) && <Link to="/login">
                      <div style={{ backgroundColor: WHITE, marginLeft: 10 }} className="login-register-button">
                        <p style={{ color: BLACK }} className="login-register-button-text">
                          {t("loginRegisterButton")}
                        </p>
                      </div>
                    </Link>}
                  </>
                  : <div className={`${loginRegisterButtonContainer}`}>
                    <Link to="/login">
                      <div style={{ backgroundColor: WHITE }} className="login-register-button">
                        <p style={{ color: BLACK }} className="login-register-button-text">
                          {t("loginButton")}
                        </p>
                      </div>
                    </Link>
                  </div>}
                  <ReactFlagsSelect
                  // ref={this.userFlag}
                  className={`${flags}`}
                  defaultCountry={String(localStorage.getItem("language") || "ES").toUpperCase()}
                  countries={["US", "ES"]}
                  showSelectedLabel={false}
                  showOptionLabel={false}
                  onSelect={(selected) => {
                    localStorage.setItem("language", String(selected).toLowerCase())
                    window.location.reload(false);
                  }}
                />
              </div>
            </div>
            {isMenuOpen
              ? <div className={`${mobileMenuContainer}`} style={{ backgroundColor: BLACK }}>
                <div className={`${mobileMenuItemContainer}`}>
                  <Link to="/home">
                    <h5 className={``} style={{ color: window.location.pathname !== '/home' ? WHITE : YELLOW }}>{t("home")}</h5>
                  </Link>
                </div>
                <div className={`${mobileMenuItemContainer}`}>
                  <Link to="/products">
                    <h5 className={``} style={{ color: window.location.pathname !== '/products' ? WHITE : YELLOW }}>{t("products")}</h5>
                  </Link>
                </div>
                <div className={`${mobileMenuItemContainer}`} style={{ borderBottom: `0.5px solid ${WHITE}` }}>
                  <Link to="/we">
                    <h5 className={``} style={{ color: window.location.pathname !== '/we' ? WHITE : YELLOW }}>{t("we")}</h5>
                  </Link>
                </div>
                {(user && user.email && (user.userType ? (user.userType !== "products-admin" && user.userType !== "seller") : true)) && <div className={`${mobileMenuItemContainer}`} style={{ borderBottom: `0.5px solid ${WHITE}` }}>
                  <Link to="/orders">
                    <h5 className={``} style={{ color: window.location.pathname !== '/orders' ? WHITE : YELLOW }}>{t("ordersButton")}</h5>
                  </Link>
                </div>}
                {(user && validateUser(["super-admin", "admin"], user.userType)) && <div className={`${mobileMenuItemContainer}`} style={{ borderBottom: `0.5px solid ${WHITE}` }}>
                  <Link to="/contacts">
                    <h5 className={``} style={{ color: window.location.pathname !== '/contacts' ? WHITE : YELLOW }}>{t("contactsButton")}</h5>
                  </Link>
                </div>}
                {(user && validateUser(["super-admin", "admin", "seller", "employer"], user.userType)) && <div className={`${mobileMenuItemContainer}`} style={{ borderBottom: `0.5px solid ${WHITE}` }}>
                  <Link to="/users">
                    <h5 className={``} style={{ color: window.location.pathname !== '/users' ? WHITE : YELLOW }}>{t("usersHeaderButton")}</h5>
                  </Link>
                </div>}
                {(user && validateUser(["super-admin"], user.userType)) && <div className={`${mobileMenuItemContainer}`} style={{ borderBottom: `0.5px solid ${WHITE}` }}>
                  <Link to="/configuration">
                    <h5 className={``} style={{ color: window.location.pathname !== '/configuration' ? WHITE : YELLOW }}>{t("configurationHeaderButton")}</h5>
                  </Link>
                </div>}
                <div className={`${mobileMenuItemContainer}`} style={{ borderBottom: `0.5px solid ${WHITE}` }}>
                  <Link to="/help">
                    <h5 className={``} style={{ color: window.location.pathname !== '/help' ? WHITE : YELLOW }}>{t("help")}</h5>
                  </Link>
                </div>
              </div>
              : null}
            {user
              ? <ModalComponent
                isOpen={this.state.userInfoModal}
                onRequestClose={() => this.setState({ userInfoModal: false })}
              >
                <div className={`${userModalContainer}`}>
                  <img src={blackUserIcon} alt="user icon" className={`${userModalIcon}`} />
                  {user.userType !== "client" && <h5 className={`${userModalText}`} style={{ color: BLACK }}>{t(`${user.userType}`)}</h5>}
                  <h5 className={`${userModalText}`} style={{ color: BLACK }}>{user.email}</h5>
                  {user.name && <h5 className={`${userModalText}`} style={{ color: BLACK }}>{user.name}</h5>}
                  {user.phone && <h5 className={`${userModalText}`} style={{ color: BLACK }}>{user.phone}</h5>}
                  <img alt="log out button" onClick={() => this.logout()} src={logOutIcon} className={`${logOutIconStyles}`} />
                </div>
              </ModalComponent>
              : null}
          </>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
    cart
  } = state;
  return {
    user,
    cart
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
