import React from 'react';
import SafeURL from '../../utils/safe-url';
import Neurons from '../../assets/partner/Neurons.svg';
import NeuronsHover from '../../assets/partner/NeuronsHover.svg';
import { Translation } from 'react-i18next';

class PartnerBanner extends React.Component {
  state = {
    hover: false,
  };

  onHover = () => {
    this.setState({ hover: true });
  };

  onLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div
            className='PartnerBanner__Container'
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '30px',
              padding: '10px',
              boxSizing: 'border-box',
            }}
          >
            <p
              style={{
                margin: '0',
                color: 'white',
                marginRight: '10px',
                fontSize: '10px',
                fontFamily: `'Barlow', sans-serif`,
                textTransform: 'uppercase'
              }}
            >
              {t('madeBy')}
            </p>
            <SafeURL
              dangerousURL='https://neurons.agency'
              style={{
                width: '50px',
                height: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0',
                padding: '0',
              }}
              onMouseEnter={() => this.onHover()}
              onMouseLeave={() => this.onLeave()}
              target='_blank'
              rel='noopener noreferrer'
              children={
                <img
                  src={this.state.hover ? NeuronsHover : Neurons}
                  alt='neurons'
                  style={{ width: 'inherit', margin: '0' }}
                />
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default PartnerBanner;
