import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import HomeFooter from '../home-footer/HomeFooter';
import { css } from 'emotion';
import { FULL_WHITE, WHITE, YELLOW } from '../../utils/color-palatte';
import bgImage from "../../assets/dashboard/background-image.png";
import ContactUs from '../contact-us/ContactUs';
import ReactPlayer from 'react-player'
import { videoId } from "../../config";

const weContainer = css`
text-align: center;
min-height: 400px;
width: 100%;
position: relative;
padding-bottom: 30px;
`

const title = css`
margin: 0;
font-size: 50px
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}
`

const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
position: relative;
display: flex;
`

const videoContainer = css`
padding-top: 40px;
padding-bottom: 40px;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
background-color: ${FULL_WHITE}
`

const video = css`
width: 500px;
height: 350px;
@media(max-width: 520px) {
  width: 100%;
}
`

class Help extends React.Component {

  state = {
  };

  componentDidMount = () => {
    window.scrollTo(0, 0)
  };

  render() {
    // const { user } = this.props;
    // console.log("user: ", user);
    return (
      <Translation>
        {t =>
          <div className={`${weContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("helpTitle1")}</h1>
                </span>
                <span style={{ marginLeft: 15 }}>
                  <h1 className={`${title}`} style={{ color: YELLOW }}>{t("helpTitle2")}</h1>
                </span>
              </span>
            </div>
            <div className={`${videoContainer}`}>
              <h1 style={{ fontWeight: 'bold' }}>{t('helpTitle')}</h1>
              <p style={{ fontSize: 20, margin: 0, marginBottom: 30 }}>{t('helpTitleContent')}</p>
              <div className={`${video}`}>
                <ReactPlayer
                  width='100%'
                  height='100%'
                  url={`https://www.youtube.com/watch?v=${videoId}`}
                />
              </div>
              <p style={{ maxWidth: 500, fontSize: 20 }}>{t('helpVideoContent')}</p>
            </div>
            <ContactUs title={t('helpContactTitle')} content={t('helpContactContent')} />
            <HomeFooter />
          </div>}
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Help);
