import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import { css } from 'emotion';
import HomeFooter from '../home-footer/HomeFooter';
import { BLACK, BLACK_OPACITY, BLACK_OPACITY2, FULL_WHITE, WHITE, YELLOW } from '../../utils/color-palatte';
import searchIcon from "../../assets/icons/search-icon.svg";
import bgImage from "../../assets/products/our-products-banner.png";
import addIcon from "../../assets/icons/add.svg";
import pictureIcon from "../../assets/icons/picture-icon.svg";
import Input from "../../utils/Input";
import motorsBrands from "../../motor-brands.json";
import Button from "../../utils/Button";
import ContactUs from '../contact-us/ContactUs';
import { deleteData, getData, postData } from '../../fetch/services';
import Loader from '../../utils/Loader';
import Alert from '../../utils/Alert';
import headerImage from "../../assets/products/our-products-piece.png";
import { Link } from 'react-router-dom'
import updateUser from '../../utils/updateUser';
import deleteFiles from '../../utils/deleteFiles';
import ProductDetailModal from '../../utils/ProductDetailsModal';
import cartIcon from "../../assets/icons/yellow-cart.svg";
import editIcon from "../../assets/icons/edit.svg";
import removeRedIcon from "../../assets/icons/remove-red-icon.svg";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import thousandSeparator from '../../utils/thousandSeparator';
import range from '../../utils/range';
import { quantitiesMax } from '../../config';
import { validateUser } from '../../utils/validateUserType';
import generatePDF from '../../utils/generatePdf';
import { emailsRegex } from '../../utils/regex';
import ProductDescription from '../../utils/ProductDescription';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const productsSectionContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
  `

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-bottom: 30px
`

const searchInputContainer = css`
max-width: 380px;
min-width: 300px
`

const link = css`
display: flex;
flex-direction: center;
align-items: center;
`

const filtersContainer = css`
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: row;
border: ${BLACK_OPACITY} solid 1px;
border-radius: 3px;
margin: 10px;
width: 95%;
flex-wrap: wrap;
max-width: 1000px;
  `

const searchInput = css`
margin: 10px;
  `

const selectStyles = css`
margin: 10px;
  `

const inputPictureStyle = css`
margin-right: 10px;
  `

const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
display: flex;
`

const title = css`
margin: 0;
font-size: 50px
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}

`

const inputContainer = css`
display: flex;
flex-direction: row;
@media(max-width: 650px) {
  flex-wrap: wrap;
}
`

const seeMoreButton = css`
color: ${WHITE};
margin: 0px;
padding: 8px;
font-size: 20px;
padding-left: 20px;
padding-right: 20px;
font-weight: bold;
`

const loader = css`
margin-top: 50px;
`

const headerImageStyle = css`
width: 300px;
height: 200px;
position: absolute;
right: 10%;
@media(max-width: 1050px) {
  width: 150px;
  height: 150px;
  right: 20px;
}
@media(max-width: 925px) {
  display: none
}
`

const buttonBackground = css`
background-color: ${BLACK};
padding: 5px;
`

const orderButton = css`
margin: 5px;
cursor: pointer
  `

const orderButtonsContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
`

const buttonText = css`
color: ${WHITE};
font-size: 12px !important;
`

const buttonContainer = css`
display: flex;
flex-direction: row;
`

const removeRedIconStyles = css`
width: 30px;
height: 30px;
margin: 5px;
cursor: pointer;
`

const editIconStyles = css`
width: 30px;
height: 30px;
margin: 5px;
cursor: pointer;
border-radius: 5px;
background-color: ${WHITE}
`

const trStyles = css`
@media(max-width: 40em) {
  border: 1px solid #000 !important;
}
`

const productName = css`
@media (max-width: 40em){
margin-left: -40% !important;
}
`

const previewImage = css`
width: 40px;
height: 40px;
`

const thText = css`
color: ${WHITE};
font-weight: bold;
margin: 10px;
@media (max-width: 40em){
  color: ${BLACK};
}
`

const tableContainer = css`
margin: 10px;
@media (max-width: 40em){
  width: 300px;
}
`

const cartInfoContainer = css`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
padding: 10px;
max-width: 400px;
margin: 10px;
margin-top: 20px;
position: relative;
`

// const inputStyles = css`
// width: 100%;
// background-color: ${FULL_WHITE} !important
// `

class Products extends React.Component {

  state = {
    products: [],
    fetching: false,
    skipItems: 0,
    totalAmount: 0,
    limitItems: 10,
    plusProductsNumber: 10,
    limitOnSearch: 30,
    isModalOpen: false,
    keepIncreasingCounter: false,
    search: "",
    byBrand: "",
    bySerie: "",
    bsValue: "",
    currency: "",
    thirdPerson: "",
    orderLoading: false
  };

  componentDidMount = async () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const search = url.searchParams.get("search");
    await this.updateTotalAmount();
    if (search) {
      this.setState({ search }, () => {
        window.scrollTo(0, 0);
        this.fetchProducts();
      })
    } else {
      this.fetchProducts();
    }

    window.scrollTo(0, 0)
  };

  updateTotalAmount = async () => {
    await this.fetchConfig()
    let counter = 0
    if (this.props.cart && this.props.cart.length > 0) {
      this.props.cart.forEach(product => {
        counter = counter + (product.quantitySelected ? Number(product.quantitySelected) : 1) * product.price
      })
    }

    this.setState({ totalAmount: counter })
  }

  fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getData(`/config/get-config`);
        // console.log("fetchConfig / response: ", response);
        const config = response.body

        this.setState({
          bsValue: config.bsValue,
          currency: config.currency,
        });
        resolve();
      } catch (e) {
        resolve(e);
        // console.log("fetchConfig / error: ", e)
        this.setState({ loading: false })
        const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
          ? e.response.data.error.message
          : null;
        const error = e && e.data && e.data.error ? e.data.error : null;
        const errorMessage = error && error.message && error.message[0] && error.message[0].msg
          ? error.message[0].msg
          : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
        this.showAlert(true, networkError || errorMessage, false)
      }
    })
  }

  onChange = async ({ target: { name, value } }) => {
    if (name === "byBrand") {
      this.setState({ bySerie: "" })
    }

    this.setState({ [name]: value, products: [], search: "" }, () => {
      this.fetchProducts();
    })
  }

  onChangeQuantity = async ({ target: { name, value } }, productSelected) => {
    const { editProduct } = this.props;
    let productCopy = productSelected;
    let productsCopy = this.state.products.map(product => {
      if (product._id === productSelected._id) {
        return {
          ...product,
          [name]: value,
        }
      } else {
        return product
      }
    });
    editProduct(productSelected._id, { ...productCopy, quantitySelected: value })
    this.setState({ products: productsCopy }, () => {
      setTimeout(() => {
        this.updateTotalAmount()
      }, 500)
    })
  }

  fetchProducts = () => {
    return new Promise(async (resolve, reject) => {
      const {
        skipItems,
        limitItems,
        fetching,
        search,
        byBrand,
        bySerie,
        products,
        limitOnSearch
      } = this.state;
      const { user } = this.props;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            if (user) {
              await this.verifySession()
            }

            await this.fetchConfig()
            const url = `/product/get-products/?skipItems=${skipItems}&limitItems=${skipItems === 0 && search ? limitOnSearch : limitItems}&search=${search}&brand=${byBrand}&serie=${bySerie}`
            // console.log("url: ", url)
            const response = await getData(url);
            // console.log("getProducts / response: ", response);
            const newProducts = response.body;
            // console.log("getProducts / newProducts: ", newProducts);
            const allProducts = products
              .concat(newProducts)

            const test = search ? allProducts.filter((item) =>
              search.toLowerCase() === item.name.split(' ')[0].toLowerCase() ||
              search.toLowerCase() === item.name.toLowerCase() ||
              search.toLowerCase() === item.description.toLowerCase()
            ) : []

            const finalProducts = test.concat(allProducts)
              .filter((item, index, self) =>
                index === self.findIndex((t) => (
                  t._id === item._id
                ))
              )


            // .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
            // console.log("getProducts / finalProducts: ", finalProducts);
            // console.log("getProducts / test: ", test);
            this.setState({
              fetching: false,
              products: this.updateQuantities(finalProducts),
              keepIncreasingCounter: newProducts.length > 0
            });
            resolve();
          } catch (e) {
            resolve(e);
            // console.log("fetchPosts / error: ", e)
            this.setState({ fetching: false })
            if (e !== "session-expired") {
              const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
                ? e.response.data.error.message
                : null;
              const error = e && e.data && e.data.error ? e.data.error : null;
              const errorMessage = error && error.message && error.message[0] && error.message[0].msg
                ? error.message[0].msg
                : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
              this.showAlert(true, networkError || errorMessage, false)
            }
          }
        })
      }
    })
  }

  updateQuantities = (products) => {
    const productsCopy = products.map(product => {
      if (this.props.cart && this.props.cart.length > 0 && this.props.cart.filter(cartProduct => cartProduct._id === product._id).length > 0) {
        const cartProduct = this.props.cart.filter(cartProduct => cartProduct._id === product._id)[0]
        return {
          ...product,
          quantitySelected: cartProduct.quantitySelected
        }
      } else {
        return product;
      }
    })
    return productsCopy
  }

  removeProduct = (product) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await this.verifySession()
        if (product.files && product.files.length > 0) {
          await deleteFiles(product.files)
        }

        await deleteData(`/product/remove-product/?productId=${product._id}&userId=${user._id}`);
        this.onRefreshProducts();
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  addToFavourites = (productId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        this.verifySession()
        await postData(`/product/add-favourite/?productId=${productId}&userId=${user._id}`);
        updateUser(this.props.saveUser)
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  removeFromFavourites = (productId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        this.verifySession()
        await postData(`/product/remove-favourite/?productId=${productId}&userId=${user._id}`);
        updateUser(this.props.saveUser)
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  onRefreshProducts = () => {
    // const { saveUserPosts } = this.props;
    const { plusProductsNumber, search, limitOnSearch } = this.state;
    // saveUserPosts([]);
    this.setState({
      skipItems: 0,
      limitItems: search ? limitOnSearch : plusProductsNumber,
      products: [],
      byBrand: "",
      search: "",
      bySerie: "",
    }, () => {
      this.fetchProducts();
    })
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  onEndReached = () => {
    // console.log("onEndReached");
    const {
      skipItems,
      limitItems,
      plusProductsNumber,
      keepIncreasingCounter,
      search,
      limitOnSearch
    } = this.state;
    if (keepIncreasingCounter) {
      this.setState({
        skipItems: skipItems + (search ? limitOnSearch : plusProductsNumber),
        limitItems: limitItems + (search ? limitOnSearch : plusProductsNumber)
      }, () => {
        this.fetchProducts();
      });
    } else {
      this.fetchProducts();
    }
  };

  handleKeyPress = e => {
    const { search, limitItems, limitOnSearch } = this.state;
    // console.log("handleKeyPress / e.key: ", e.key)
    // console.log("handleKeyPress / e.charCode: ", e.charCode)
    // console.log("handleKeyPress / e.keyCode: ", e.keyCode)
    if (e.key === 'Enter' || e.charCode === 13 || e.keyCode === 13) {
      e.preventDefault()
      this.setState({
        products: [],
        skipItems: 0,
        limitItems: search ? limitOnSearch : limitItems,
      }, () => {
        this.fetchProducts();
      })
    }
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  deliverOrder = (t) => {
    const { cart, user } = this.props;
    const { bsValue, currency } = this.state;
    // console.log("cart: ", cart);
    const totalAmount = `${thousandSeparator(currency === "bs" ? this.state.totalAmount * Number(bsValue) : this.state.totalAmount)}${currency === "bs" ? "Bs" : "$"}`
    if (this.state.thirdPerson && !this.state.thirdPerson.match(emailsRegex)) {
      this.setState({ thirdPersonError: true })
      this.showAlert(true, t('invalidThirdPersonEmail'), false)
      return false;
    }

    this.setState({ orderLoading: true }, async () => {
      try {
        const data = {
          user: this.props.user._id,
          products: this.props.cart,
          amount: this.state.totalAmount,
          thirdPerson: this.state.thirdPerson
        }
        const orderCreated = await postData('/order/create-order', data);
        // console.log("deliverOrder / orderCreated: ", orderCreated);
        this.showAlert(true, t('order-delivered'), true)
        this.setState({
          orderLoading: false,
        })
        generatePDF(cart, totalAmount, t, currency, bsValue, orderCreated.body, this.state.thirdPerson ? user : null)
        this.props.clearCart();
      } catch (e) {
        // console.log("deliverOrder / error: ", e);
        this.setState({ orderLoading: false })
        const error = e && e.data && e.data.error ? e.data.error : null;
        const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
          ? e.response.data.error.message
          : null;
        // console.log("networkError: ", networkError);
        const invalidThirdPersonEmail = error && error.code === "invalid-thirdperson-email" ? error.message : null;
        const errorMessage = error && error.message && error.message[0] && error.message[0].msg
          ? error.message[0].msg
          : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
        // console.log("errorMessage: ", errorMessage);
        this.showAlert(true, invalidThirdPersonEmail || networkError || errorMessage, false)
      }
    })
  };

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { products, bsValue, currency, limitOnSearch, limitItems, search } = this.state;
    const { user, cart, addProductToCart, removeProductFromCart } = this.props;
    const totalAmount = `${thousandSeparator(currency === "bs" ? this.state.totalAmount.toFixed(2) * Number(bsValue) : this.state.totalAmount.toFixed(2))}${currency === "bs" ? "Bs" : "$"}`
    // console.log("products: ", products);
    // console.log("cart: ", cart);
    // console.log("totalAmount: ", totalAmount);
    // console.log("this.state.totalAmount: ", this.state.totalAmount);
    return (
      <Translation>
        {t =>
          <div className={`${productsSectionContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("productsSectionOurText")}</h1>
                </span>
                <span style={{ marginLeft: 15 }}>
                  <h1 className={`${title}`} style={{ color: YELLOW }}>{t("productsSectionProductsText")}</h1>
                </span>
                <img className={`${headerImageStyle}`} src={headerImage} alt="" />
              </span>
            </div>
            <div className={`${container}`} style={{ backgroundColor: FULL_WHITE }}>
              {cart && cart.length > 0
                ? <div className={`${cartInfoContainer}`} style={{ backgroundColor: WHITE }}>
                  <h3 style={{ color: BLACK_OPACITY2, margin: 0 }}>{t("cartTotal")}</h3>
                  <p style={{ color: BLACK, fontWeight: 'bold', margin: 0, marginTop: 20 }}>{totalAmount}</p>
                  {/* {validateUser(["super-admin", "admin", "seller", "employer"], user.userType) && <Input
                    type="text"
                    label={t('OrdderToThirdPerson')}
                    placeholder={t('thirdPerson')}
                    value={this.state.thirdPerson}
                    onChange={this.handleInputChange}
                    name='thirdPerson'
                    className={`${inputStyles}`}
                    showErrors={this.state.thirdPersonError}
                    disabled={this.state.orderLoading}
                  />} */}
                  <Link to="cart">
                    <Button
                      loading={this.state.orderLoading}
                      // onClick={() => this.deliverOrder(t)}
                      disabled={this.state.orderLoading}
                      content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30 }}>{t("completeBuy")}</p>}
                      style={{ backgroundColor: BLACK, marginTop: 20, minWidth: '100%', minHeight: 38 }}
                      loaderColor={FULL_WHITE}
                    />
                  </Link>
                </div>
                : null}
              <div className={`${filtersContainer}`}>
                <div className={`${inputContainer}`}>
                  <Input
                    type="text"
                    className={`${searchInput}`}
                    containerStyle={`${searchInputContainer}`}
                    pictureStyle={`${inputPictureStyle}`}
                    placeholder={t('searchProductsInputPlaceholder')}
                    value={search}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleInputChange}
                    name='search'
                    rightPicture={<img
                      onClick={() => {
                        this.setState({
                          products: [],
                          skipItems: 0,
                          limitItems: search ? limitOnSearch : limitItems,
                        }, () => {
                          this.fetchProducts();
                        })
                      }}
                      src={searchIcon}
                      style={{
                        width: 37,
                        height: 20,
                        cursor: 'pointer',
                        backgroundColor: BLACK,
                        padding: 6,
                        marginRight: 10,
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                      alt="search-button"
                    />}
                  />
                  <Button
                    onClick={() => this.onRefreshProducts()}
                    content={<p style={{ color: WHITE, margin: 0, fontSize: 15, fontWeight: 'bold' }}>{t("seeAllProductsProducts")}</p>}
                    style={{ backgroundColor: BLACK, height: 30, minWidth: 90, margin: 10, minHeight: 38 }}
                  />
                  {(user && (user.userType === "super-admin" || "admin" || "products-admin")) && <Link className={`${link}`} to="/create-product">
                    <img alt="" src={addIcon} style={{ width: 30, height: 30 }} />
                  </Link>}
                </div>
                <div>
                  <select
                    value={this.state.byBrand}
                    className={`${selectStyles}`}
                    name="byBrand"
                    onChange={this.onChange}
                  >
                    <option value="">{t('filterByBrand')}</option>
                    {motorsBrands.BRANDS.map((brand, i) =>
                      <option key={i} value={brand}>{brand}</option>
                    )}
                  </select>
                  {this.state.byBrand
                    ? <select
                      value={this.state.bySerie}
                      className={`${selectStyles}`}
                      name="bySerie"
                      onChange={this.onChange}
                    >
                      <option value="">{t('filterBySerie')}</option>
                      {motorsBrands[this.state.byBrand].map(brand =>
                        <option value={brand}>{brand}</option>
                      )}
                    </select>
                    : null}
                </div>
              </div>
              <div className={`${tableContainer}`}>
                {products && products.length > 0
                  ? <Table style={{ borderSpacing: 0 }}>
                    <Thead style={{ backgroundColor: BLACK, pading: 10 }}>
                      <Tr>
                        <Th><p className={`${thText}`}>{t('tableProductPartNumber')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductName')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductBrand')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductPicture')}</p></Th>
                        {user && <Th><p className={`${thText}`}>{t('tableProductPrice')}</p></Th>}
                        {user && <Th><p className={`${thText}`}>{t('tableProductQuantity')}</p></Th>}
                        {user && <Th><p className={`${thText}`}>{t('tableProductTotal')}</p></Th>}
                        {user && <Th></Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {products.map(product => {
                        const isAdded = cart && cart.length > 0 && cart.filter(item => item._id === product._id).length > 0
                        const productPriceTotal = (product.price * (product.quantitySelected ? Number(product.quantitySelected) : 1)).toFixed(2)
                        // console.log("product: ", product)
                        // console.log("productPriceTotal: ", productPriceTotal)
                        return (
                          <Tr className={`${trStyles}`} key={product._id}>
                            <Td>
                              <p style={{ color: BLACK, margin: 10 }}>
                                {product.partNumber}
                              </p>
                            </Td>
                            <Td>
                              <ProductDescription
                                onClick={() => user ? this.setState({ selectedProduct: product, isModalOpen: true }) : null}
                                style={{ color: BLACK, margin: 10, cursor: user ? 'pointer' : '' }}
                                description={product.name}
                              />
                            </Td>
                            <Td>
                              <p
                                style={{ color: BLACK, margin: 10, cursor: user ? 'pointer' : '' }}
                              >
                                {product.motorBrand}
                              </p>
                            </Td>
                            <Td>
                              <img
                                alt=""
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({ selectedProduct: product, isModalOpen: true })}
                                src={pictureIcon}
                                className={`${previewImage}`}
                              />
                            </Td>
                            {user && <Td>
                              <p
                                style={{ color: BLACK, margin: 10 }}
                              >
                                {`${thousandSeparator(currency === "bs" ? product.price * Number(bsValue) : product.price)}${currency === "bs" ? "Bs" : "$"}`}
                              </p>
                            </Td>}
                            {user && <Td>
                              <select
                                value={product.quantitySelected}
                                className={`${selectStyles}`}
                                name="quantitySelected"
                                onChange={async (e) => {
                                  this.onChangeQuantity(e, product)
                                  // removeProductFromCart(product)
                                  await this.updateTotalAmount();
                                }}
                              >
                                {range(quantitiesMax).map((quantity, i) =>
                                  <option value={Number(quantity) + 1} key={i}>{Number(quantity) + 1}</option>
                                )}
                              </select>
                            </Td>}
                            {user && <Td>
                              <p
                                style={{ color: BLACK, margin: 10 }}
                              >
                                {`${thousandSeparator(currency === "bs" ? productPriceTotal * Number(bsValue) : productPriceTotal)}${currency === "bs" ? "Bs" : "$"}`}
                              </p>
                            </Td>}
                            {user && <Td>
                              <div className={`${orderButtonsContainer} ${productName}`}>
                                {(user && validateUser(['super-admin', 'admin', 'products-admin'], user.userType)) && <div className={`${buttonContainer}`}>
                                  <img
                                    onClick={() => {
                                      if (window.confirm(`${t('sureToRemoveProduct')} ${product.name}?`)) {
                                        if (user && (user.userType === "super-admin" || "admin" || "products-admin")) {
                                          this.removeProduct(product)
                                        } else {
                                          this.showAlert(true, t('internalError'), false)
                                        }
                                      } else {
                                      }
                                    }}
                                    src={removeRedIcon}
                                    className={`${removeRedIconStyles}`}
                                    alt=""
                                  />
                                  <Link to={`/edit-product/?product=${product._id}`}>
                                    <img src={editIcon} className={`${editIconStyles}`} alt="" />
                                  </Link>
                                </div>}
                                <div className={`${orderButton}`}>
                                  <div
                                    onClick={async () => {
                                      if (isAdded) {
                                        this.verifySession()
                                        removeProductFromCart(product)
                                        await this.updateTotalAmount();
                                      } else {
                                        this.verifySession()
                                        addProductToCart(product)
                                        await this.updateTotalAmount();
                                      }
                                    }}
                                    style={{ border: `${WHITE} 1px solid` }}
                                    className={`product-button-container ${isAdded ? buttonBackground : ""}`}
                                  >
                                    {isAdded && <img src={cartIcon} className="cart-icon-styles" alt="" />}
                                    <p className={`product-button-text ${isAdded ? buttonText : ""}`}>{!isAdded ? t("addToCart") : t("removeFromCart")}</p>
                                  </div>
                                </div>
                              </div>
                            </Td>}
                          </Tr>
                        )
                      }
                      )}
                    </Tbody>
                  </Table>
                  : this.state.fetching
                    ? <Loader color={BLACK} className={`${loader}`} />
                    : (this.state.search || this.state.byBrand || this.state.bySerie)
                      ? <h3 style={{ color: BLACK, margin: 20, marginTop: 50 }}>{t('noResulst')}</h3>
                      : null}
              </div>
              {products && products.length > 0
                ? this.state.keepIncreasingCounter && !this.state.fetching
                  ? <Button
                    onClick={() => this.onEndReached()}
                    content={<p className={`${seeMoreButton}`}>
                      {t("seeMoreProducts")}
                    </p>}
                    style={{ backgroundColor: BLACK, marginTop: 20, minHeight: 38 }}
                  />
                  : !this.state.fetching
                    ? products && products.length > 0
                      ? <h5 style={{ color: BLACK, margin: 20, marginTop: 50 }}>{t('noMoreProducts')}</h5>
                      : null
                    : <Loader color={BLACK} className={`${loader}`} />
                : null}
            </div>
            <ContactUs to={'/home'} />
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
            {this.state.selectedProduct
              ? <ProductDetailModal
                bsValue={bsValue}
                currency={currency}
                isOpen={this.state.isModalOpen}
                addToFavourites={this.addToFavourites}
                removeFromFavourites={this.removeFromFavourites}
                removeProduct={this.removeProduct}
                user={user}
                onRequestClose={() => this.setState({ isModalOpen: false })}
                selectedProduct={this.state.selectedProduct}
              />
              : null}
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
    addProductToCart: bindActionCreators(actions.addProductToCart, dispatch),
    removeProductFromCart: bindActionCreators(actions.removeProductFromCart, dispatch),
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    editProduct: bindActionCreators(actions.editProduct, dispatch),
    clearCart: bindActionCreators(actions.clearCart, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
    cart
  } = state;
  return {
    user,
    cart
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
