import React from 'react'
import { css } from 'emotion'
import { BLACK, BLACK_OPACITY } from './color-palatte'
import Modal from 'react-modal';
import closeIcon from "../assets/icons/remove-icon.svg";
import warningIcon from "../assets/icons/warning.svg";
import sucessIcon from "../assets/icons/check.svg";

const Alert = (props) => {
  const closeiconStyles = css`
  width: 25px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
  const successIconStyles = css`
  width: 60px;
  height: 60px;
  margin: 20px;
`
  const alertContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
  const customStyles = {
    overlay: {
      backgroundColor: BLACK_OPACITY,
      zIndex: 200
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };
  return (
    <Modal
      {...props}
      style={customStyles}
    >
      <div className={`${alertContainer}`}>
        <img className={`${closeiconStyles}`} src={closeIcon} onClick={() => props.onRequestClose()} alt="" />
        <img className={`${successIconStyles}`} src={props.success ? sucessIcon : warningIcon} alt="" />
        <h5 style={{ color: BLACK, marginLeft: 10, marginRight: 25, maxWidth: 200 }}>{props.content}</h5>
      </div>
    </Modal>
  )
}

export default Alert;