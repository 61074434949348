import React from 'react'
import { BLACK, RED } from './color-palatte'
import { Translation } from 'react-i18next';

const Select = (props) => {

  const {
    children,
    showErrors,
    label,
    labelStyle,
    isRequired,
    optional
  } = props;
  return (
    <Translation>
      {t => <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        {label && <span style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <p className={`${labelStyle}`} style={{ margin: 0, marginRight: 5, color: BLACK, fontWeight: 'bold' }}>{label}</p>
            {isRequired && !optional ? <span style={{ color: RED }}>*</span> : null}
            {!isRequired && optional ? <p style={{ color: BLACK }}>{`(${t('optional')})`}</p> : null}
          </span>}
        <select {...props} style={showErrors && !props.value ? { border: `1px solid ${RED}` } : {}}>
          {children}
        </select>
        </div>}
    </Translation>
  )
}

export default Select;

