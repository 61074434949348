import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import { css } from 'emotion';
import HomeFooter from '../home-footer/HomeFooter';
import { BLACK, FULL_WHITE, WHITE } from '../../utils/color-palatte';
import ContactUs from '../contact-us/ContactUs';
import { postData } from '../../fetch/services';
import Loader from '../../utils/Loader';
import Alert from '../../utils/Alert';
import updateUser from '../../utils/updateUser';
import cartIcon from "../../assets/icons/yellow-cart.svg";
import pictureIcon from "../../assets/icons/picture-icon.svg";
import editIcon from "../../assets/icons/edit.svg";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Link } from 'react-router-dom';
import ProductDetailModal from '../../utils/ProductDetailsModal';
import thousandSeparator from '../../utils/thousandSeparator';
import range from '../../utils/range';
import { quantitiesMax } from '../../config';
import { validateUser } from '../../utils/validateUserType';
import ProductDescription from '../../utils/ProductDescription';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const productsSectionContainer = css`
text-align: center;
position: relative;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
min-height: 400px;
`

const title = css`
margin: 0;
`

const titleContainer = css`
margin-top: 30px;
`

const loader = css`
margin-top: 50px;
margin-bottom: 50px;
`

const tableContainer = css`
margin: 10px;
@media (max-width: 40em){
  width: 300px;
}
`

const previewImage = css`
width: 40px;
height: 40px;
cursor: pointer;
`

const thText = css`
color: ${WHITE};
font-weight: bold;
margin: 10px;
@media (max-width: 40em){
  color: ${BLACK};
}
`

const trStyles = css`
@media(max-width: 40em) {
  border: 1px solid #000 !important;
}
`

const buttonBackground = css`
background-color: ${BLACK};
padding: 5px;
`

const orderButton = css`
margin: 5px;
cursor: pointer
  `

const orderButtonsContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
`

const buttonText = css`
color: ${WHITE};
font-size: 12px !important;
`

const buttonContainer = css`
display: flex;
flex-direction: row;
`

const editIconStyles = css`
width: 30px;
height: 30px;
margin: 5px;
cursor: pointer;
border-radius: 5px;
background-color: ${WHITE}
`

const productName = css`
@media (max-width: 40em){
margin-left: -40% !important;
}
`

const selectStyles = css`
margin: 10px;
  `

class Favourites extends React.Component {

  state = {
    favourites: [],
    fetching: false,
  };

  componentDidMount = () => {

    this.fetchFavourites();

    window.scrollTo(0, 0)
  };

  fetchFavourites = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      const { user } = this.props;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const data = {
              userId: user._id,
              productIds: user.favourites || []
            }
            const response = await postData(`/product/get-favourites`, data);
            // console.log("fetchFavourites / response: ", response);
            const favourites = response.body;
            this.setState({
              fetching: false,
              favourites: this.updateQuantities(favourites),
            });
            resolve();
          } catch (e) {
            resolve(e);
            // console.log("fetchPosts / error: ", e)
            this.setState({ fetching: false })
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        })
      }
    })
  }

  addToFavourites = (productId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/product/add-favourite/?productId=${productId}&userId=${user._id}`);
        await updateUser(this.props.saveUser)
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  removeFromFavourites = (productId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/product/remove-favourite/?productId=${productId}&userId=${user._id}`);
        await updateUser(this.props.saveUser);
        this.fetchFavourites();
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  onRefreshProducts = () => {
    // const { saveUserPosts } = this.props;
    const { plusProductsNumber } = this.state;
    // saveUserPosts([]);
    this.setState({ skipItems: 0, limitItems: plusProductsNumber, products: [] }, () => {
      this.fetchFavourites();
    })
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  updateQuantities = (products) => {
    const productsCopy = products.map(product => {
      if (this.props.cart && this.props.cart.length > 0 && this.props.cart.filter(cartProduct => cartProduct._id === product._id).length > 0) {
        const cartProduct = this.props.cart.filter(cartProduct => cartProduct._id === product._id)[0]
        return {
          ...product,
          quantitySelected: cartProduct.quantitySelected
        }
      } else {
        return product;
      }
    })
    return productsCopy
  }

  onChangeQuantity = async ({ target: { name, value } }, productId) => {
    let favouritesCopy = this.state.favourites.map(product => {
      if (product._id === productId) {
        return {
          ...product,
          [name]: value,
        }
      } else {
        return product
      }
    });
    this.setState({ favourites: favouritesCopy })
  }

  render() {
    const { favourites } = this.state;
    const { user, cart, addProductToCart, removeProductFromCart } = this.props;
    // console.log("favourites: ", favourites);
    return (
      <Translation>
        {t =>
          <div className={`${productsSectionContainer}`}>
            <Header />
            <div className={`${container}`} style={{ backgroundColor: FULL_WHITE }}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                <span>
                  <h3 className={`${title}`} style={{ color: BLACK, fontWeight: 'normal' }}>{t("favouritesTitle1")}</h3>
                </span>
                <span style={{ marginLeft: 5 }}>
                  <h3 className={`${title}`} style={{ color: BLACK, fontWeight: 'bold' }}>{t("favouritesTitle2")}</h3>
                </span>
              </span>
              <div className={`${tableContainer}`}>
                {favourites && favourites.length > 0
                  ? <Table style={{ borderSpacing: 0 }}>
                    <Thead style={{ backgroundColor: BLACK, pading: 10 }}>
                      <Tr>
                        <Th><p className={`${thText}`}>{t('tableProductPartNumber')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductName')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductPicture')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductPrice')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductQuantity')}</p></Th>
                        <Th><p className={`${thText}`}>{t('tableProductTotal')}</p></Th>
                        {user && <Th></Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {favourites.map(product => {
                        const isAdded = cart && cart.length > 0 && cart.filter(item => item._id === product._id).length > 0
                        return (
                          <Tr className={`${trStyles}`} key={product._id}>
                            <Td>
                              <p style={{ color: BLACK, margin: 10 }}>
                                {product.partNumber}
                              </p>
                            </Td>
                            <Td>
                            <ProductDescription
                                onClick={() => user ? this.setState({ selectedProduct: product, isModalOpen: true }) : null}
                                style={{ color: BLACK, margin: 10, cursor: user ? 'pointer' : '' }}
                                description={product.name} 
                                />
                              {/* <p
                                onClick={() => this.setState({ selectedProduct: product, isModalOpen: true })}
                                style={{ color: BLACK, margin: 10, cursor: 'pointer' }}
                              >
                                {product.name}
                              </p> */}
                            </Td>
                            <Td>
                              <img
                                alt=""
                                onClick={() => this.setState({ selectedProduct: product, isModalOpen: true })}
                                src={pictureIcon}
                                className={`${previewImage}`}
                              />
                            </Td>
                            <Td>
                              <p
                                style={{ color: BLACK, margin: 10 }}
                              >
                                {`${thousandSeparator(product.price)}$`}
                              </p>
                            </Td>
                            <Td>
                              <select
                                value={product.quantitySelected}
                                className={`${selectStyles}`}
                                name="quantitySelected"
                                onChange={(e) => {
                                  this.onChangeQuantity(e, product._id)
                                  removeProductFromCart(product)
                                }}
                              >
                                {range(quantitiesMax).map(quantity =>
                                  <option value={Number(quantity) + 1}>{Number(quantity) + 1}</option>
                                )}
                              </select>
                            </Td>
                            <Td>
                              <p
                                style={{ color: BLACK, margin: 10 }}
                              >
                                {`${thousandSeparator(product.price * (product.quantitySelected || 1))}$`}
                              </p>
                            </Td>
                            {user && <Td>
                              <div className={`${orderButtonsContainer} ${productName}`}>
                                {(user && validateUser(["super-admin", "admin", "products-admin"], user.userType)) && <div className={`${buttonContainer}`}>
                                  <Link to={`/edit-product/?product=${product._id}`}>
                                    <img src={editIcon} className={`${editIconStyles}`} alt="" />
                                  </Link>
                                </div>}
                                <div className={`${orderButton}`}>
                                  <div
                                    onClick={() => isAdded ? removeProductFromCart(product) : addProductToCart(product)}
                                    style={{ border: `${WHITE} 1px solid` }}
                                    className={`product-button-container ${isAdded ? buttonBackground : ""}`}
                                  >
                                    {isAdded && <img src={cartIcon} className="cart-icon-styles" alt="" />}
                                    <p className={`product-button-text ${isAdded ? buttonText : ""}`}>{!isAdded ? t("addToCart") : t("removeFromCart")}</p>
                                  </div>
                                </div>
                              </div>
                            </Td>}
                          </Tr>
                        )
                      }
                      )}
                    </Tbody>
                  </Table>
                  : this.state.fetching
                    ? <Loader color={BLACK} className={`${loader}`} />
                    : <h3 style={{ color: BLACK, margin: 20, marginTop: 50, marginBottom: 50 }}>{t('noFavouritesYet')}</h3>}
              </div>
            </div>
            <ContactUs to={'/home'} />
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
            {this.state.selectedProduct
              ? <ProductDetailModal
                isOpen={this.state.isModalOpen}
                addToFavourites={this.addToFavourites}
                removeFromFavourites={this.removeFromFavourites}
                user={user}
                onRequestClose={() => this.setState({ isModalOpen: false })}
                selectedProduct={this.state.selectedProduct}
              />
              : null}
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
    addProductToCart: bindActionCreators(actions.addProductToCart, dispatch),
    removeProductFromCart: bindActionCreators(actions.removeProductFromCart, dispatch),
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
    cart
  } = state;
  return {
    user,
    cart
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Favourites);
