import { firebaseStorage } from "../firebase/config";

const deleteFiles = (urls) => {
    var filesRoutes = [];

      urls.forEach(url => {
        var list = url.split('/');
        filesRoutes.push(list[list.length - 1].split('?')[0].replace('%2F', '/'));
      })

    // console.log("deleteFiles: ", filesRoutes);
    let promisesArray = []
    filesRoutes.forEach(route => {
      promisesArray.push(new Promise((resolve, reject) => {
          // console.log("current route: ", route);
          const storageRef = firebaseStorage.ref();
          var imageRef = storageRef.child(route); // ig: images/desert.jpg
          imageRef.delete().then(function (res) {
            // console.log("delete image response: ", res)
            return resolve()// File deleted successfully
          }).catch(function (error) {
            // console.log("delete image error: ", error)
            return resolve()// File deleted successfully
            // Uh-oh, an error occurred!
          });
        })
      )
    })

    // console.log("promisesArray: ", promisesArray);
    return Promise.all(promisesArray);
  }

  export default deleteFiles;