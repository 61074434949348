import firebase from 'firebase';
import 'firebase/storage';

// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: "AIzaSyDIpGAWFHJQWa61ze9Sx9aMFPIMcywopvo",
  authDomain: "neurons-shopping-cart.firebaseapp.com",
  databaseURL: "https://neurons-shopping-cart.firebaseio.com",
  projectId: "neurons-shopping-cart",
  storageBucket: "neurons-shopping-cart.appspot.com",
  messagingSenderId: "891231184198",
  appId: "1:891231184198:web:de7d9eff410c0d71197147",
  measurementId: "G-62LS5FFMR8"
};
firebase.initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
var firebaseStorage = firebase.storage();
let fire = firebase;
export {
  firebaseStorage,
  fire
}