import { firebaseStorage } from "../firebase/config";

export const saveFile = (
  name,
  base64,
  folder = 'products'
) => {
  // console.log("saveimage uri: ", uri);
  // console.log("saveimage name: ", name);
  return new Promise(async (resolve, reject) => {
    try {
      const storageRef = firebaseStorage.ref();
      var uploadTask = storageRef.child(`${folder}/${name}`);
      // console.log("base64: ", base64);
      await uploadTask.putString(base64, 'data_url', { contentType: `image/jpg` })
      const downloadURL = await uploadTask.getDownloadURL()
      // console.log("downloadURL: ", downloadURL);
      resolve(downloadURL);
    } catch (e) {
      // console.log("uploadTask.putString() / error: ", e);
      reject(e);
    }
  })
}

export const saveFilesToCloud = (files) => {
  // console.log("saveFilesToCloud / files: ", files);
  if (files.length > 0) {
    let promiseArray = [];

    files.forEach((file) => {
      promiseArray.push(saveFile(String(Math.random()).replace('0.', ''), file, "products"))
    })

    return Promise.all(promiseArray)
  } else {
    return Promise.reject('files are required to upload.');
  }
}

export default saveFilesToCloud;