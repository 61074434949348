import React, { Component } from 'react';
const URL = require('url-parse')

class SafeURL extends Component {
  isSafe(dangerousURL, text) {
    const url = URL(dangerousURL, {})
    if (url.protocol === 'http:') return true
    if (url.protocol === 'https:') return true
    return false
  }
  render() {
    const dangerousURL = this.props.dangerousURL
    const safeURL = this.isSafe(dangerousURL) ? dangerousURL : null
    return <a
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      {...this.props}
      style={this.props.style}
      href={safeURL}
    >
      {this.props.children}
    </a>
  }
}

export default SafeURL;