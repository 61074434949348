import { postData } from "../fetch/services";

const saveStat = async (type, product) => {
    const userId = localStorage.getItem('userId')
    if(userId){
      const data = {
        user: userId,
        product: product,
        type: type,
      }
      await postData('/stat/create-stat', data);
    //   console.log("saveStat / response: ", response);
    }
  }

  export default saveStat;