export const YELLOW = "#fff806"; 
export const YELLOW_DARK = "#f4e94c"; 
export const WHITE = "#ededee";
export const FULL_WHITE = "#FFFFFF";
export const GRAY = "gray";
export const WHITE_OPACITY = "rgba(237, 237, 238, 0.2)";
export const BLACK = "#080607";
export const BLACK_OPACITY = "rgba(8, 6, 7, 0.2)";
export const BLACK_OPACITY2 = "rgba(8, 6, 7, 0.5)";
export const RED = "red";
