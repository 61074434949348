import React from 'react';
import { Translation } from 'react-i18next';
import { BLACK, FULL_WHITE, WHITE } from '../../utils/color-palatte';
import { css } from 'emotion';
import contactUsBgImage from "../../assets/dashboard/contact-us-bg.png";
import TextArea from '../../utils/TextArea';
import Input from '../../utils/Input';
import { emailsRegex } from '../../utils/regex';
import { postData } from '../../fetch/services';
import Button from '../../utils/Button';
import Alert from '../../utils/Alert';

const contactUsContainer = css`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: 350px;
  width: 100%;
  background-image: url("${contactUsBgImage}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media(max-width: 1070px) {
    flex-direction: column;
  }
  @media(max-width: 650px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const form = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: relative;
padding: 10px;
padding-right: 50px;
padding-left: 50px;
max-width: 500px;
@media(max-width: 650px) {
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
  width: 90%;
}
  `

const messageInput = css`
font-size: 18px;
display: block;
width: 100%;
resize: none;
border: none;
border-radius: 5px;
margin-top: 10px;
margin-bottom: 10px;
`

const titleText = css`
margin: 0;
margin-top: 10px;
margin-bottom: 0;
font-weight: bolder;
  `

const firstInputContainer1 = css`
align-items: center;
width: 90%
  `
const firstInputContainer2 = css`
align-items: center;
  `

class ContactUsComplete extends React.Component {

  state = {
    contactLoading: false,
    contactMessage: '',
    contactEmail: '',
    contactName: '',
  }

  onContact = (event, t) => {
    event.preventDefault();
    if (this.state.contactEmail.match(emailsRegex) &&
      this.state.contactMessage) {
      this.setState({ contactLoading: true }, async () => {
        try {
          const data = {
            name: this.state.contactName,
            message: this.state.contactMessage,
            email: this.state.contactEmail
          }
          await postData('/contact/create-contact', data, false);
          // console.log("onContact / response: ", response);
          this.showAlert(true, t('contactEmailSended'), true)
          this.setState({
            contactLoading: false,
            contactName: '',
            contactMessage: '',
            contactEmail: '',
            showInputsErrorContact: false
          })
        } catch (e) {
          // console.log("onContact / error: ", e);
          const error = e && e.data && e.data.error && e.data.error.message ? e.data.error.message : t('internalError')
          this.showAlert(true, error, false)
          this.setState({ contactLoading: false })
        }
      })
    } else {
      this.setState({ showInputsErrorContact: true })
      if (this.state.contactEmail && !this.state.contactEmail.match(emailsRegex)) {
        this.showAlert(true, t('emailIsInvalid'), false)
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  render() {
    return (
      <Translation>
        {t =>
          <div style={{ backgroundColor: WHITE }} className={`${contactUsContainer}`}>
            <form
              className={`${form}`}
              style={{ backgroundColor: FULL_WHITE }}
              noValidate
              autoComplete='off'
              onSubmit={(e) => this.onContact(e, t)}
            >
              <h1 className={`${titleText}`} style={{ color: BLACK }}>
                {t("contactUsCompleteTitle")}
              </h1>
              <h5 className={`${titleText}`} style={{ color: BLACK }}>
                {t("contactUsCompleteTitle2")}
              </h5>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 10
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}>
                  <Input
                    placeholder={t("nameInput")}
                    type="text"
                    width="100%"
                    className={`${firstInputContainer1}`}
                    value={this.state.contactName}
                    onChange={this.handleInputChange}
                    name='contactName'
                    disabled={this.state.contactLoading}
                  />
                  <Input
                    placeholder={t("emailInput")}
                    type="text"
                    className={`${firstInputContainer2}`}
                    width="100%"
                    value={this.state.contactEmail}
                    showErrors={this.state.showInputsErrorContact}
                    onChange={this.handleInputChange}
                    name='contactEmail'
                    disabled={this.state.contactLoading}
                  />
                </div>
                <TextArea
                  rows="4"
                  cols="15"
                  placeholder={t("messageInput")}
                  name='contactMessage'
                  showErrors={this.state.showInputsErrorContact}
                  className={`${messageInput}`}
                  onChange={this.handleInputChange}
                  value={this.state.contactMessage}
                  disabled={this.state.contactLoading}
                />
              </div>
              <Button
                type="submit"
                style={{ zIndex: 1, minWidth: 100, minHeight: 38, backgroundColor: BLACK }}
                loading={this.state.contactLoading}
                loaderColor={WHITE}
                disabled={this.state.contactLoading}
                content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30 }}>
                  {t("contactButtonText2")}
                </p>}
                width=""
              />
            </form>
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

export default ContactUsComplete;
