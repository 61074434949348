export default {
    translation: {
        "home": "INICIO",
        "products": "PRODUCTOS",
        "we": "QUIÉNES SOMOS",
        "loginRegisterButton": "REGISTRAR",
        "loginButton": "LOGIN",
        "contactUs": "Contáctanos",
        "dashboardTitle": "MÁS DE 25 AÑOS",
        "dashboardTitle15": "DISTRIBUYENDO REPUESTOS",
        "dashboardTitle2": "AL MAYOR",
        "dashboardTitle16": "AL MAYOR",
        "messageInput": "Mensaje",
        "emailInput": "Correo",
        "nameInput": "Nombre completo",
        "contactButtonText": "Contáctar",
        "contactButtonText2": "Enviar",
        "homeProductsOurText": "NUESTROS",
        "homeProductsProductsText": "PRODUCTOS",
        "homeProductsTitleContent": "Contamos con mayor cantidad de repuestos y partes de motores diesel, ofreciendo las mejores opciones.",
        "weSubTitle": "Nosotros",
        "weTitle": "NOSOTROS",
        "weContent": "Tenemos más de 25 años de experiencia suministrando la cobertura más amplia de piezas de motor y piezas de inyección de combustible, ofreciendo las mejores opciones en el mercado de reemplazo. Nuestra misión es ofrecer productos de alta calidad, envío inmediato al mismo tiempo que ofrecemos precios competitivos. Estos valores nos han ganado la lealtad de nuestros clientes en todo el país, estamos convencidos de que nuestro compromiso ayudará a fortalecer su negocio.",
        "addToCart": "Añadir al carrito",
        "moreDetails": "Más detalles",
        "seeAllProducts": "Ver todos",
        "searchProductsInputPlaceholder": "Buscar pieza",
        "footerLastInfo": "2020 Mayor Diesel",
        "correo": "Email",
        "footerMenuTitle": "Menu",
        "footerHome": "Inicio",
        "footerProducts": "Productos",
        "footerWe": "Quiénes somos",
        "footerSocialMediaTitle": "Social Media",
        "footerFacebook": "Facebook",
        "footerTwitter": "Twitter",
        "contactUsTitle": "CONTÁCTANOS",
        "contactUsClickHere": "Click aquí",
        "loginTitle": "ACCEDER",
        "signupTitle": "REGISTRARSE",
        "signinEmail": "Correo electronico",
        "signinPassword": "Contraseña",
        "forgetPassword": "Olvidaste la contraseña?",
        "forgetPassClickHere": "Click aquí",
        "optional": "Opcional",
        "signupName": "Nombre completo",
        "signupPhone": "Numero de teléfono",
        "filterByBrand": "Por motor",
        "filterBySerie": "Por serie",
        "seeMoreProducts": "Ver más",
        "productsSectionOurText": "NUESTROS",
        "productsSectionProductsText": "PRODUCTOS",
        "contactEmailSended": "Correo de contacto enviado.",
        "internalError": "Error interno del servidor, intenta de nuevo.",
        "emailIsInvalid": "Correo invalido.",
        "email-verification-sended": "Verification email sended to",
        "signup-complete": "Registro exitoso.",
        "cartTitle1": "CARRITO DE",
        "cartTitle2": "COMPRAS",
        "cartTotal": "TOTAL DEL CARRITO",
        "completeBuy": "COMPLETAR COMPRA",
        "cartProductTitle": "Producto",
        "cartPriceTitle": "Precio",
        "cartQuantityTitle": "Cantidad",
        "cartTotalTitle": "Total",
        "createProductTitle": "CREAR PRODUCTO",
        "createProductName": "Descripción",
        "createProductDescription": "Detalles",
        "partNumber": "Numero de parte",
        "createProductButton": "CREAR PRODUCTO",
        "createProductBrand": "Tipo de Motor",
        "createProductSerie": "Serie",
        "createProductQuantity": "Cantidad de productos",
        "create-product-complete": "Producto creado.",
        "noResulst": "No se encontraron productos.",
        "removeFromCart": "Remover del carrito.",
        "cartEmpty": "No hay productos todavia.",
        "sureToRemoveProduct": "Estas seguro de eliminar el producto:",
        "noMoreProducts": "No hay más productos.",
        "seeAllProductsProducts": "Ver todos",
        "resetEmail": "Correo",
        "resetPasswordTitle": "RESETEAR CONTRASEÑA",
        "resetPasswordButton": "ENVIAR",
        "invalidEmail": "Correo invalido.",
        "pleaseCheckEmail": "Revisa tu correo por favor.",
        "cannotValidateToken": 'No se puede validar el token, por favor genera otro.',
        "missingToken": 'Falta token.',
        "newPassword": 'Contraseña nueva',
        "changePasswordButton": 'CAMBIAR CONTRASEÑA',
        "passwordChanged": 'Contraseña cambiada con exito.',
        "contactUsCompleteTitle": 'CONTÁCTANOS',
        "contactUsCompleteTitle2": 'COMO PODEMOS AYUDARTE?',
        "weTitle1": 'QUIENES',
        "weTitle2": 'SOMOS',
        "wePhoneTitle": 'TELÉFONO',
        "weEmailTitle": 'E-MAIL',
        "weDirectionTitle": 'DIRECCIÓN',
        "weAnotherTitle": 'HORARIO',
        "order-delivered": 'Estamos revisando tu pedido, seras contáctado a la brevedad posible, muchas gracias.',
        "productDetailsBrand": 'MOTOR',
        "productDetailsSerie": 'SERIE',
        "productImages": 'IMAGENES DEL PRODUCTO',
        "titleBrands": 'MARCAS',
        "titleAlliedBrands": 'ALIADAS',
        "contactsEmpty": 'No hay contactos todavia.',
        "contactsName": 'Nombre',
        "contactsEmail": 'Correo',
        "contactsDate": 'Fecha',
        "contactsMessage": 'Mensaje',
        "contactsTitle": 'Contactos',
        "contactsButton": 'CONTACTOS',
        "noMoreContacts": 'No hay más contactos.',
        "noResultsContacts": 'No se encontraron contactos.',
        "ordersButton": 'ORDENES',
        "allOrdersButton": 'ORDENES',
        "ordersTitle": 'ORDENES',
        "ordersNr": 'ORDEN',
        "orderProducts": 'PRODUCTOS',
        "orderDate": 'FECHA',
        "orderPrice": 'MONTO',
        "orderStatus": 'ESTATUS',
        "orderProductsButton": 'PRODUCTOS',
        "noResultsOrders": 'No se encontraron ordenes.',
        "ordersEmpty": 'No hay ordenes todavia.',
        "noMoreOrders": 'No hay más ordenes.',
        "adminText": '(ADMINISTRADOR)',
        "approvedStatus": 'APROBADO',
        "rejectedStatus": 'RECHAZADO',
        "pendingStatus": 'EN PROCESO',
        "footerOrdersButton": 'Ordenes',
        "footerContactsButton": 'Contactos',
        "userStatsButton": 'ESTADISTICAS',
        "userName": 'NOMBRE',
        "userEmail": 'CORREO',
        "userPhone": 'TELÉFONO',
        "noResultsUsers": 'No se encontraron usuarios.',
        "usersEmpty": 'No users yet.',
        "noMoreUsers": 'No more users.',
        "usersHeaderButton": 'USUARIOS',
        "footerUsersButton": 'Usuarios',
        "userCreated": 'F.CREADO',
        "usersTitle": 'USUARIOS',
        "searchUsersInputPlaceholder": 'Buscar usuarios.',
        "searchOrdersInputPlaceholder": 'Buscar ordenes.',
        "searchContactsInputPlaceholder": 'Buscar contactos.',
        "statsTitle": 'ESTADISTICAS DE USUARIO',
        "searchStatsInputPlaceholder": 'Busca estadisticas.',
        "statType": 'TIPO',
        "statProduct": 'PRODUCTO',
        "statDate": 'FECHA',
        "noResultsStats": 'No se encontraron estadisticas.',
        "statsEmpty": 'No hay estadisticas todavia.',
        "noMoreStats": 'No hay más estadisticas.',
        "userStatsEmail": 'Correo',
        "userStatsName": 'Nombre',
        "userStatsPhone": 'Teléfono',
        "userStatsUserTitle": 'Usuario',
        "notDefined": 'Sin definir',
        "statTypeView": 'Vista',
        "statTypeLogin": 'Inicio de sesion',
        "statTypeLogout": 'Cerrar sesion',
        "userActivities": 'ACTIVIDADES',
        "editProductTitle": 'EDITAR PRODUCTO',
        "saveProductButton": 'GUARDAR',
        "productEdited": 'Producto editado.',
        "favouritesTitle1": 'MIS',
        "favouritesTitle2": 'FAVORITOS',
        "footerOFavouritesButton": 'Favoritos',
        "noFavouritesYet": 'No hay favoritos todavia.',
        "productDetailsPartNumber": 'NRO DE PARTE',
        "tableProductPartNumber": 'NR DE PARTE',
        "tableProductName": 'DESCRIPCIÓN',
        "tableProductBrand": 'MARCA',
        "tableProductPicture": 'DETALLES',
        "cartProductPreview": 'DETALLES',
        "ordersUser": 'USUARIO',
        "tableProductPrice": 'PRECIO',
        "tableProductQuantity": 'CANTIDAD',
        "tableProductTotal": 'TOTAL',
        "dollar": '(USD)',
        "createProductMotorBrand": 'Marca',
        "productDetailsMotorBrand": 'MARCA',
        "super-admin": 'SUPER ADMINISTRADOR',
        "admin": 'ADMINISTRADOR JR',
        "employer": 'EMPLEADO',
        "seller": 'VENDEDOR',
        "client": 'CLIENTE',
        "products-admin": 'ADMINISTRADOR DE PRODUCTOS',
        "configurationHeaderButton": 'CONFIGURACIÓN',
        "configurationTitle": 'CONFIGURACIÓN',
        "footerConfigurationButton": 'Configuración',
        "bsValue": 'Bolivares (Bs) valor',
        "editConfigCurrency": 'Moneda',
        "currencyLabel": 'Moneda',
        "configEdited": 'Configuración actualizada.',
        "thirdPerson": 'Correo del usuario',
        "OrdderToThirdPerson": 'Crear orden a usuario:',
        "invalidThirdPersonEmail": 'Correo de usuario invalido.',
        "userTypeTable": 'Tipo de usuario',
        "order": 'orden',
        "contactUsContent": '¿Como podemos ayudarte?',
        "phone": 'Telefono',
        "orderThirdPerson": "Creado por",
        "orderUser": "Cliente",
        "weClientServiceTitle": "SERVICIO AL CLIENTE",
        "weClientServiceContent": "Nuestro personal altamente capacitado, con amplia experiencia en la industria, esta siempre a su disposición para procesar  pedidos, rastrear envíos, cotizar las piezas correctas e informarle sobre la disponibilidad de los productos.",
        "weProductsTitle": "NUESTROS PRODUCTOS",
        "weProductsContent": "Ofrecemos la más amplia cobertura en partes de motor y componentes de inyección del mercado de reemplazo.",
        "weFoEnginesTitle": "PARA MOTORES",
        "weDotText1": "Camiones Para Carga Pesada",
        "weDotText2": "Equipos de Minería y construcción",
        "weDotText3": "Equipos para la Agricultura",
        "weDotText4": "Aplicación Marina",
        "weDotText5": "Generación de energía",
        "weDotText6": "Gas Natural",
        "weSecondTitle": "REPUESTOS PARA MOTORES DIESEL Y MOTORES A GAS NATURAL",
        "weSecondDotText1": "Pistones / Juegos de Cilindros",
        "weSecondDotText2": "Juegos de Reparación Completa",
        "weSecondDotText3": "Conjuntos de Juntas o Empacaduras",
        "weSecondDotText4": "Retenedores de Aceite",
        "weSecondDotText5": "Cojinetes y Bujes",
        "weSecondDotText6": "Sistemas de Combustible",
        "weSecondDotText7": "Sistema de Lubricación",
        "weSecondDotText8": "Sistema de Enfriamiento",
        "weThirdTitle": "PARTES PARA CAMIONES Y REMOLQUES",
        "weThirdDotText1": "Motor",
        "weThirdDotText2": "Embrague",
        "weThirdDotText3": "Tren Motriz",
        "weThirdDotText4": "Suspensión",
        "weThirdDotText5": "Cabina y Chasis",
        "weThirdDotText6": "Aire y electricidad",
        "weGauaranteeTitle": "GARANTÍA",
        "weGauaranteeContent": "Todos los productos vendidos por www.mayordiesel.com tienen una garantía de fábrica que se pasa junto al comprador. Una solicitud por escrito es requerido por nosotros antes de devolver cualquier mercancía cubierta por esta garantía. POR FAVOR REVISE LAS ETIQUETAS O PAPELES DE GARANTÍA DE CUALQUIER TEMA ESPECÍFICO PARA GARANTIZAR RETORNO.",
        "weDeliveryTitle": "INSTRUCCIONES DE ENVÍO",
        "weDeliveryContent": "Los clientes nos deben proporcionar instrucciones de envío. De lo contrario, vamos a utilizar nuestro criterio en la selección de transportista o ruta.",
        "weTermsConditionsTitle": "TERMINOS Y CONDICIONES",
        "weTermsConditionsContent": "Prepago por transferencia bancaria Condiciones de crédito pueden estar disponibles para clientes calificados. Por favor, pregunte.",
        "help": "AYUDA",
        "helpTitle1": "PAGINA DE",
        "helpTitle2": "AYUDA",
        "help2": "Ayuda",
        "helpContactTitle": "CONTACTARNOS ES EL PRIMER PASO",
        "helpContactContent": "PARA ENCONTRAR LA MEJOR OPCIÓN",
        "helpTitle": "ÚNETE A NUESTRA RED DE ALIADOS",
        "helpTitleContent": "Y encuentra tus repuestos diesel al mayor en venezuela",
        "helpVideoContent": "Reproduce el video y conoce el proceso que debes seguir para registrarte, y formalizar tu proceso de compra.",
        "footerExtraInfo": "Más de 25 años siendo proveedores de repuestos diesel al mayor.",
        "weMarketSegmentTitle": "SEGMENTOS DEL MERCADO",
        "atentionTime": "Atención telefonica de lunes a viernes de 8am a 5pm.",
        "atentionTimeEn": "Telephone service is available Monday to Friday, 8:00 am to 5:00 pm.",
        "logosWarnings": "Todos los nombres de fabricantes, números, símbolos y descripciones, se utilizan solamente como referencia y no insinúan que los artículos listados sean productos de estos fabricantes.",
        "keepBuying": "CONTINUAR COMPRA",
        "sessionExpired": "Tu sesión ha expirado.",
        "not-found": "No encontrado",
        "madeBy": "Hecho Por"
    }
}