import React from 'react';
import './home-products.css';
import { Translation } from 'react-i18next';
import { BLACK, WHITE, FULL_WHITE } from '../../utils/color-palatte';
import wsIcon from "../../assets/icons/ws-icon.svg"
import tgIcon from "../../assets/icons/tg-icon.svg"
import searchIcon from "../../assets/icons/search-icon.svg"
import Product from "../../utils/product/Product"
import { css } from 'emotion'
import { wsMe, tgMe } from "../../config";
import { getData, postData } from '../../fetch/services';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import moment from "moment";
import Loader from '../../utils/Loader';
import { Link, Redirect } from 'react-router-dom';
import updateUser from '../../utils/updateUser';
import Alert from '../../utils/Alert';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const homeProductsMediaButton = css`
    cursor: pointer;
    width: 50px;
    height: 50px;
    @media(max-width: 650px) {
        width: 20px !important;
        height: 20px !important;
    }
  `
const searchProductInput = css`
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
    width: 200px;
    padding: 10px 10px 10px 5px;
    border: none;
    border-radius: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media(max-width: 420px) {
      margin-left: 0px;
    }
  `

const loader = css`
    margin-top: 30px;
    margin-bottom: 50px;
  `

class HomeProducts extends React.Component {

  state = {
    products: [],
    fetching: false,
    skipItems: 0,
    limitItems: 4,
    plusProductsNumber: 5,
    keepIncreasingCounter: false,
    selectedProduct: null,
    search: "",
    byBrand: "",
    bySerie: "",
    bsValue: "",
    currency: ""
  };

  componentDidMount = () => {
    this.fetchProducts();
  }

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  fetchProducts = () => {
    return new Promise(async (resolve, reject) => {
      const { skipItems, limitItems, fetching, search, byBrand, bySerie, products } = this.state;
      const { user } = this.props;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            await this.fetchConfig()
            const response = await getData(`/product/get-products/?userId=${user ? user._id : ""}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&brand=${byBrand}&serie=${bySerie}`);
            // console.log("getProducts / response: ", response);
            const newProducts = response.body;
            // console.log("getProducts / newProducts: ", newProducts);
            const finalProducts = products
              .concat(newProducts)
              .filter((item, index, self) =>
                index === self.findIndex((t) => (
                  t._id === item._id
                ))
              )
              .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
            // console.log("getProducts / finalProducts: ", finalProducts);
            this.setState({
              fetching: false,
              products: finalProducts,
              keepIncreasingCounter: newProducts.length > 0
            });
            resolve();
          } catch (e) {
            // reject(e);
            // console.log("fetchPosts / error: ", e)
            this.setState({ fetching: false })
            // const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
            // modal(error);
          }
        })
      }
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.setState({ redirect: true })
    }
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  }

  addToFavourites = (productId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        this.verifySession()
        await postData(`/product/add-favourite/?productId=${productId}&userId=${user._id}`);
        updateUser(this.props.saveUser)
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  removeFromFavourites = (productId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        this.verifySession()
        await postData(`/product/remove-favourite/?productId=${productId}&userId=${user._id}`);
        updateUser(this.props.saveUser)
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }

  fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getData(`/config/get-config`);
        // console.log("fetchConfig / response: ", response);
        const config = response.body

        this.setState({
          bsValue: config.bsValue,
          currency: config.currency,
        });
        resolve();
      } catch (e) {
        resolve(e);
        // console.log("fetchConfig / error: ", e)
        this.setState({ loading: false })
        const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
          ? e.response.data.error.message
          : null;
        const error = e && e.data && e.data.error ? e.data.error : null;
        const errorMessage = error && error.message && error.message[0] && error.message[0].msg
          ? error.message[0].msg
          : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
        this.showAlert(true, networkError || errorMessage, false)
      }
    })
  }

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { cart, addProductToCart, removeProductFromCart, user } = this.props;
    const { products, bsValue, currency } = this.state;
    // console.log("products: ", products);
    return (
      <Translation>
        {t =>
          <div style={{ backgroundColor: FULL_WHITE }} className="home-products-container">
            <div className="home-products-title-container">
              <span style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className="home-products-title">{t("homeProductsOurText")}</h1>
                </span>
                <span style={{ marginLeft: 5 }}>
                  <h1 className="home-products-title">{t("homeProductsProductsText")}</h1>
                </span>
              </span>
              <p className="home-products-content">{t("homeProductsTitleContent")}</p>
              <div className="home-products-media-buttons">
                {wsMe && <a href={wsMe} target="_blank" rel="noopener noreferrer">
                  <img className={`${homeProductsMediaButton}`} alt="ws" src={wsIcon} />
                </a>}
                {tgMe && <a href={tgMe} target="_blank" rel="noopener noreferrer">
                  <img className={`${homeProductsMediaButton}`} alt="ws" src={tgIcon} style={{ marginTop: 10 }} />
                </a>}
              </div>
            </div>
            <div className="products-container">
              {products && products.length > 0
                ? products.map((product, i) =>
                  <Product
                    bsValue={bsValue}
                    currency={currency}
                    isLogged={user}
                    product={product}
                    key={i}
                    addToFavourites={this.addToFavourites}
                    removeFromFavourites={this.removeFromFavourites}
                    isAdded={cart && cart.length > 0 && cart.filter(item => item._id === product._id).length > 0}
                    addProductToCart={() => {
                      this.verifySession()
                      addProductToCart(product)
                    }}
                    removeProductFromCart={() => {
                      this.verifySession()
                      removeProductFromCart(product)
                    }}
                  />
                )
                : <Loader color={BLACK} className={`${loader}`} />}
            </div>
            <div className="products-search-container">
              <Link to={`/products`}>
                <div className="see-all-products-button" style={{ backgroundColor: BLACK }}>
                  <h2 className="see-all-products-button-text" style={{ color: WHITE }}>
                    {t("seeAllProducts")}
                  </h2>
                </div>
              </Link>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <input
                  className={`${searchProductInput}`}
                  placeholder={t("searchProductsInputPlaceholder")}
                  type="text"
                  style={{ color: BLACK, backgroundColor: WHITE }}
                  value={this.state.search}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                  name='search'
                />
                <Link to={`/products/?search=${this.state.search}`}>
                  <img
                    src={searchIcon}
                    style={{ width: 45, height: 25, cursor: 'pointer', backgroundColor: BLACK, padding: 7 }}
                    alt="search-button"
                  />
                </Link>
              </div>
            </div>
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
            {this.state.redirect
              ? <React.Fragment>
                <Redirect
                  to={`/products/?search=${this.state.search}`}
                />
                {this.setState({ redirect: false })}
              </React.Fragment>
              : null
            }
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProductToCart: bindActionCreators(actions.addProductToCart, dispatch),
    removeProductFromCart: bindActionCreators(actions.removeProductFromCart, dispatch),
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
    cart
  } = state;
  return {
    user,
    cart
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeProducts);
