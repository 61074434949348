import React from 'react'
import { BLACK, WHITE } from './color-palatte'
import ModalComponent from './Modal';
import { css } from 'emotion';
// import lefArrowIcon from "../assets/products/left-arrow.svg";
// import rightArrowIcon from "../assets/products/right-arrow.svg";
import { Translation } from 'react-i18next';
// import AliceCarousel from 'react-alice-carousel'
// import 'react-alice-carousel/lib/alice-carousel.css'
import { Link } from 'react-router-dom';
import editIcon from "../assets/icons/edit.svg";
import defaultProductImage from "../assets/icons/timing-belt.svg";
import heartIcon from "../assets/icons/heart.svg";
import heartFillIcon from "../assets/icons/heart-fill.svg";
import removeRedIcon from "../assets/icons/remove-red-icon.svg";
import thousandSeparator from './thousandSeparator';
import { validateUser } from './validateUserType';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ProductNameTitle from './ProductNameTitle';

const productDetailsContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
position: relative;
overflow-x: hidden;
overflow-y: scroll;
max-height: 700px;
@media(max-width: 650px) {
  height: 90vh;
}
@media(max-width: 800px) {
  flex-wrap: wrap;
}
`

const productDetailImage = css`
width: 300px;
height: 300px;
`

// const prevImage = css`
// width: 40px;
// height: 40px;
// `

const productDetailsInfoContainer = css`
width: 100%;
height: 100%;
margin-left: 10px;
max-width: 400px;
`

// const lefArrowIconStyles = css`
// width: 30px !important;
// height: 30px;
// position: absolute;
// left: 0px;
// top: 40%;
// z-index: 20;
// cursor: pointer;
// `

// const rightArrowIconStyles = css`
// width: 30px !important;
// height: 30px;
// position: absolute;
// right: 0px;
// top: 40%;
// cursor: pointer;
// `

// const thumbItems = css`
// margin: 0;
// padding: 0;
// display: flex;
// justify-content: center;
// align-items: center;
// `

const removeRedIconStyles = css`
width: 30px;
height: 30px;
cursor: pointer;
margin: 5px;
`

const buttonsContainer = css`
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row;
bottom: 20px
`

const editIconStyles = css`
width: 30px;
height: 30px;
cursor: pointer;
border-radius: 5px;
margin: 5px;
background-color: ${WHITE}
`

const heartIconStyles = css`
width: 30px;
height: 30px;
cursor: pointer;
border-radius: 5px;
margin: 5px;
`

class ProductDetailModal extends React.Component {

  state = {
    currentIndex: 0,
    isFavourite: false,
    items: this.galleryItems()
  }

  componentDidMount = () => {
    // console.log("entro a componentDidMount")
    const { selectedProduct, user } = this.props;
    if (user &&
      user.favourites &&
      user.favourites.length > 0
      && user.favourites.filter(productId => productId === selectedProduct._id).length > 0) {
      this.setState({ isFavourite: true })
    } else {
      this.setState({ isFavourite: false })
    }
  }

  slideTo = (i) => this.setState({ currentIndex: i })

  onSlideChanged = (e) => {
    // console.log("e: ", e);
    this.setState({ currentIndex: e.item })
  }

  slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

  slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

  thumbItem = (item, i) => item

  galleryItems() {
    return this.props.selectedProduct.files && this.props.selectedProduct.files.length > 0
      ? this.props.selectedProduct.files.map((image, i) => <img alt="" className={`${productDetailImage}`} key={i} src={image} />)
      : []
  }

  render() {
    // const { currentIndex } = this.state;
    const { selectedProduct, user, removeProduct, addToFavourites, removeFromFavourites, bsValue, currency } = this.props;
    return (
      <Translation>
        {t =>
          <ModalComponent
            {...this.props}
          >
            <div className={`${productDetailsContainer}`}>
              <div style={{
                width: 300,
                height: selectedProduct &&
                  selectedProduct.files &&
                  selectedProduct.files.length > 1
                  ? 380
                  : 300,
                minHeight: 300,
                position: 'relative',
              }}>
                {selectedProduct && selectedProduct.files && selectedProduct.files.length > 0 && selectedProduct.files.length > 1
                  ? <Carousel
                    showArrows={true}
                    width={300}
                    // renderArrowPrev={(e) => {
                    //   console.log("renderArrowPrev / e", e)
                    //   return <img className={`${lefArrowIconStyles}`} onClick={e} src={lefArrowIcon} alt="" />
                    // }}
                    // renderArrowNext={(e) => {
                    //   console.log("renderArrowNext / e", e)
                    //   return <img className={`${rightArrowIconStyles}`} onClick={e} src={rightArrowIcon} alt="" />
                    // }}
                    thumbWidth={60}
                    renderIndicator={false}
                    renderThumbs={(items) => {
                      // console.log("renderThumbs / items: ", items)
                      return items.map(this.thumbItem)
                    }}
                  >
                    {this.props.selectedProduct.files.map((image, i) => <img alt="" className={`${productDetailImage}`} key={i} src={image} />)}
                  </Carousel>
                  //   <AliceCarousel
                  //   dotsDisabled={true}
                  //   buttonsDisabled={true}
                  //   items={this.props.selectedProduct.files.map((image, i) => <img alt="" className={`${productDetailImage}`} key={i} src={image} />)}
                  //   // responsive={responsive}
                  //   slideToIndex={currentIndex}
                  //   onSlideChanged={this.onSlideChanged}
                  // />
                  : selectedProduct && selectedProduct.files && selectedProduct.files.length > 0 && selectedProduct.files.length === 1
                    ? <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
                      <img src={selectedProduct.files[0]} alt="" style={{ width: 300, height: 300, alignSelf: 'center' }} />
                    </div>
                    : <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
                      <img src={defaultProductImage} alt="" style={{ width: 100, height: 100, alignSelf: 'center' }} />
                    </div>}
                {/* {selectedProduct.files && selectedProduct.files.length > 0 && selectedProduct.files.length > 1
                  ? <>
                    <ul className={`${thumbItems}`}>{selectedProduct.files.map(this.thumbItem)}</ul>
                    <img className={`${lefArrowIconStyles}`} src={lefArrowIcon} onClick={() => this.slidePrev()} alt="" />
                    <img className={`${rightArrowIconStyles}`} src={rightArrowIcon} onClick={() => this.slideNext()} alt="" />
                  </>
                  : null} */}
              </div>
              <div className={`${productDetailsInfoContainer}`}>
                <ProductNameTitle
                  style={{ color: BLACK, borderBottom: `1px solid ${BLACK}`, paddingBottom: 20 }}
                  description={selectedProduct.name}
                />
                {/* <h2 style={{ color: BLACK, borderBottom: `1px solid ${BLACK}`, paddingBottom: 20 }}>{selectedProduct.name}</h2> */}
                <p style={{ color: BLACK }}>{selectedProduct.description}</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5 }}>
                  <p style={{ color: BLACK, margin: 0, fontWeight: 'bold' }}>{`${t('productDetailsBrand')}:`}</p>
                  <p style={{ color: BLACK, margin: 0, marginLeft: 5 }}>{selectedProduct.brand}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5 }}>
                  <p style={{ color: BLACK, margin: 0, fontWeight: 'bold' }}>{`${t('productDetailsSerie')}:`}</p>
                  <p style={{ color: BLACK, margin: 0, marginLeft: 5 }}>{selectedProduct.serie}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5 }}>
                  <p style={{ color: BLACK, margin: 0, fontWeight: 'bold' }}>{`${t('productDetailsMotorBrand')}:`}</p>
                  <p style={{ color: BLACK, margin: 0, marginLeft: 5 }}>{selectedProduct.motorBrand || t('notDefined')}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5 }}>
                  <p style={{ color: BLACK, margin: 0, fontWeight: 'bold' }}>{`${t('productDetailsPartNumber')}:`}</p>
                  <p style={{ color: BLACK, margin: 0, marginLeft: 5 }}>{selectedProduct.partNumber}</p>
                </div>
                {user && <h2 style={{ color: BLACK }}>{`${thousandSeparator(currency === "bs" ? selectedProduct.price * bsValue : selectedProduct.price)}${currency === "bs" ? "Bs" : "$"}`}</h2>}
                {user && <div className={`${buttonsContainer}`}>
                  {(user && validateUser(["super-admin", "admin", "products-admin"], user.userType)) && <>
                    {removeProduct && <img onClick={removeProduct} src={removeRedIcon} className={`${removeRedIconStyles}`} alt="" />}
                    <Link to={`/edit-product/?product=${selectedProduct._id}`} style={{ display: 'flex' }}>
                      <img src={editIcon} className={`${editIconStyles}`} alt="" />
                    </Link>
                  </>}
                  <img
                    onClick={() => {
                      this.setState({ isFavourite: !this.state.isFavourite }, () => {
                        if (!this.state.isFavourite) {
                          removeFromFavourites(selectedProduct._id)
                        } else {
                          addToFavourites(selectedProduct._id)
                        }
                      })
                    }}
                    src={this.state.isFavourite ? heartFillIcon : heartIcon}
                    className={`${heartIconStyles}`}
                    alt=""
                  />
                </div>}
              </div>
            </div>
          </ModalComponent>
        }
      </Translation>
    )
  }
}

export default ProductDetailModal;