import saveStat from '../utils/saveStat';
//TYPES
import {
  SAVE_USER,
  SIGNOUT,
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  EDIT_PRODUCT_FROM_CART,
  CLEAR_CART
} from './types';

//FUNCTIONS

// initial state
const initialState = {
  user: null,
  cart: null
};

// Helper Functions

function saveUser(state, payload) {
  return {
    ...state,
    user: payload.data
  };
};

function addProductToCart(state, payload) {
  let cartCopy = state.cart || []
  if (state.cart && state.cart.filter(product => product._id === payload.data._id).length > 0) {
    return {
      ...state
    };
  } else {
    return {
      ...state,
      cart: [...cartCopy, payload.data]
    };
  }
};

function removeProductFromCart(state, payload) {
  let products = state.cart ? state.cart.filter(product => product._id !== payload.data._id) : []
  return {
    ...state,
    cart: products
  };
};

function clearCart(state) {
  return {
    ...state,
    cart: null
  };
};

function editProduct(state, payload) {
  const productId = payload.productId
  const newProduct = payload.newProduct
  const cartCopy = state.cart
  const finalCart = cartCopy ? cartCopy.map(product => {
    if (product._id === productId) {
      return newProduct
    } else {
      return product
    }
  }) : null
  return {
    ...state,
    cart: finalCart
  };
};

async function signOut(state, payload) {
  saveStat('logout')
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.clear();
  return {
    ...state,
    user: null,
    cart: null
  };
};

// Reducer Function

function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER:
      return saveUser(state, action);
    case SIGNOUT:
      return signOut(state, action);
    case ADD_PRODUCT_TO_CART:
      return addProductToCart(state, action);
    case REMOVE_PRODUCT_FROM_CART:
      return removeProductFromCart(state, action);
    case EDIT_PRODUCT_FROM_CART:
      return editProduct(state, action);
    case CLEAR_CART:
      return clearCart(state, action);
    default:
      return state;
  };
};

export default reducer;
