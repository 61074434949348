import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import motorImage from "../../assets/dashboard/principal-banner-piece.png"
import bgImage from "../../assets/dashboard/principal-banner.png"
import blkDieselLogo from "../../assets/home-brands/blk-diesel-logo.png"
import boydLogo from "../../assets/home-brands/boyd-logo.png"
import ctpLogo from "../../assets/home-brands/ctp-logo.png"
import federalModulLogo from "../../assets/home-brands/federal-mogul-logo.png"
import fpDiesel from "../../assets/home-brands/fp-diesel-logo.png"
import interstateMcbeeLogo from "../../assets/home-brands/interstate-mcbee-logo.png"
import maxiforceLogo from "../../assets/home-brands/maxiforce-logo.png"
import paiLogo from "../../assets/home-brands/pai-logo.png"
// import { postData, getData } from "../../fetch";
import Button from '../../utils/Button';
import { YELLOW, WHITE, BLACK, FULL_WHITE } from '../../utils/color-palatte';
import HomeProducts from '../home-products/HomeProducts';
import ContactUs from '../contact-us/ContactUs';
import HomeFooter from '../home-footer/HomeFooter';
import { css } from 'emotion'
import { postData } from '../../fetch/services';
import { emailsRegex } from '../../utils/regex';
import ModalComponent from '../../utils/Modal';
import Input from '../../utils/Input';
import TextArea from '../../utils/TextArea';
import Alert from '../../utils/Alert';

const form = css`
display: flex;
flex-direction: column;
align-items: flex-start;
padding-left: 20%;
position: relative;
@media(max-width: 650px) {
  padding-left: 0px;
  align-items: center;
}
  `

const titleContainer = css`
@media(max-width: 650px) {
  margin-left: 10px;
}
  `

const brandsContainer = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 300px;
  `

const brandsContainer2 = css`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
max-width: 1000px
  `

const motorImageStyles = css`
width: 450px;
height: 450px;
position: absolute;
bottom: -50px;
right: 20%;
z-index: 0;
@media(max-width: 600px) {
  width: 250px;
  height: 250px;
}
  `

const homeContainer = css`
  text-align: center;
  min-height: 400px;
  background-image: url("${bgImage}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-bottom: 30px;
`

const titleText = css`
margin: 0;
margin-top: 10px;
margin-bottom: 0px;
z-index: 1;
text-align: left;
font-family: 'Barlow';
font-weight: bolder;
font-size: 40px;
  `

const messageInput = css`
font-size: 18px;
display: block;
width: 100%;
resize: none;
border: none;
border-radius: 5px;
margin-top: 10px;
margin-bottom: 10px;
font-family: sans-serif;
`

const changePasswordContainer = css`

`

const inputStyles = css`
margin-top: 10px;
`

const brandContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
justify-content: space-between;
`

class Home extends React.Component {

  state = {
    contactLoading: false,
    contactMessage: '',
    contactEmail: '',
    contactName: '',
    changePassLoading: false,
    resetPassEmail: '',
    password: '',
    isModalOpen: false,
    resetPasswordLoading: false,
    showModalInputsErrors: false,
    showInputsErrorContact: false,
    isAlertOpen: false,
    alertSuccess: false,
    alertContent: ""
  };

  componentDidMount = () => {
    // const url_string = window.location.href;
    // const url = new URL(url_string);
    // const token = url.searchParams.get("token");
    // if (token) {
    //   this.confirmEmail()
    // }
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get("passwordtoken");
    if (token) {
      this.setState({ isModalOpen: true });
    }

    window.scrollTo(0, 0)
  };

  // confirmEmail = async () => {
  //   const url_string = window.location.href;
  //   const url = new URL(url_string);
  //   const token = url.searchParams.get("token");
  //   // console.log("confirmEmail / token: ", token);
  //   try {
  //     const response = await postData('/user/confirm-email', { token }, false);
  //     // console.log("confirmEmail / response: ", response);
  //     alert(response.body);
  //   } catch (e) {
  //     // console.log("confirmEmail / error: ", e);
  //     const error = e && e.data && e.data.error && e.data.error.message
  //       ? e.data.error.message
  //       : language === "en"
  //         ? 'Cannot validate token, please generate another one.'
  //         : 'No se puede validar el token, genere otro.'
  //     alert(error);
  //   }
  // }

  onContact = (event, t) => {
    event.preventDefault();
    if (this.state.contactEmail.match(emailsRegex) && this.state.contactMessage) {
      this.setState({ contactLoading: true }, async () => {
        try {
          const data = {
            name: this.state.contactName,
            message: this.state.contactMessage,
            email: this.state.contactEmail
          }
          await postData('/contact/create-contact', data, false);
          // console.log("onContact / response: ", response);
          this.showAlert(true, t('contactEmailSended'), true)
          this.setState({
            contactLoading: false,
            contactName: '',
            contactMessage: '',
            contactEmail: '',
            showInputsErrorContact: false
          })
        } catch (e) {
          // console.log("onContact / error: ", e);
          const error = e && e.data && e.data.error && e.data.error.message ? e.data.error.message : t('internalError')
          this.showAlert(true, error, false)
          this.setState({ contactLoading: false })
        }
      })
    } else {
      this.setState({ showInputsErrorContact: true })
      if (this.state.contactEmail && !this.state.contactEmail.match(emailsRegex)) {
        this.showAlert(true, t('emailIsInvalid'), false)
      }
    }
  };

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  changePassword = (t) => {
    const { password } = this.state;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get("passwordtoken");
    // console.log("token: ", token);
    if (password &&
      token) {
      this.setState({ changePassLoading: true }, async () => {
        const data = { token, password };
        // console.log("changePassword / data: ", data)
        try {
          await postData('/user/change-password', data, false);
          // console.log("changePassword / response: ", response);
          this.showAlert(true, t('passwordChanged'), true)
          this.setState({
            changePassLoading: false,
            showModalInputsErrors: false,
            isModalOpen: false,
            password: ""
          })
        } catch (e) {
          this.setState({ changePassLoading: false })
          // console.log("changePassword / error: ", e);
          const error = e && e.data.error && e.data.error.message
            ? e.data.error.message
            : t('cannotValidateToken')
          this.showAlert(true, error, false)
        }
      })
    } else {
      this.setState({ showModalInputsErrors: true })
      if (!token) {
        this.showAlert(true, t('missingToken'), false)
      }
    }
  }

  render() {
    const lang = String(localStorage.getItem("language") || "ES")
    // console.log("lang: ", lang)
    // const { user } = this.props;
    // console.log("user: ", user);
    return (
      <Translation>
        {t =>
          <div>
            <div className={`${homeContainer}`}>
              <Header />
              <form className={`${form}`} noValidate autoComplete='off' onSubmit={(e) => this.onContact(e, t)}>
                <div className={titleContainer} style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                  <h1 className={`${titleText}`} style={{ color: WHITE }}>
                    <span>{t("dashboardTitle")}</span><br />
                    <span>{t("dashboardTitle15")}</span><br />
                    <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <span style={{ color: YELLOW, marginRight: 10 }}>DIESEL</span>
                      <span>{t("dashboardTitle2")}</span>
                    </span>
                    {lang !== "es"
                    ? <span>{t("dashboardTitle16")}</span>
                  : null}  
                  </h1>
                </div>
                <div style={{
                  zIndex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                  <Input
                    placeholder={t("nameInput")}
                    type="text"
                    value={this.state.contactName}
                    onChange={this.handleInputChange}
                    name='contactName'
                    disabled={this.state.contactLoading}
                  />
                  <Input
                    placeholder={t("emailInput")}
                    type="text"
                    className={`${inputStyles}`}
                    showErrors={this.state.showInputsErrorContact}
                    style={{ color: BLACK, backgroundColor: WHITE, marginTop: 10 }}
                    value={this.state.contactEmail}
                    onChange={this.handleInputChange}
                    name='contactEmail'
                    disabled={this.state.contactLoading}
                  />
                  <TextArea
                    rows="4"
                    cols="15"
                    placeholder={t("messageInput")}
                    showErrors={this.state.showInputsErrorContact}
                    name='contactMessage'
                    style={{ color: BLACK, backgroundColor: WHITE }}
                    className={`${messageInput}`}
                    onChange={this.handleInputChange}
                    value={this.state.contactMessage}
                    disabled={this.state.contactLoading}
                  />
                </div>
                <Button
                  type="submit"
                  style={{ zIndex: 1, minWidth: 100, minHeight: 38 }}
                  loading={this.state.contactLoading}
                  disabled={this.state.contactLoading}
                  content={t("contactButtonText")}
                  width=""
                />
                <img src={motorImage} className={`${motorImageStyles}`} alt="" />
              </form>
            </div>
            <HomeProducts />
            <ContactUs />
            <div className={`${brandsContainer}`} style={{ backgroundColor: FULL_WHITE }}>
              <span style={{ display: 'flex', flexWrap: 'wrap', margin: 30 }}>
                <span style={{ color: BLACK, marginRight: 10, fontWeight: 'bold', fontSize: 30 }}>{t("titleBrands")}</span>
                <span style={{ color: BLACK, fontSize: 30 }}>{t("titleAlliedBrands")}</span>
              </span>
              <div className={`${brandsContainer2}`}>
              <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={blkDieselLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>BLK DIESEL®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={boydLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>BOYD®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 150 }} src={ctpLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>CTP®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={federalModulLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>FEDERAL MOGUL®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={fpDiesel} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>FP DIESEL®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={interstateMcbeeLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>INTERSTATE MCBEE®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={maxiforceLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>MAXIFORCE®</p>
                </div>
                <div className={`${brandContainer}`}>
                  <img style={{ width: 200, height: 200 }} src={paiLogo} alt="" />
                  <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>PAI®</p>
                </div>
              </div>
              <p style={{ maxWidth: 900, textAlign: 'center', marginTop: 20 }}>{t('logosWarnings')}</p>
            </div>
            <HomeFooter />
            <ModalComponent
              isOpen={this.state.isModalOpen}
              onRequestClose={() => this.setState({ isModalOpen: false })}
            >
              <div className={`${changePasswordContainer}`}>
                <h2 style={{ color: BLACK }}>{t("resetPasswordTitle")}</h2>
                <Input
                  type="password"
                  label={t('newPassword')}
                  value={this.state.password}
                  showErrors={this.state.showModalInputsErrors}
                  onChange={this.handleInputChange}
                  disabled={this.state.changePassLoading}
                  name='password'
                  isRequired
                />
                <Button
                  loading={this.state.changePassLoading}
                  onClick={() => this.changePassword(t)}
                  disabled={this.state.changePassLoading}
                  content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30 }}>
                    {t("changePasswordButton")}
                  </p>}
                  style={{ backgroundColor: BLACK, marginTop: 10, minWidth: 100, minHeight: 38 }}
                  loaderColor={FULL_WHITE}
                />
              </div>
            </ModalComponent>
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
