import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import HomeFooter from '../home-footer/HomeFooter';
import { css } from 'emotion'
import { BLACK, FULL_WHITE, WHITE } from '../../utils/color-palatte';
import Input from "../../utils/Input";
import Select from "../../utils/Select";
import Button from "../../utils/Button";
import { postData } from '../../fetch/services';
import motorsBrands from "../../motor-brands.json";
import MultiImageInput from 'react-multiple-image-input';
import saveFilesToCloud from '../../utils/saveFilesToCloud';
import Alert from '../../utils/Alert';
import Loader from '../../utils/Loader';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const signinSignupContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const itemsContainer = css`
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
`

const item = css`
min-width: 280px;
display: flex;
justify-content: flex-start;
max-width: 400px;
display: flex;
align-items: flex-start;
flex-direction: column;
padding-right: 20px;
padding-left: 20px;
@media(max-width: 390px) {
  width: 100%
}
`

const inputStyles = css`
width: 100%;
`

const selectStyles = css`
margin: 20px;
margin-left: 0px;
  `

class CreateProduct extends React.Component {

  state = {
    images: {},
    name: "",
    description: "",
    productPartNumber: "",
    motorBrand: "",
    productBrand: "",
    price: "",
    productSerie: "",
    loading: false,
    showInputsErrors: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0)
  };

  createProduct = (t) => {
    const { user } = this.props;
    if (this.state.name &&
      this.state.productBrand &&
      this.state.productSerie &&
      this.state.productPartNumber &&
      this.state.motorBrand &&
      this.state.price
    ) {
      this.setState({ loading: true }, async () => {
        try {
          let savedFiles = null
          if (this.state.images && this.state.images.length > 0) {
            savedFiles = await saveFilesToCloud(this.state.images);
          }

          const data = {
            user: user._id,
            name: this.state.name,
            description: this.state.description,
            files: savedFiles,
            brand: this.state.productBrand,
            serie: this.state.productSerie,
            motorBrand: this.state.motorBrand,
            price: this.state.price,
            partNumber: this.state.productPartNumber,
          }
          await postData('/product/create-product', data);
          // console.log("signUp / response: ", response);
          this.showAlert(true, t('create-product-complete'), true)
          this.setState({
            loading: false,
            images: {},
            name: "",
            description: "",
            productPartNumber: "",
            motorbrand: "",
            productBrand: "",
            price: "",
            productSerie: "",
            showInputsErrors: false
          })
        } catch (e) {
          // console.log("createProduct / error: ", e);
          this.setState({ loading: false })
          const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
            ? e.response.data.error.message
            : null;
          const error = e && e.data && e.data.error ? e.data.error : null;
          const errorMessage = error && error.message
            ? error.message
            : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
          this.showAlert(true, networkError || errorMessage, false)
        }
      })
    } else {
      this.setState({ showInputsErrors: true });
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  onChange = async ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const crop = {
      unit: '%',
      aspect: 4 / 4,
      width: '100'
    };
    // const { user } = this.props;
    // console.log("files: ", this.state.images);
    return (
      <Translation>
        {t =>
          <div className={`${signinSignupContainer}`}>
            <Header />
            <div style={{ backgroundColor: FULL_WHITE }} className={`${itemsContainer}`}>
              <div className={`${item}`} style={{ paddingBottom: 20 }}>
                <h2 style={{ color: BLACK }}>{t("createProductTitle")}</h2>
                <h5 style={{ color: BLACK }}>{t("productImages")}</h5>
                {this.state.loading
                  ? <Loader color={BLACK} />
                  : <MultiImageInput
                    max={5}
                    images={this.state.images}
                    setImages={images => this.setState({ images: Object.values(images) })}
                    cropConfig={{ crop, ruleOfThirds: true }}
                    theme={{
                      background: BLACK,
                      outlineColor: WHITE,
                      textColor: WHITE,
                      buttonColor: '#ff0e1f',
                      modalColor: BLACK
                    }}
                    disabled={this.state.loading}
                  />}
                <Input
                  type="text"
                  label={t('createProductName')}
                  value={this.state.name}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='name'
                  className={`${inputStyles}`}
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                  isRequired
                />
                <Input
                  type="text"
                  label={t('createProductDescription')}
                  value={this.state.description}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='description'
                  className={`${inputStyles}`}
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                />
                <Input
                  type="text"
                  label={t('partNumber')}
                  onFocus={() => this.verifySession(t)}
                  value={this.state.productPartNumber}
                  onChange={this.handleInputChange}
                  name='productPartNumber'
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                  isRequired
                />
                <Input
                  type="number"
                  label={`${t('price')} ${t('dollar')}`}
                  value={this.state.price}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='price'
                  disabled={this.state.loading}
                  showErrors={this.state.showInputsErrors}
                  isRequired
                />
                <Input
                  type="text"
                  label={t('createProductMotorBrand')}
                  value={this.state.motorBrand}
                  onFocus={() => this.verifySession(t)}
                  onChange={this.handleInputChange}
                  name='motorBrand'
                  className={`${inputStyles}`}
                  showErrors={this.state.showInputsErrors}
                  disabled={this.state.loading}
                  isRequired
                />
                <div>
                  <Select
                    showErrors={this.state.showInputsErrors}
                    className={`${selectStyles}`}
                    onFocus={() => this.verifySession(t)}
                    name="productBrand"
                    value={this.state.productBrand}
                    disabled={this.state.loading}
                    onChange={this.onChange}
                  >
                    <option value="">{t('createProductBrand')}</option>
                    {motorsBrands.BRANDS.map(brand =>
                      <option value={brand}>{brand}</option>
                    )}
                  </Select>
                  {this.state.productBrand
                    ? <Select
                      showErrors={this.state.showInputsErrors}
                      className={`${selectStyles}`}
                      onFocus={() => this.verifySession(t)}
                      disabled={this.state.loading}
                      value={this.state.productSerie}
                      name="productSerie"
                      onChange={this.onChange}
                    >
                      <option value="">{t('createProductSerie')}</option>
                      {motorsBrands[this.state.productBrand].map(brand =>
                        <option value={brand}>{brand}</option>
                      )}
                    </Select>
                    : null}
                </div>
                <Button
                  loading={this.state.loading}
                  onClick={() => this.createProduct(t)}
                  disabled={this.state.loading}
                  content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30 }}>{t("createProductButton")}</p>}
                  style={{ backgroundColor: BLACK, marginTop: 10, minWidth: 100, minHeight: 38 }}
                  loaderColor={FULL_WHITE}
                />
              </div>
            </div>
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user
  } = state;
  return {
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProduct);
