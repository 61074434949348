import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import { css } from 'emotion';
import bgImage from "../../assets/dashboard/background-image.png";
import checkIcon from "../../assets/orders/check.svg";
import rejectIcon from "../../assets/orders/reject.svg";
import { BLACK, BLACK_OPACITY, BLACK_OPACITY2, FULL_WHITE, RED, WHITE, YELLOW } from '../../utils/color-palatte';
import HomeFooter from '../home-footer/HomeFooter';
import Button from '../../utils/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getData, postData, putData } from '../../fetch/services';
import moment from 'moment';
import Loader from '../../utils/Loader';
import Alert from '../../utils/Alert';
import searchIcon from "../../assets/icons/search-icon.svg";
import Input from '../../utils/Input';
import ModalComponent from '../../utils/Modal';
import thousandSeparator from '../../utils/thousandSeparator';
import { validateUser } from '../../utils/validateUserType';
import { Redirect } from "react-router-dom";
import defaultProductImage from "../../assets/icons/timing-belt.svg";
import { API_URL } from '../../config';
import SocketIOClient from 'socket.io-client';
import mp3Sound from '../../assets/pristine-609.mp3';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
display: flex;
`

const title = css`
margin: 0;
font-size: 50px
`

const cartContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}
`

const shoppingCartContentContainer = css`
min-height: 300px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
flex-wrap: wrap;
`

const orderButtonsContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
`

const tableContainer = css`
margin: 10px;
@media (max-width: 40em){
  width: 300px;
}
`

const productName = css`
@media (max-width: 40em){
margin-left: -40% !important;
}
`

const thText = css`
color: ${WHITE};
font-weight: bold;
margin: 10px;
@media (max-width: 40em){
  color: ${BLACK};
}
`

const loader = css`
margin-top: 50px;
margin-bottom: 50px;
`

const seeMoreButton = css`
color: ${WHITE};
margin: 0px;
padding: 8px;
font-size: 20px;
padding-left: 20px;
padding-right: 20px;
font-weight: bold;
`

const productButton = css`
color: ${WHITE};
margin: 0px;
padding: 8px;
font-size: 12px;
padding-left: 10px;
padding-right: 10px;
font-weight: bold;
`

const filtersContainer = css`
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: row;
border: ${BLACK_OPACITY} solid 1px;
border-radius: 3px;
margin: 10px;
width: 95%;
flex-wrap: wrap;
max-width: 1000px;
  `

const inputContainer = css`
display: flex;
flex-direction: row;
@media(max-width: 650px) {
  flex-wrap: wrap;
}
`

const searchInput = css`
margin: 10px;
  `

const searchInputContainer = css`
max-width: 380px;
min-width: 300px
`

const inputPictureStyle = css`
margin-right: 10px;
  `

const orderButton = css`
background-color: ${BLACK};
display: flex;
justify-content: center;
align-items: center;
height: 30px;
width: 30px;
margin: 5px;
border-radius: 5px;
cursor: pointer
  `

const orderButtonImage = css`
width: 15px;
height: 15px;
`

const productsModalContainer = css`
overflow-x: hidden;
overflow-y: scroll;
max-height: 700px;
@media(max-width: 650px) {
  height: 90vh;
}
`

const trStyles = css`
@media(max-width: 40em) {
  border: 1px solid #000 !important;
}
`

const previewImage = css`
width: 40px;
height: 40px;
`

const socket = SocketIOClient(API_URL, {
  transports: ['websocket'] // you need to explicitly tell it to use websockets
});

class Orders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orders: [],
      skipItems: 0,
      limitItems: 5,
      search: "",
      plusProductsNumber: 5,
      keepIncreasingCounter: false,
      bsValue: "",
      currency: ""
    }

    socket.on('refresh', () => {
      this.setState({
        loading: true,
        orders: [],
        skipItems: 0,
        limitItems: 5,
        search: "",
        plusProductsNumber: 5,
        keepIncreasingCounter: false,
        bsValue: "",
        currency: ""
      }, () => {
        const audio = new Audio(mp3Sound)
        audio.play()
        this.getOrders()
      })
    });
  }

  componentDidMount = () => {
    this.getOrders();
    window.scrollTo(0, 0)
  };

  onEndReached = () => {
    // console.log("onEndReached");
    const {
      skipItems,
      limitItems,
      plusProductsNumber,
      keepIncreasingCounter
    } = this.state;
    if (keepIncreasingCounter) {
      this.setState({
        skipItems: skipItems + plusProductsNumber,
        limitItems: limitItems + plusProductsNumber
      }, () => {
        this.getOrders();
      });
    } else {
      this.getOrders();
    }
  };

  fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getData(`/config/get-config`);
        // console.log("fetchConfig / response: ", response);
        const config = response.body

        this.setState({
          bsValue: config.bsValue,
          currency: config.currency,
        });
        resolve();
      } catch (e) {
        resolve(e);
        // console.log("fetchConfig / error: ", e)
        this.setState({ loading: false })
        const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
          ? e.response.data.error.message
          : null;
        const error = e && e.data && e.data.error ? e.data.error : null;
        const errorMessage = error && error.message && error.message[0] && error.message[0].msg
          ? error.message[0].msg
          : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
        this.showAlert(true, networkError || errorMessage, false)
      }
    })
  }

  getOrders = () => {
    return new Promise(async (resolve, reject) => {
      const { skipItems, limitItems, search, orders } = this.state;
      const { user } = this.props;
      this.setState({ loading: true }, async () => {
        try {
          await this.verifySession()
          await this.fetchConfig()
          const response = await getData(`/order/get-orders/?userId=${user ? user._id : ""}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}`);
          // console.log("getOrders / response: ", response);
          const newOrders = response.body;
          // console.log("getOrders / newOrders: ", newOrders);
          const finalOrders = orders
            .concat(newOrders)
            .filter((item, index, self) =>
              index === self.findIndex((t) => (
                t._id === item._id
              ))
            )
          // .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
          // console.log("getOrders / finalOrders: ", finalOrders);
          this.setState({
            loading: false,
            orders: finalOrders,
            keepIncreasingCounter: newOrders.length > 0
          });
          resolve();
        } catch (e) {
          this.setState({ loading: false })
          resolve(e);
          // console.log("getOrders / error: ", e)
          if (e !== 'session-expired') {
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        }
      })
    })
  }

  finishOrder = (orderId, status, t) => {
    return new Promise(async (resolve, reject) => {
      const { user } = this.props;
      this.setState({ loading: true }, async () => {
        try {
          await this.verifySession(t)
          const data = {
            status,
            userId: user._id,
            orderId
          }
          await putData(`/order/finish-order/`, data);
          // console.log("getOrders / response: ", response);
          // .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
          // console.log("getOrders / finalOrders: ", finalOrders);
          this.setState({
            loading: false,
          });
          this.onRefreshProducts();
          resolve();
        } catch (e) {
          reject(e);
          // console.log("getOrders / error: ", e)
          if (e !== 'session-expired') {
            this.setState({ loading: false })
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        }
      })
    })
  }

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  onRefreshProducts = () => {
    // const { saveUserPosts } = this.props;
    const { plusProductsNumber } = this.state;
    // saveUserPosts([]);
    this.setState({ skipItems: 0, limitItems: plusProductsNumber, orders: [], search: "" }, () => {
      this.getOrders();
    })
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.setState({
        orders: [],
        skipItems: 0,
        limitItems: 5,
      }, () => {
        this.getOrders();
      })
    }
  }

  validateUser = () => {
    const { user } = this.props;
    if (validateUser(["seller", "products-admin"], user.userType)) {
      return <Redirect to="/home" />
    }
  };

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { orders, bsValue, currency } = this.state;
    const { user } = this.props;
    // console.log("orders: ", orders);
    return (
      <Translation>
        {t =>
          <div className={`${cartContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("ordersTitle")}</h1>
                </span>
              </span>
            </div>
            <div className={`${shoppingCartContentContainer}`} style={{ backgroundColor: FULL_WHITE }}>
              <div className={`${filtersContainer}`}>
                <div className={`${inputContainer}`}>
                  <Input
                    type="text"
                    className={`${searchInput}`}
                    containerStyle={`${searchInputContainer}`}
                    pictureStyle={`${inputPictureStyle}`}
                    placeholder={t('searchOrdersInputPlaceholder')}
                    value={this.state.search}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleInputChange}
                    name='search'
                    rightPicture={<img
                      onClick={() => {
                        this.setState({
                          orders: [],
                          skipItems: 0,
                          limitItems: 5,
                        }, () => {
                          this.getOrders();
                        })
                      }}
                      src={searchIcon}
                      style={{
                        width: 37,
                        height: 20,
                        cursor: 'pointer',
                        backgroundColor: BLACK,
                        padding: 6,
                        marginRight: 10,
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                      alt="search-button"
                    />}
                  />
                  <Button
                    onClick={() => this.onRefreshProducts()}
                    content={<p style={{ color: WHITE, margin: 0, fontSize: 15, fontWeight: 'bold' }}>{t("seeAllProductsProducts")}</p>}
                    style={{ backgroundColor: BLACK, height: 30, minWidth: 90, margin: 10, minHeight: 38 }}
                  />
                </div>
              </div>
              <div className={`${tableContainer}`}>
                {orders && orders.length > 0
                  ? <Table style={{ borderSpacing: 0 }}>
                    <Thead style={{ backgroundColor: BLACK, pading: 10 }}>
                      <Tr>
                        <Th><p className={`${thText}`}>{t('ordersNr')}</p></Th>
                        <Th><p className={`${thText}`}>{t('ordersUser')}</p></Th>
                        <Th><p className={`${thText}`}>{t('orderProducts')}</p></Th>
                        <Th><p className={`${thText}`}>{t('orderDate')}</p></Th>
                        <Th><p className={`${thText}`}>{t('orderPrice')}</p></Th>
                        <Th><p className={`${thText}`}>{t('orderStatus')}</p></Th>
                        {(user && validateUser(["super-admin", "admin", "employer"], user.userType)) && <Th></Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orders.map(order =>
                        <Tr className={`${trStyles}`} key={order._id}>
                          <Td>
                            {<p style={{ color: BLACK_OPACITY2, margin: 10 }} className={`${productName}`}>
                              {order.orderNumber || order._id}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ color: BLACK_OPACITY2, margin: 10 }} className={`${productName}`}>
                              {order.user ? (order.user.email || order.user.name) : t('not-found')}
                            </p>}
                          </Td>
                          <Td>
                            <Button
                              onClick={() => {
                                this.setState({ selectedProducts: order.products }, () => {
                                  this.setState({ isModalOpen: true })
                                })
                              }}
                              content={<p className={`${productButton}`}>
                                {t("orderProductsButton")}
                              </p>}
                              style={{ backgroundColor: BLACK, margin: 10 }}
                            />
                          </Td>
                          <Td>
                            {<p style={{ color: BLACK_OPACITY2, margin: 10 }} className={`${productName}`}>
                              {order.creationDate ? moment(order.creationDate).format('YYYY-MM-DD hh:mm A') : t('notDefined')}
                            </p>}
                          </Td>
                          <Td>
                            {<p style={{ color: BLACK, margin: 10, fontWeight: 'bold' }} className={`${productName}`}>
                              {`${thousandSeparator(currency === "bs" ? order.amount * Number(bsValue) : order.amount)}${currency === "bs" ? "Bs" : "$"}`}
                            </p>}
                          </Td>
                          <Td>
                            {<p
                              style={{
                                color: order.status === "APPROVED" ? 'green' : order.status === "REJECTED" ? RED : YELLOW,
                                margin: 10,
                                fontWeight: 'bold'
                              }}
                              className={`${productName}`}
                            >
                              {order.status === "APPROVED"
                                ? t("approvedStatus")
                                : order.status === "REJECTED"
                                  ? t("rejectedStatus")
                                  : t("pendingStatus")}
                            </p>}
                          </Td>
                          {(user && validateUser(["super-admin", "admin", "employer"], user.userType) && (order.status !== "APPROVED" && order.status !== "REJECTED")) && <Td>
                            <div className={`${orderButtonsContainer}`}>
                              <div className={`${orderButton}`}>
                                <img onClick={() => this.finishOrder(order._id, "APPROVED", t)} src={checkIcon} style={{ marginTop: 3 }} className={`${orderButtonImage}`} alt="approve order button" />
                              </div>
                              <div className={`${orderButton}`}>
                                <img onClick={() => this.finishOrder(order._id, "REJECTED", t)} src={rejectIcon} className={`${orderButtonImage}`} alt="reject order button" />
                              </div>
                            </div>
                          </Td>}
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  : !this.state.loading
                    ? this.state.search ? <h2 style={{ color: BLACK, margin: 50 }}>{t('noResultsOrders')}</h2> : <h2 style={{ color: BLACK, margin: 50 }}>{t('ordersEmpty')}</h2>
                    : <Loader color={BLACK} className={`${loader}`} />}
              </div>
              {this.state.keepIncreasingCounter && !this.state.loading
                ? <Button
                  onClick={() => this.onEndReached()}
                  content={<p className={`${seeMoreButton}`}>
                    {t("seeMoreProducts")}
                  </p>}
                  style={{ backgroundColor: BLACK, marginTop: 20, minHeight: 38, marginBottom: 20 }}
                />
                : !this.state.loading
                  ? orders && orders.length > 0 ? <h5 style={{ color: BLACK, margin: 20, marginTop: 50 }}>{t('noMoreOrders')}</h5> : null
                  : <Loader color={BLACK} className={`${loader}`} />}
            </div>
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
            <ModalComponent
              isOpen={this.state.isModalOpen}
              onRequestClose={() => this.setState({ isModalOpen: false })}
            >
              <div className={`${productsModalContainer}`}>
                <div className={`${tableContainer}`}>
                  {this.state.selectedProducts && this.state.selectedProducts.length > 0
                    ? <Table style={{ borderSpacing: 0 }}>
                      <Thead style={{ backgroundColor: BLACK, pading: 10 }}>
                        <Tr>
                          <Th><p className={`${thText}`}>{t('tableProductPartNumber')}</p></Th>
                          <Th><p className={`${thText}`}>{t('tableProductName')}</p></Th>
                          <Th><p className={`${thText}`}>{t('tableProductPicture')}</p></Th>
                          <Th><p className={`${thText}`}>{t('tableProductPrice')}</p></Th>
                          <Th><p className={`${thText}`}>{t('tableProductQuantity')}</p></Th>
                          <Th><p className={`${thText}`}>{t('tableProductTotal')}</p></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {this.state.selectedProducts.map(product => {
                          const totalPrice = product.price * (product.quantitySelected || 1)
                          return (
                            <Tr className={`${trStyles}`} key={product._id}>
                              <Td>
                                <p style={{ color: BLACK, margin: 10, textAlign: 'center' }}>
                                  {product.partNumber}
                                </p>
                              </Td>
                              <Td>
                                <p style={{ color: BLACK, margin: 10 }}>
                                  {product.name}
                                </p>
                              </Td>
                              <Td>
                                <div style={{ textAlign: 'center' }}>
                                  <img
                                    alt=""
                                    src={product.files && product.files.length > 0 ? product.files[0] : defaultProductImage}
                                    className={`${previewImage}`}
                                  />
                                </div>
                              </Td>
                              <Td>
                                <p style={{ color: BLACK, margin: 10, textAlign: 'center' }}>
                                  {`${thousandSeparator(currency === "bs" ? product.price * Number(bsValue) : product.price)}${currency === "bs" ? "Bs" : "$"}`}
                                </p>
                              </Td>
                              <Td>
                                <p style={{ color: BLACK, margin: 10, textAlign: 'center' }}>
                                  {`${thousandSeparator(product.quantitySelected || 1)}`}
                                </p>
                              </Td>
                              <Td>
                                <p style={{ color: BLACK, margin: 10, textAlign: 'center' }}>
                                  {`${thousandSeparator(currency === "bs" ? totalPrice * Number(bsValue) : totalPrice)}${currency === "bs" ? "Bs" : "$"}`}
                                </p>
                              </Td>
                            </Tr>
                          )
                        }
                        )}
                      </Tbody>
                    </Table>
                    : this.state.fetching
                      ? <Loader color={BLACK} className={`${loader}`} />
                      : (this.state.search || this.state.byBrand || this.state.bySerie)
                        ? <h3 style={{ color: BLACK, margin: 20, marginTop: 50 }}>{t('noResulst')}</h3>
                        : null}
                </div>
              </div>
            </ModalComponent>
            {this.validateUser()}
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
  } = state;
  return {
    user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
