import React from 'react';
import { css } from 'emotion';
import { BLACK, RED, WHITE } from './color-palatte';
import { Translation } from 'react-i18next';
import ViewIcon from '../assets/icons/View.svg';
import HidenIcon from '../assets/icons/Hiden.svg';

const Input = (props) => {
  const language =
    localStorage.getItem('language') === 'us'
      ? 'en'
      : localStorage.getItem('language') || 'es';

  const input = css`
    background-color: ${WHITE};
    // min-width: 100%;
  `;
  const labelStyles = css``;

  const container = css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: ${props.width || '100%'};
  `;

  const inputContainer = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    // justify-content: left;
    position: relative;
    box-sizing: border-box;
    // @media (max-width: 390px) {
    //   padding-right: 50px;
    // }
  `;

  const imageInput = css`
    width: 20px;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 20px;
  `;

  const pictureStyles = css`
    width: 40px;
    height: 30px;
    cursor: pointer;
  `;

  const redBorder = css`
    border-bottom: 1px solid ${RED};
  `;

  const {
    className,
    placeholder,
    value,
    onChange,
    name,
    label,
    labelStyle,
    pictureStyle,
    containerStyle,
    type,
    picture,
    isRequired,
    optional,
    rightPicture,
    showErrors,
    onKeyPress,
    disabled,
    onFocus,
    imgClick,
    auth
  } = props;
  return (
    <Translation>
      {(t) => (
        <div className={`${container} ${containerStyle}`}>
          {label && (
            <span
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                className={`${labelStyle} ${labelStyles}`}
                style={{ marginRight: 5, color: BLACK, fontWeight: 'bold' }}
              >
                {label}
              </p>
              {isRequired && !optional ? (
                <span style={{ color: RED }}>*</span>
              ) : null}
              {!isRequired && optional ? (
                <p style={{ color: BLACK }}>{`(${t('optional')})`}</p>
              ) : null}
            </span>
          )}
          <div className={`${inputContainer}`}>
            {picture && (
              <img
                src={picture}
                className={`${pictureStyles} ${pictureStyle}`}
                alt=''
              />
            )}
            <input
              disabled={disabled}
              className={`${input} ${className} ${showErrors && isRequired && !value ? redBorder : ''
                }`}
              placeholder={placeholder}
              onFocus={onFocus}
              type={type || 'text'}
              value={value}
              onKeyPress={onKeyPress}
              onChange={onChange}
              name={name}
            />
            {rightPicture
              ? rightPicture
              : auth
                ? <img
                  src={type === 'password' ? ViewIcon : HidenIcon}
                  alt='Hiden'
                  onClick={imgClick}
                  className={`${imageInput}`}
                  title={
                    language === 'en'
                      ? type === 'password'
                        ? 'Show Password'
                        : 'Hidden Password'
                      : type === 'password'
                        ? 'Mostrar Contraseña'
                        : 'Ocultar Contraseña'
                  }
                />
                : null}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default Input;
