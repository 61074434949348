import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import { css } from 'emotion';
import removeIcon from "../../assets/icons/remove-icon.svg";
// import PdfIcon from "../../assets/cart/pdf.svg";
import bgImage from "../../assets/products/products-bg.png";
import headerImage from "../../assets/cart/cart-piece.png";
import { BLACK, BLACK_OPACITY2, FULL_WHITE, WHITE, YELLOW } from '../../utils/color-palatte';
import ContactUs from '../contact-us/ContactUs';
import HomeFooter from '../home-footer/HomeFooter';
import Button from '../../utils/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { API_URL, quantitiesMax } from "../../config";
import { getData, postData } from '../../fetch/services';
import Alert from '../../utils/Alert';
import thousandSeparator from '../../utils/thousandSeparator';
import range from '../../utils/range';
import Input from '../../utils/Input';
import { emailsRegex } from '../../utils/regex';
import { validateUser } from '../../utils/validateUserType';
import generatePDF from '../../utils/generatePdf';
import { Link } from 'react-router-dom';
import defaultProductImage from "../../assets/icons/timing-belt.svg";
import SocketIOClient from 'socket.io-client';
import ProductDescription from '../../utils/ProductDescription';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"



const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
display: flex;
`

const title = css`
margin: 0;
font-size: 50px
`

const cartContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}
`

const shoppingCartContentContainer = css`
min-height: 300px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
`

const cartInfoContainer = css`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
padding: 10px;
margin: 10px;
position: relative;
`

const tableContainer = css`
margin: 10px;
@media (max-width: 40em){
  width: 300px;
}
`

const tableItems = css`
@media (max-width: 40em){
  text-align: right;
}
`

const productImage = css`
width: 40px;
height: 40px;
margin: 10px;
`

const selectStyles = css`
margin: 10px;
  `

const priceStyles = css`
color: ${BLACK};
font-weight: bold;
margin: 10px;
`

const thText = css`
color: ${WHITE};
font-weight: bold;
margin: 10px;
@media (max-width: 40em){
  color: ${BLACK};
}
`

const removeIconStyles = css`
width: 20px;
height: 20px;
cursor: pointer;
`

// const pdfIconStyles = css`
// width: 30px;
// height: 30px;
// cursor: pointer;
// position: absolute;
// top: 10px;
// right: 10px
// `

const headerImageStyle = css`
width: 200px;
height: 200px;
position: absolute;
right: 10%;
@media(max-width: 1050px) {
  width: 150px;
  height: 150px;
  right: 20px;
}
@media(max-width: 925px) {
  display: none
}
`

const inputStyles = css`
width: 100%;
background-color: ${FULL_WHITE} !important
`

const socket = SocketIOClient(API_URL, {
  transports: ['websocket'] // you need to explicitly tell it to use websockets
});

class Cart extends React.Component {

  state = {
    totalAmount: 0,
    orderLoading: false,
    thirdPerson: "",
    thirdPersonError: false
  };

  componentDidMount = async () => {
    this.verifySession()
    await this.updateTotalAmount();
    window.scrollTo(0, 0)
  };

  onChange = async ({ target: { name, value } }, product) => {
    const { editProduct } = this.props;
    let productCopy = product;
    editProduct(product._id, { ...productCopy, quantitySelected: value })
    setTimeout(() => {
      this.updateTotalAmount()
    }, 500)
  }

  updateTotalAmount = async () => {
    await this.fetchConfig()
    let counter = 0
    if (this.props.cart && this.props.cart.length > 0) {
      this.props.cart.forEach(product => {
        counter = counter + ((product.quantitySelected || 1) * product.price)
      })
    }

    this.setState({ totalAmount: counter })
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  deliverOrder = (t) => {
    const { cart, user } = this.props;
    const { bsValue, currency } = this.state;
    // console.log("cart: ", cart);
    const totalAmount = `${thousandSeparator(currency === "bs" ? this.state.totalAmount * Number(bsValue) : this.state.totalAmount)}${currency === "bs" ? "Bs" : "$"}`
    if (this.state.thirdPerson && !this.state.thirdPerson.match(emailsRegex)) {
      this.setState({ thirdPersonError: true })
      this.showAlert(true, t('invalidThirdPersonEmail'), false)
      return false;
    }

    this.setState({ orderLoading: true }, async () => {
      try {
        await this.verifySession()
        const data = {
          user: this.props.user._id,
          products: this.props.cart,
          amount: this.state.totalAmount,
          thirdPerson: this.state.thirdPerson
        }
        const orderCreated = await postData('/order/create-order', data);
        socket.emit('on-order');
        // console.log("deliverOrder / orderCreated: ", orderCreated);
        this.showAlert(true, t('order-delivered'), true)
        this.setState({
          orderLoading: false,
        })
        generatePDF(cart, totalAmount, t, currency, bsValue, orderCreated.body, this.state.thirdPerson ? user : null)
        this.props.clearCart();
      } catch (e) {
        // console.log("deliverOrder / error: ", e);
        this.setState({ orderLoading: false })
        if (e !== "session-expired") {
          const error = e && e.data && e.data.error ? e.data.error : null;
          const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
            ? e.response.data.error.message
            : null;
          // console.log("networkError: ", networkError);
          const invalidThirdPersonEmail = error && error.code === "invalid-thirdperson-email" ? error.message : null;
          const errorMessage = error && error.message && error.message[0] && error.message[0].msg
            ? error.message[0].msg
            : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
          // console.log("errorMessage: ", errorMessage);
          this.showAlert(true, invalidThirdPersonEmail || networkError || errorMessage, false)
        }
      }
    })
  };

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getData(`/config/get-config`);
        // console.log("fetchConfig / response: ", response);
        const config = response.body

        this.setState({
          bsValue: config.bsValue,
          currency: config.currency,
        });
        resolve();
      } catch (e) {
        resolve(e);
        // console.log("fetchConfig / error: ", e)
        this.setState({ loading: false })
        const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
          ? e.response.data.error.message
          : null;
        const error = e && e.data && e.data.error ? e.data.error : null;
        const errorMessage = error && error.message && error.message[0] && error.message[0].msg
          ? error.message[0].msg
          : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
        this.showAlert(true, networkError || errorMessage, false)
      }
    })
  }

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { cart, removeProductFromCart, user } = this.props;
    const { bsValue, currency } = this.state;
    // console.log("cart: ", cart);
    // console.log("this.state.totalAmount: ", this.state.totalAmount);
    const totalAmount = `${thousandSeparator(currency === "bs" ? this.state.totalAmount.toFixed(2) * Number(bsValue) : this.state.totalAmount.toFixed(2))}${currency === "bs" ? "Bs" : "$"}`
    return (
      <Translation>
        {t =>
          <div className={`${cartContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("cartTitle1")}</h1>
                </span>
                <span style={{ marginLeft: 5 }}>
                  <h1 className={`${title}`} style={{ color: YELLOW }}>{t("cartTitle2")}</h1>
                </span>
                <img className={`${headerImageStyle}`} src={headerImage} alt="" />
              </span>
            </div>
            <div className={`${shoppingCartContentContainer}`} style={{ backgroundColor: FULL_WHITE }}>
              {!this.state.orderLoading
                ? <div className={`${tableContainer}`}>
                  <Table style={{ borderSpacing: 0 }}>
                    <Thead style={{ backgroundColor: BLACK, pading: 10 }}>
                      <Tr>
                        <Th><p className={`${thText}`}>{t('cartProductTitle')}</p></Th>
                        <Th><p className={`${thText}`}>{t('cartProductPreview')}</p></Th>
                        <Th><p className={`${thText}`}>{t('cartPriceTitle')}</p></Th>
                        <Th><p className={`${thText}`}>{t('cartQuantityTitle')}</p></Th>
                        <Th><p className={`${thText}`}>{t('cartTotalTitle')}</p></Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cart && cart.length > 0
                        ? cart.map((product, i) => {
                          const totalAmount = product.quantitySelected ? (Number(product.quantitySelected) * product.price).toFixed(2) : product.price
                          return (
                            <Tr key={i}>
                              <Td>
                                <ProductDescription 
                                style={{ color: BLACK_OPACITY2, margin: 10 }} className={`${tableItems}`}
                                description={product.name}
                                />
                                {/* <p style={{ color: BLACK_OPACITY2, margin: 10 }} className={`${tableItems}`}>
                                  {product.name}
                                </p> */}
                              </Td>
                              <Td>
                                <div className={`${tableItems}`}>
                                  <img src={product.files && product.files.length > 0 ? product.files[0] : defaultProductImage} className={`${productImage}`} alt={``} />
                                </div>
                              </Td>
                              <Td>
                                <p className={`${priceStyles} ${tableItems}`}>{`${thousandSeparator(currency === "bs" ? product.price * bsValue : product.price)}${currency === "bs" ? "Bs" : "$"}`}</p>
                              </Td>
                              <Td>
                                <div className={`${tableItems}`}>
                                  <select
                                    style={{ margin: 10 }}
                                    className={`${selectStyles}`}
                                    value={product.quantitySelected}
                                    onChange={(e) => this.onChange(e, product)}
                                  >
                                    {range(quantitiesMax).map((e, i) =>
                                      <option key={i} value={i + 1}>{i + 1}</option>
                                    )}
                                  </select>
                                </div>
                              </Td>
                              <Td>
                                <p className={`${priceStyles} ${tableItems}`}>
                                  {`${currency === "bs" ? thousandSeparator(totalAmount * Number(bsValue)) : thousandSeparator(totalAmount)}${currency === "bs" ? "Bs" : "$"}`}
                                </p>
                              </Td>
                              <Td>
                                <div className={`${tableItems}`}>
                                  <img
                                    alt=''
                                    onClick={() => {
                                      removeProductFromCart(product)
                                      setTimeout(() => {
                                        this.updateTotalAmount()
                                      }, 500)
                                    }} src={removeIcon} className={`${removeIconStyles}`} />
                                </div>
                              </Td>
                            </Tr>
                          )
                        })
                        : <h2 style={{ color: BLACK, margin: 50 }}>{t('cartEmpty')}</h2>}
                    </Tbody>
                  </Table>
                </div>
                : null}
              <div className={`${cartInfoContainer}`} style={{ backgroundColor: WHITE }}>
                {/* <img
                    alt=''
                    onClick={() => generatePDF(cart, totalAmount, t, currency, bsValue)}
                    src={PdfIcon}
                    className={`${pdfIconStyles}`}
                  /> */}
                <h3 style={{ color: BLACK_OPACITY2, margin: 0 }}>{t("cartTotal")}</h3>
                <p style={{ color: BLACK, fontWeight: 'bold', margin: 0, marginTop: 20 }}>{totalAmount}</p>
                {validateUser(["super-admin", "admin", "seller", "employer"], user.userType) && <Input
                  type="text"
                  label={t('OrdderToThirdPerson')}
                  placeholder={t('thirdPerson')}
                  value={this.state.thirdPerson}
                  onChange={this.handleInputChange}
                  name='thirdPerson'
                  className={`${inputStyles}`}
                  showErrors={this.state.thirdPersonError}
                  disabled={this.state.orderLoading}
                />}
                {(cart && cart.length > 0) && <Button
                  loading={this.state.orderLoading}
                  onClick={() => this.deliverOrder(t)}
                  disabled={this.state.orderLoading}
                  content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30, fontWeight: 'bold' }}>{t("completeBuy")}</p>}
                  style={{ backgroundColor: BLACK, marginTop: 20, minWidth: '100%', minHeight: 38 }}
                  loaderColor={FULL_WHITE}
                />}
                {!this.state.orderLoading && <Link
                  style={{ minWidth: '100%' }}
                  to="/products">
                  <Button
                    loading={this.state.orderLoading}
                    onClick={() => null}
                    disabled={this.state.orderLoading}
                    content={<p style={{ color: BLACK, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30, fontWeight: 'bold' }}>{t("keepBuying")}</p>}
                    style={{ backgroundColor: YELLOW, marginTop: 10, minWidth: '100%', minHeight: 38 }}
                    loaderColor={FULL_WHITE}
                  />
                </Link>}
              </div>
            </div>
            <ContactUs to={'/home'} />
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
    removeProductFromCart: bindActionCreators(actions.removeProductFromCart, dispatch),
    editProduct: bindActionCreators(actions.editProduct, dispatch),
    clearCart: bindActionCreators(actions.clearCart, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
    cart
  } = state;
  return {
    user,
    cart
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
