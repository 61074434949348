import { getData } from "../fetch/services";

const updateUser = async (saveUser) => {
  const userId = localStorage.getItem('userId')
  if (userId) {
    const response = await getData(`/user/get-user/${userId}`);
    // console.log("updateUser / response: ", response);
    saveUser(response.body[0])
  }
}

export default updateUser;