export default {
  translation: {
    "home": "HOME",
    "products": "PRODUCTS",
    "we": "ABOUT US",
    "loginRegisterButton": "REGISTER",
    "loginButton": "SIGN IN",
    "contactUs": "Contact us",
    "dashboardTitle": "MORE THAN 25 YEARS",
    "dashboardTitle15": "DISTRIBUTING",
    "dashboardTitle2": "ENGINE PARTS",
    "dashboardTitle16": "WHOLESALE",
    "messageInput": "Message",
    "emailInput": "Email",
    "nameInput": "Full name",
    "contactButtonText": "Contact",
    "contactButtonText2": "Send",
    "homeProductsOurText": "OUR",
    "homeProductsProductsText": "PRODUCTS",
    "weSubTitle": "We",
    "homeProductsTitleContent": "We have a larger quantity of spare parts and parts for diesel engines, offering the best options.",
    "weTitle": "ABOUT US",
    "weContent": "We have more than 25 years of experience providing extensive engine parts and fuel injection parts coverage, with the best replacement market options. Our mission is to provide high-quality products, prompt shipment, and competitive prices. Our values have earned nationwide customer loyalty, and we are confident that our commitment will strengthen your business.",
    "addToCart": "Add to cart",
    "moreDetails": "More details",
    "seeAllProducts": "See all",
    "searchProductsInputPlaceholder": "Search piece",
    "footerLastInfo": "2020 Mayor Diesel",
    "email": "Email",
    "footerMenuTitle": "Menú",
    "footerHome": "Home",
    "footerProducts": "Products",
    "footerWe": "About us",
    "footerSocialMediaTitle": "Social Media",
    "footerFacebook": "Facebook",
    "footerTwitter": "Twitter",
    "contactUsTitle": "CONTACT US",
    "contactUsClickHere": "Click here",
    "loginTitle": "SIGN IN",
    "signupTitle": "SIGN UP",
    "signinEmail": "Email",
    "signinPassword": "Password",
    "forgetPassword": "Forget your password?",
    "forgetPassClickHere": "Click here",
    "optional": "Optional",
    "signupName": "Full name",
    "signupPhone": "Phone number",
    "filterByBrand": "By motor",
    "filterBySerie": "By serie",
    "seeMoreProducts": "See more",
    "productsSectionOurText": "OUR",
    "productsSectionProductsText": "PRODUCTS",
    "contactEmailSended": "Contact email sended.",
    "internalError": "Internal server error, please try again.",
    "emailIsInvalid": "Invalid email.",
    "email-not-verified": "Correo no verificado, presiona ok para reenviar correo de verificacion.",
    "email-verification-sended": "Correo de verificacion enviado a",
    "signup-complete": "You've sign up successfully.",
    "cartTitle1": "SHOPPING",
    "cartTitle2": "CART",
    "cartTotal": "CART TOTAL AMOUNT",
    "completeBuy": "FINISH BUY",
    "cartProductTitle": "Product",
    "cartPriceTitle": "Price",
    "cartQuantityTitle": "Quantity",
    "cartTotalTitle": "Total",
    "createProductTitle": "CREATE PRODUCT",
    "createProductName": "Description",
    "createProductDescription": "Details",
    "partNumber": "Part number",
    "createProductButton": "CREATE PRODUCT",
    "createProductBrand": "Motor type",
    "createProductSerie": "Serie",
    "createProductQuantity": "Product Quantity",
    "create-product-complete": "Product created.",
    "noResulst": "No products found.",
    "removeFromCart": "Remove from cart.",
    "cartEmpty": "No products yet.",
    "sureToRemoveProduct": "Are you sure to delete the product:",
    "noMoreProducts": "No more products.",
    "seeAllProductsProducts": "See all",
    "resetEmail": "Email",
    "resetPasswordTitle": "RESET PASSWORD",
    "resetPasswordButton": "SEND",
    "invalidEmail": "Invalid email.",
    "pleaseCheckEmail": "Check your email please.",
    "cannotValidateToken": 'Cannot validate token, please generate another one.',
    "missingToken": 'Missing token.',
    "newPassword": 'New password',
    "changePasswordButton": 'CHANGE PASSWORD',
    "passwordChanged": 'Password changed successfully.',
    "contactUsCompleteTitle": 'CONTACT US',
    "contactUsCompleteTitle2": 'HOW CAN WE HELP YOU?',
    "weTitle1": 'MEET',
    "weTitle2": 'US',
    "wePhoneTitle": 'PHONE',
    "weEmailTitle": 'E-MAIL',
    "weDirectionTitle": 'ADDRESS',
    "weAnotherTitle": 'SCHEDULE',
    "order-delivered": 'We are reviewing your order, you will be contacted as soon as possible, thank you very much.',
    "productDetailsBrand": 'MOTOR',
    "productDetailsSerie": 'SERIE',
    "productImages": 'PRODUCT IMAGES',
    "titleBrands": 'ALLIED',
    "titleAlliedBrands": 'BRANDS',
    "contactsEmpty": 'No contacts yet.',
    "contactsName": 'Name',
    "contactsEmail": 'Email',
    "contactsDate": 'Date',
    "contactsMessage": 'Message',
    "contactsTitle": 'Contacts',
    "contactsButton": 'CONTACTS',
    "noMoreContacts": 'No more contacts.',
    "noResultsContacts": 'No contacts found.',
    "ordersButton": 'ORDERS',
    "allOrdersButton": 'ORDERS',
    "ordersTitle": 'ORDERS',
    "ordersNr": 'ORDER',
    "orderProducts": 'ORDER PRODUCTS',
    "orderDate": 'DATE',
    "orderStatus": 'STATUS',
    "orderPrice": 'AMOUNT',
    "orderProductsButton": 'PRODUCTS',
    "noResultsOrders": 'No orders found.',
    "ordersEmpty": 'No orders yet.',
    "noMoreOrders": 'No more orders.',
    "adminText": '(ADMIN)',
    "approvedStatus": 'APPROVED',
    "rejectedStatus": 'REJECTED',
    "pendingStatus": 'IN PROCESS',
    "footerOrdersButton": 'Orders',
    "footerContactsButton": 'Contacts',
    "userStatsButton": 'STATS',
    "userName": 'NAME',
    "userEmail": 'EMAIL',
    "userPhone": 'PHONE',
    "noResultsUsers": 'No users found.',
    "usersEmpty": 'No users yet.',
    "noMoreUsers": 'No more users.',
    "usersHeaderButton": 'USERS',
    "footerUsersButton": 'Users',
    "userCreated": 'CREATED',
    "usersTitle": 'USERS',
    "searchUsersInputPlaceholder": 'Search users.',
    "searchOrdersInputPlaceholder": 'Search orders.',
    "searchContactsInputPlaceholder": 'Search contacts.',
    "statsTitle": 'USER STATS',
    "searchStatsInputPlaceholder": 'Search stats.',
    "statType": 'TYPE',
    "statProduct": 'PRODUCT',
    "statDate": 'DATE',
    "noResultsStats": 'No stats found.',
    "statsEmpty": 'No stats yet.',
    "noMoreStats": 'No more stats.',
    "userStatsEmail": 'Email',
    "userStatsName": 'Name',
    "userStatsPhone": 'Phone',
    "userStatsUserTitle": 'User',
    "notDefined": 'Not defined',
    "statTypeView": 'View',
    "statTypeLogin": 'Login',
    "statTypeLogout": 'Logout',
    "userActivities": 'ACTIVITIES',
    "editProductTitle": 'EDIT PRODUCT',
    "saveProductButton": 'SAVE',
    "productEdited": 'Product edited.',
    "favouritesTitle1": 'MY',
    "favouritesTitle2": 'FAVOURITES',
    "footerOFavouritesButton": 'Favourites',
    "noFavouritesYet": 'No favourites yet.',
    "productDetailsPartNumber": 'PART NUMBER',
    "tableProductPartNumber": 'PART NUMBER',
    "tableProductName": 'DESCRIPTION',
    "tableProductBrand": 'BRAND',
    "tableProductPicture": 'DETAILS',
    "cartProductPreview": 'DETAILS',
    "ordersUser": 'USER',
    "tableProductPrice": 'PRICE',
    "tableProductQuantity": 'QUANTITY',
    "tableProductTotal": 'TOTAL',
    "dollar": '(USD)',
    "createProductMotorBrand": 'Brand',
    "productDetailsMotorBrand": 'BRAND',
    "super-admin": 'SUPER ADMIN',
    "admin": 'ADMIN JR',
    "employer": 'EMPLOYER',
    "seller": 'SELLER',
    "client": 'CLIENT',
    "products-admin": 'PRODUCTS ADMIN',
    "configurationHeaderButton": 'CONFIGURATION',
    "configurationTitle": 'CONFIGURATION',
    "footerConfigurationButton": 'Configuration',
    "bsValue": 'Bolivares (Bs) value',
    "editConfigCurrency": 'Currency',
    "currencyLabel": 'Currency',
    "configEdited": 'Configuration edited.',
    "thirdPerson": 'User email',
    "OrdderToThirdPerson": 'Create user order:',
    "invalidThirdPersonEmail": 'Invalid user email.',
    "userTypeTable": 'User type',
    "order": 'order',
    "contactUsContent": 'How can we help you?',
    "phone": 'Phone',
    "orderThirdPerson": "Created by",
    "orderUser": "Client",
    "weClientServiceTitle": "CUSTOMER SERVICE",
    "weClientServiceContent": "Our highly trained staff, with extensive industry experience, is always there to process orders, track shipments, quote the correct parts, and inform you of product availability.",
    "weProductsTitle": "OUR PRODUCTS",
    "weProductsContent": "We offer the widest coverage of replacement engine parts and injection components in the market.",
    "weFoEnginesTitle": "FOR ENGINES",
    "weDotText1": "Heavy Duty Trucks",
    "weDotText2": "Mining and construction equipment",
    "weDotText3": "Agricultural Equipment",
    "weDotText4": "Marine Application",
    "weDotText5": "Energy generation",
    "weDotText6": "Natural gas",
    "weSecondTitle": "SPARE PARTS FOR DIESEL ENGINES AND NATURAL GAS ENGINES",
    "weSecondDotText1": "Pistons / Cylinder Kits",
    "weSecondDotText2": "Complete Repair Kits",
    "weSecondDotText3": "Gasket or Gasket Sets",
    "weSecondDotText4": "Oil Retainers",
    "weSecondDotText5": "Bearings and Bushings",
    "weSecondDotText6": "Fuel Systems",
    "weSecondDotText7": "Lubrication system",
    "weSecondDotText8": "Cooling system",
    "weThirdTitle": "PARTS FOR TRUCKS AND TRAILERS",
    "weThirdDotText1": "Motor",
    "weThirdDotText2": "Clutch",
    "weThirdDotText3": "Powertrain",
    "weThirdDotText4": "Suspension",
    "weThirdDotText5": "Cab and Chassis",
    "weThirdDotText6": "Air and electricity",
    "weGauaranteeTitle": "GUARANTEE",
    "weGauaranteeContent": "All products sold by www.mayordiesel.com have a factory warranty that is passed on to the buyer. A written request is required by us before returning any merchandise covered by this warranty. PLEASE CHECK THE LABELS OR GUARANTEE PAPERS OF ANY ISSUE SPECIFIC ITEM TO GUARANTEE RETURN.",
    "weDeliveryTitle": "SHIPPING INSTRUCTIONS",
    "weDeliveryContent": "Customers must provide us with shipping instructions. Otherwise, we will use our criteria in the selection of carrier or route.",
    "weTermsConditionsTitle": "TERMS AND CONDITIONS",
    "weTermsConditionsContent": "Prepaid by bank transfer Credit conditions may be available to qualified customers. Please ask.",
    "help": "HELP",
    "helpTitle1": "SECTION OF",
    "helpTitle2": "HELP",
    "help2": "Help",
    "helpContactTitle": "CONTACTING US IS THE FIRST STEP",
    "helpContactContent": "TO FIND THE BEST OPTION",
    "helpTitle": "JOIN OUR NETWORK OF ALLIES",
    "helpTitleContent": "And find your diesel spare parts wholesale in venezuela",
    "helpVideoContent": "Play the video and know the process you must follow to register, and formalize your purchase process.",
    "footerExtraInfo": "More than 25 years being suppliers of diesel engine parts.",
    "weMarketSegmentTitle": "MARKET SEGMENTS",
    "atentionTime": "Telephone service is available Monday to Friday, 8:00 am to 5:00 pm.",
    "atentionTimeEn": "Telephone service is available Monday to Friday, 8:00 am to 5:00 pm.",
    "logosWarnings": "All manufacturer names, numbers, symbols and descriptions are used for reference only and do not imply that the items listed are products of these manufacturers.",
    "keepBuying": "KEEP BUYING",
    "sessionExpired": "Your session has expired.",
    "not-found": "Not found",
    "madeBy": "Made By"
  }
}