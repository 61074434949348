import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions';
import { Translation } from 'react-i18next';
import Header from '../header/Header';
import { css } from 'emotion';
import bgImage from "../../assets/dashboard/background-image.png";
import { BLACK, FULL_WHITE, WHITE } from '../../utils/color-palatte';
import HomeFooter from '../home-footer/HomeFooter';
import Button from '../../utils/Button';
import { getData, postData, putData } from '../../fetch/services';
import Alert from '../../utils/Alert';
import Input from '../../utils/Input';
import Select from '../../utils/Select';

const language = localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || "es"

const productsTitleContainer = css`
text-align: center;
min-height: 200px;
background-image: url("${bgImage}");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
padding-bottom: 30px;
justify-content: flex-start;
align-items: center;
display: flex;
`

const title = css`
margin: 0;
font-size: 50px
`

const cartContainer = css`
text-align: center;
min-height: 400px;
position: relative;
padding-bottom: 30px;
`

const titleContainer = css`
@media(min-width: 650px) {
  margin-left: 20%;
}
`

const selectStyles = css`
margin: 20px;
margin-left: 0px;
  `

const configContainer = css`
background-color: ${FULL_WHITE};
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
  `
const buttonsContainer = css`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
max-width: 400px;
  `

class Config extends React.Component {

  state = {
    loading: true,
    bsValue: 0,
    currency: "",
    currencies: [],
    showInputsErrors: false
  };

  componentDidMount = () => {
    this.fetchConfig();
    window.scrollTo(0, 0)
  };

  showAlert = (isOpen, content, success) => this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  })

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }


  fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          await this.verifySession()
          const response = await getData(`/config/get-config`);
          // console.log("fetchConfig / response: ", response);
          const config = response.body

          this.setState({
            bsValue: config.bsValue,
            currency: config.currency,
            currencies: config.currencies,
            loading: false,
          });
          resolve();
        } catch (e) {
          resolve(e);
          // console.log("fetchConfig / error: ", e)
          this.setState({ loading: false })
          if (e !== "session-expired") {
            const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
              ? e.response.data.error.message
              : null;
            const error = e && e.data && e.data.error ? e.data.error : null;
            const errorMessage = error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
            this.showAlert(true, networkError || errorMessage, false)
          }
        }
      })
    })
  }

  saveConfig = (t) => {
    return new Promise(async (resolve, reject) => {
      if (this.state.bsValue && this.state.currency) {
        this.setState({ loading: true }, async () => {
          try {
            await this.verifySession()
            const { user } = this.props;
            const {
              bsValue,
              currency
            } = this.state;
            let configCopy = {
              bsValue,
              currency,
            }
            const data = {
              userId: user._id,
              config: configCopy
            }
            await putData(`/config/edit-config`, data);
            // console.log("saveProduct / response: ", response);
            this.fetchConfig()
            this.showAlert(true, t('configEdited'), true)
            this.setState({
              loading: false,
              files: {},
              showInputsErrors: false
            });
            resolve();
          } catch (e) {
            resolve(e);
            // console.log("saveConfig / error: ", e)
            this.setState({ loading: false })
            if (e !== 'session-expired') {
              const networkError = e && e.response && e.response.data && e.response.data.error && e.response.data.error.message
                ? e.response.data.error.message
                : null;
              const error = e && e.data && e.data.error ? e.data.error : null;
              const errorMessage = error && error.message && error.message[0] && error.message[0].msg
                ? error.message[0].msg
                : language === "en" ? "Internal server error, please try again." : "Error interno del servidor, intenta de nuevo."
              this.showAlert(true, networkError || errorMessage, false)
            }
          }
        })
      } else {
        this.setState({ showInputsErrors: true })
      }
    })
  }

  onChange = async ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  verifySession = (t) => {
    const message = t ? t("sessionExpired") : "Tu sesión ha expirado."
    return new Promise(async (resolve, reject) => {
      try {
        await postData(`/user/verify-session`);
        // console.log("verifySession / res: ", res)
        resolve()
      } catch (e) {
        // console.log("verifySession error: ", e)
        reject('session-expired')
        this.showAlert(true, message, false)
        window.scrollTo(0, 0);
        setTimeout(() => {
          const { signOut } = this.props;
          signOut();
        }, 3000);
      }
    })
  }

  render() {
    const { currencies } = this.state;
    // console.log("this.state.currency: ", this.state.currency);
    return (
      <Translation>
        {t =>
          <div className={`${cartContainer}`}>
            <Header />
            <div className={`${productsTitleContainer}`}>
              <span className={`${titleContainer}`} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <span>
                  <h1 className={`${title}`} style={{ color: WHITE }}>{t("configurationTitle")}</h1>
                </span>
              </span>
            </div>
            <div className={`${configContainer}`}>
              <div className={`${buttonsContainer}`}>
                <Select
                  label={t('currencyLabel')}
                  showErrors={this.state.showInputsErrors}
                  className={`${selectStyles}`}
                  value={this.state.currency}
                  name="currency"
                  disabled={this.state.loading}
                  onChange={this.onChange}
                  isRequired
                >
                  <option value="">{t('editConfigCurrency')}</option>
                  {currencies.map(currency =>
                    <option value={currency.value}>{currency.name}</option>
                  )}
                </Select>
                {this.state.currency === "bs" && <Input
                  type="number"
                  label={t('bsValue')}
                  value={this.state.bsValue}
                  onChange={this.handleInputChange}
                  name='bsValue'
                  disabled={this.state.loading}
                  showErrors={this.state.showInputsErrors}
                  isRequired
                />}
                <Button
                  loading={this.state.loading}
                  onClick={() => this.saveConfig(t)}
                  disabled={this.state.loading}
                  content={<p style={{ color: WHITE, margin: 0, padding: 8, fontSize: 17, paddingLeft: 30, paddingRight: 30 }}>{t("saveProductButton")}</p>}
                  style={{ backgroundColor: BLACK, marginTop: 30, minWidth: 100, minHeight: 38 }}
                  loaderColor={FULL_WHITE}
                />
              </div>
            </div>
            <HomeFooter />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        }
      </Translation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: bindActionCreators(actions.signOut, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    user,
  } = state;
  return {
    user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Config);
